import { css } from "@emotion/css";

export const navBarStyle = css`
  display: contents;
  .google-div {
    margin-left: 230%;
    margin-top: 5px;
  }
  .buttons-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
  .ul_div {
    display: inline-block;
    margin: auto 30px;
    list-style: none;
  }
  .li_div {
    display: inline;
    list-style-position: inside;
    padding: 14px 8px;
    line-height: 20px;
    font-weight: 600;
    color: rgb(99, 99, 99);
    font-size: 15px;
  }

  .start-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 158px;
    height: 40px;
    color: white;
    background: #f88932;
    border-color: #f88932;
    box-shadow: rgb(223 225 229) 0px 0px 4px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    margin-right: 10px;
  }
  .login-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
    line-height: 20px;
    justify-content: center;
    border: 1px solid#ffffff;
    background-color: #ffffff;
    color: #f88932;
    height: 40px;
    border-radius: 4px;
    font-weight: 600;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 4px 0px;
    margin-right: 16px;
    width: 158px;
  }
`;

export const googleStyle = css`
  .google-div {
    margin-left: 230%;
    margin-top: 5px;
  }
`;
