import { css } from "@emotion/css";

export const contactStyle = (isMobile) => css`
  .section-div {
    color: #ffffff;
    background-color: #f88932b5;
  }
  .card-div {
    display: flex;
    flex-direction: column;
    width: 1140px;
    margin: 0 auto;
  }
  .form-div {
    width: 710px;
    min-height: 627px;
    box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 40%);
    margin: 60px auto 0;
    color: #111111;
    background-color: #89d8e3;
  }
  .inputs-div {
    padding: 110px 40px 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    box-sizing: border-box;
  }
  .contact-header {
    font-size: 3.75rem;
    font-weight: 700;
    margin: 0;
    color: #082b76 !important;

    text-align: center;
  }
  .form-input {
    font-size: 1rem;
    height: auto !important;
    box-sizing: border-box;
    display: block;
    pointer-events: auto;
    pointer-events: initial;
    color: #111111;
    background-color: #89d8e3;
  }
  .form-class {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    box-sizing: border-box;
    text-align: left;
  }
  .input {
    display: block;
    width: 100%;
    padding: 10px 12px;
    background-image: none;
    background-clip: padding-box;
    border: none;
    border-radius: 3px;
    color: #111111;
    background-color: #ffffff;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    height: auto;
    overflow: visible;
    margin: 10px;
  }
  .submit-div {
    border: none;
    width: 400px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    margin-left: ${isMobile ? "" : "100px"};
  }
  .second-card-div {
    margin-top: -745px;
    margin-bottom: 0px;
    min-height: 175px;
    grid-template-columns: repeat(3, calc(25% - 5px));
    grid-gap: 150px;
    display: grid;
    margin-left: 20px;
  }
  .cards {
    display: initial;
  }
  .h5 {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }
  .location-icon {
    width: 50px;
    height: 50px;
    margin-left: 125px;
    margin-top: 10px;
    margin-bottom: 3px;
  }
  .contact-icon {
    width: 50px;
    height: 50px;
    margin-left: 130px;
    margin-top: 10px;
  }
  .email-icon {
    width: 50px;
    height: 50px;
    margin-left: 135px;
    margin-top: 10px;
  }
  .texts {
    margin: 10px;
    text-align: center;
    font-weight: 400;
  }
`;
