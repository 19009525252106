import { css } from "@emotion/css";

export const leadsContainer = (isMobile) => css`
  width: ${isMobile ? `100%` : `993px;`};
  background-color: #ffffff;
  overflow-x: scroll;
  overflow-x: ${!isMobile ? `hidden` : `scroll`};
  ${isMobile
    ? `
     margin:0 auto;
     padding-bottom: 64px;
     border-top: 1px solid #e0e1e3;`
    : ` margin-left: 20px;
        border: 1px solid #e0e1e3;
        border-radius: 4px;
        margin-bottom: 64px;
        height: auto;`};
  .LeadsHeader {
    padding: 16px;
    border-bottom: 1px solid #e0e1e3;
    font-size: 22px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    margin-bottom: 0px;
  }
  .NoLeads {
    padding: 16px;
    height: 500px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .description {
    width: ${isMobile ? "336px" : "300px"};
  }
`;

export const plansTableStyle = css`
  table {
    width: 100%;
  }
  th {
    background-color: #fbfbfb;
    text-align: center;
    padding: 16px 23px;
    border-bottom: 1px solid #e0e1e3;
  }
  td {
    border-bottom: 1px solid #e0e1e3;
    text-align: center;
    padding: 16px 18px;
  }
  .ViewDetails {
    cursor: pointer;
    color: #f88932;
    font-size: 14px;
    letter-spacing: 0.6px;
    font-weight: 800;
  }
`;
export const MobileLeadsCard = css`
  width: 336px;
  height: 185px;
  margin: 16px 12px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #e0e1e3;
  background-color: white;
  .items {
    display: flex;
    align-items: center;
  }
  .item-title {
    width: 97px;
    opacity: 0.7;
    font-size: 14px;
  }
  .item-name {
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .view-detail {
    border-top: 1px solid #e0e1e3;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .view-button {
    font-size: 14px;
    color: #f88932;
    margin-right: 8px;
    font-weight: 600;
    padding: 12px 8px 4px;
    margin-top: 0px;
  }
  .top-row {
    margin-top: 10px;
  }
`;
export const LeadsDetailsStyle = (isMobile) => css`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  .item-image {
    border-radius: 4px;
  }
  .popupContainerStyle {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    border-radius: 5px;
    width: ${isMobile ? `336px` : `576px;`};
    box-shadow: 0 10px 20px 0 rgba(55, 70, 95, 0.12);
    position: relative;
  }
  .itemTitle {
    font-size: 16px;
    font-weight: 800;
    margin-left: 16px;
  }
  .closeThick {
    cursor: pointer;
    position: absolute;
    top: ${isMobile ? `8px` : `16px;`};
    right: ${isMobile ? `8px` : `16px;`};
    width: ${isMobile ? `16px` : `20px;`};
    height: ${isMobile ? `16px` : `20px;`};
  }
  .leadsInfo {
    padding: ${isMobile ? `12px 8px` : `16px;`};
  }
  .information {
    display: flex;
  }
  .infoLabel {
    width: ${isMobile ? `88px` : `95px;`};
    opacity: 0.7;
    font-size: ${isMobile ? `14px` : `16px;`};
  }
  .infoValue {
    font-size: ${isMobile ? `14px` : `16px;`};
  }
  .infoTitle {
    font-size: ${isMobile ? `16px` : `18px;`};
    font-weight: 800;
  }
  .detailHeader {
    padding: ${isMobile ? `8px` : `16px;`};
    border-bottom: 1px solid #e0e1e3;
    display: flex;
    align-items: center;
  }
  .whatsapp {
    color: green;
    margin-bottom: 3px;
    margin-left: 12px;
  }
`;
