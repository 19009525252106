import React from "react";
import {Row, Col, Form} from "react-bootstrap";

function NewForm({
  inputsHandler,
  inputKey,
  name,
  value,
  check,
  placeholder,
  autoComplete,
  disable,
  onBlurCapture,
  onFocusCapture,
}) {
  return (
    <>
      <Form.Group className="mb-1" as={Row} controlId={name}>
        <Col sm="6">
          <Form.Label>
            <strong>{inputKey}</strong>
          </Form.Label>
          {check ? (
            <Form.Control
              type="text"
              as="textarea"
              name={name.toLowerCase()}
              onChange={inputsHandler}
              value={value}
              autoComplete={autoComplete}
              disabled={disable}
              onBlurCapture={onBlurCapture}
              onFocusCapture={onFocusCapture}
            />
          ) : (
            <Form.Control
              type="text"
              name={name.toLowerCase()}
              onChange={inputsHandler}
              value={value}
              placeholder={placeholder}
              autoComplete={autoComplete}
              disabled={disable}
              onBlurCapture={onBlurCapture}
              onFocusCapture={onFocusCapture}
            />
          )}
        </Col>
      </Form.Group>
    </>
  );
}

export default NewForm;
