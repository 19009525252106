/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import {useCallback, useEffect, useMemo} from "react";
import {getProductDetailsById} from "../../apis/product";

const useProduct = (
  setProduct,
  product,
  autoSuggestion,
  setAutoSuggestion,
  l1Data,
  setL1Data,
  l2Data,
  setL2Data,
  l3Data,
  setL3Data,
  showCategorySuggestion,
  setCategorySuggestion,
  showMakeSuggestion,
  setMakeSuggestion,
  showModelSuggestion,
  setModelSuggestion,
  showYearSuggestion,
  setYearSuggestion,
  address,
  setValidItemTitle,
  setValidMake,
  setValidModel,
  setValidYear,
  setValidCountry,
  setValidSubCategory,
  setPriceDisable,
  setImageUrl,
  setAutoSuggestionModel,
  setTags,
  tags
) => {
  const location = [];
  const onChange = useCallback(
    (field) => (event) => {
      if (field === "item_title") {
        setValidItemTitle(true);
        setCategorySuggestion(true);
      }
      if (field === "make") {
        setMakeSuggestion(true);
        setValidMake(true);
      }
      if (field === "year") {
        setYearSuggestion(true);
        setValidYear(true);
      }
      if (field === "model") {
        setModelSuggestion(true);
        setValidModel(true);
      }
      if (field === "buying_format") {
        if (event.target.value === "Price On Request") {
          setProduct((previousValue) => ({
            ...previousValue,
            [field]: "sale",
          }));
          setPriceDisable(true);
        } else {
          setPriceDisable(false);
        }
        setProduct((previousValue) => ({
          ...previousValue,
          [field]: event.target.value,
        }));
        return;
      }
      if (field === "price") {
        if (product.buying_format === "sale") {
          setProduct((previousValue) => ({
            ...previousValue,
            price_sale: event.target.value,
          }));
        }
        if (product.buying_format === "rent") {
          setProduct((previousValue) => ({
            ...previousValue,
            price_rent: event.target.value,
          }));
        }
      }
      setProduct((previousValue) => ({
        ...previousValue,
        [field]: event.target.value,
      }));
    },
    [setCategorySuggestion, setMakeSuggestion, setProduct, setYearSuggestion]
  );
  useEffect(() => {
    const getSuggestion = setTimeout(async () => {
      if (product?.item_title) {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/autofillProvidedByUser`,
          {
            title: product.item_title,
          }
        );
        setAutoSuggestionModel(
          res?.data?.modelData?.processed_model?.map((model, index) => {
            return {
              id: index + 1,
              name: model,
            };
          })
        );
        setTags(
          res?.data?.modelData?.processed_model?.map((model, index) => {
            return {
              id: index + 1,
              name: model,
            };
          })
        );
        if (window.location.pathname === "/listing/new") {
          setMakeSuggestion(true);
          setModelSuggestion(true);
          setYearSuggestion(true);
        }
        setAutoSuggestion(res?.data);
      } else {
        setAutoSuggestion([]);
      }
    }, 1000);
    return () => clearTimeout(getSuggestion);
  }, [product?.item_title]);
  const onSaveProduct = useCallback(() => () => {}, []);

  const onAdd = (tag) => {
    // setTags([...tags, tag]);
    console.log("adding");
  };

  const onDelete = (i) => {
    // setTags(tags.filter((index) => index !== i));
    console.log("deleting");
  };
  const getIndustry = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/getCategory?id=${id}`)
      .then((response) => {
        setL1Data(response.data.data);
      });
  };

  const getCategory = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/getCategory?id=${id}`)
      .then((response) => {
        setL2Data(response.data.data);
      });
  };

  const getSubCategory = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/getCategory?id=${id}`)
      .then((response) => {
        setL3Data(response.data.data);
      });
  };

  useMemo(() => {
    if (!product?.category) {
      setProduct((prevState) => ({
        ...prevState,
        sub_category: "",
        sub_category_id: "",
      }));
    }
  }, [product?.category, setProduct]);
  useMemo(() => {
    if (!product?.main_category) {
      setProduct((prevState) => ({
        ...prevState,
        category: "",
        category_id: "",
      }));
    }
  }, [product?.main_category, setProduct]);
  useEffect(() => {
    const getProductInfo = async () => {
      if (
        window.location.pathname.replace("/listing/", "").replace("new", "")
      ) {
        let productData = await getProductDetailsById(
          window.location.pathname.replace("/listing/", "")
        );
        setValidSubCategory(true);
        setProduct(productData?.items?.[0]);
        setProduct((prevValues) => ({
          ...prevValues,
          price:
            productData?.items?.[0]?.buying_format === "sale"
              ? productData?.items?.[0]?.price_sale
              : productData?.items?.[0]?.price_rent,
        }));
        const temp = productData?.items[0]?.img_url;
        setImageUrl([productData?.items[0].thumbnail_url, ...temp]);
      }
    };
    getProductInfo();
    getIndustry(0);
    // setCompanyName(JSON.parse(localStorage.getItem("user"))?.companyName);
  }, []);

  useMemo(() => {
    if (address) {
      const add_split = address ? address.split(",") : "";
      setProduct((prevState) => ({
        ...prevState,
        country: (add_split[add_split?.length - 1] ?? "").trim(),
        state: (add_split[add_split?.length - 2] ?? "").trim(),
        city: (add_split[add_split?.length - 3] ?? "").trim(),
      }));
      setValidCountry(true);
    }
  }, [address, setProduct]);
  const handleOnClickSuggestion = (data, type) => {
    if (type === "category") {
      setValidSubCategory(true);
      setCategorySuggestion(false);
      setProduct((prevValues) => ({
        ...prevValues,
        main_category: data.industryName,
        main_category_id: data.industryKey,
        category: data.categoryName,
        category_id: data.categoryKey,
        sub_category: data.subCategoryName,
        sub_category_id: data.subCategoryKey,
      }));
    }
    if (type === "make") {
      setValidMake(true);
      setMakeSuggestion(false);
      setProduct((prevValues) => ({...prevValues, make: data}));
    }
    if (type === "model") {
      setValidModel(true);
      setModelSuggestion(false);
      setProduct((prevValues) => ({...prevValues, model: data}));
    }
    if (type === "year") {
      setValidYear(true);
      setYearSuggestion(false);
      setProduct((prevValues) => ({...prevValues, year: data}));
    }
  };
  if (product?.city) {
    location.push(product.city);
  }
  if (product?.state) {
    location.push(product.state);
  }
  if (product?.country) {
    location.push(product.country);
  }
  const newLocation = location.join();

  const onBlurCaptureHandler = (fieldName) => {
    if (fieldName === "item_title") {
      setCategorySuggestion(false);
    }
    if (fieldName === "make") {
      setMakeSuggestion(false);
    }
    if (fieldName === "year") {
      setYearSuggestion(false);
    }
    if (fieldName === "model") {
      setModelSuggestion(false);
    }
  };

  const onFocusHandler = (fieldName) => {
    if (fieldName === "item_title") {
      setCategorySuggestion(true);
    }
    if (fieldName === "make") {
      setMakeSuggestion(true);
    }
    if (fieldName === "year") {
      setYearSuggestion(true);
    }
    if (fieldName === "model") {
      setModelSuggestion(true);
    }
  };

  return {
    onChange,
    onSaveProduct,
    product,
    autoSuggestion,
    setProduct,
    l1Data,
    l2Data,
    l3Data,
    getCategory,
    getSubCategory,
    handleOnClickSuggestion,
    showCategorySuggestion,
    showMakeSuggestion,
    showModelSuggestion,
    showYearSuggestion,
    newLocation,
    onBlurCaptureHandler,
    onFocusHandler,
    onAdd,
    onDelete,
  };
};

export default useProduct;
