import { css } from "@emotion/css";

export const sellingStyle = (isMobile) => css`
  display: ${isMobile ? "" : "grid"};
  margin-left: ${isMobile ? "20px" : ""};
  margin-top: ${isMobile ? "110px" : ""};
  grid-template-columns: ${isMobile ? "" : "20% 50% 30%"};

  .card-sell {
    margin-top: 10px;
    display: ${isMobile ? "" : "flex"};
    justify-content: center;
    align-self: center;
    align-items: center;
    margin-left: ${isMobile ? "" : "300px"};
  }
  .mid-sell {
    display: ${isMobile ? "grid" : "flex"};
    margin: 10px;
  }
  .field-input {
    width: ${isMobile ? "300px" : "280px"};
    margin: 10px;
  }
  .div1-div {
    align-items: center;
    display: ${isMobile ? "" : "grid"};
    margin-left: ${isMobile ? "" : "543px"};
    margin-right: ${isMobile ? "" : "-160px"};
    justify-content: center;
  }
  .phone-text {
    margin-left: 19px;
    width: ${isMobile ? "10px" : ""};
  }
  .form-control {
    width: ${isMobile ? "281px" : ""};
  }
  .mail-location-div {
    margin-top: ${isMobile ? "0px" : "20px"};
    display: ${isMobile ? "grid" : "flex"};
  }
  .mail-input {
    margin-left: 20px;
    width: ${isMobile ? "300px" : "280px"};
    margin-top: ${isMobile ? "20px" : ""};
  }
  .google-mail {
    margin-left: ${isMobile ? "20px" : "18px"};
    width: ${isMobile ? "300px" : "255px"};
    height: ${isMobile ? "" : "51px"};
    margin-top: ${isMobile ? "20px" : ""};
  }
  .or {
    display: ${isMobile ? "flex" : ""};
    justify-content: ${isMobile ? "center" : ""};
    margin-top: ${isMobile ? "10px" : ""};
    margin-left: ${isMobile ? "" : "10px"};
    font-weight: bold;
  }

  .confirm-pass-input {
    width: ${isMobile ? "300px" : "280px"};
    margin-left: ${isMobile ? "" : "10px"};
  }
  .location-input {
    margin-left: 20px;
    width: ${isMobile ? "300px" : "575px"};
    margin-top: ${isMobile ? "20px" : "10px"};
  }
  .submit-div {
    border: none;
    border-radius: 15px;
    background: #f88932;
    color: #fff;
    font-size: ${isMobile ? "20px" : "16px"};
    font-weight: ${isMobile ? "600" : ""};
    width: ${isMobile ? "300px" : "115px"};
    height: ${isMobile ? "50px" : "40px"};
    margin-top: 10px;
    margin-left: ${isMobile ? "20px" : "265px"};
    margin-bottom: 30px;
    align-item: ${isMobile ? "center" : ""};
  }
  .heading-text {
    font-size: 22px;
    font-weight: 600;
    text-align: ${isMobile ? "center" : ""};
  }

  .send-otp {
    background: rgb(248, 137, 50);
    color: rgb(255, 255, 255);
    font-weight: bold;
    cursor: pointer;
    float: ${isMobile ? "" : "right"};
    margin-top: ${isMobile ? "10px" : "5px"};
    margin-right: ${isMobile ? "" : "20px"};
    border: none;
    border-radius: ${isMobile ? "10px" : "5px"};
    width: ${isMobile ? "100px" : "100px"};
    height: ${isMobile ? "35px" : "35px"};
  }
  .pass-input {
    width: ${isMobile ? "86%" : "280px"};
    margin-left: ${isMobile ? "10px" : "-65px"};
  }
`;
export const billingStyle = (isMobile) => css`
  display: ${isMobile ? "grid" : "block"};
  margin-left: ${isMobile ? "30px" : "0px"};
  padding: ${isMobile ? "" : "15px"};
  margin-top: ${isMobile ? "110px" : ""};

  .bill-text {
    font-size: 22px;
    font-weight: 600;
    text-align: ${isMobile ? "center" : ""};
    margin-left: ${isMobile ? "40px" : ""};
    margin-top: ${isMobile ? "10px" : ""};
    margin-bottom: ${isMobile ? "10px" : ""};
  }
  .bill-div {
    display: ${isMobile ? "grid" : "flex"};
    margin: ${isMobile ? "" : "15px"};
    margin-left: ${isMobile ? "50px" : ""};
  }
  .button-div {
    padding: ${isMobile ? "" : "10px"};
    float: ${isMobile ? "" : "right"};
    margin: ${isMobile ? "" : "10px"};
    margin-left: ${isMobile ? "65px" : ""};
  }
  .next-button {
    border: none;
    background: #f88932;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    color: #fff;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 20px;
  }
  .skip-button {
    border: none;
    background: rgb(20 78 17 / 65%);
    border-radius: 10px;
    width: 150px;
    height: 40px;
    color: #fff;
    margin-top: 10px;
    font-weight: 400;
    font-size: 20px;
  }
  .inputs-div {
    width: ${isMobile ? "" : "100%"};
    margin: ${isMobile ? "10px" : "10px"};
  }
`;
export const companyInfoStyle = (isMobile) => css`
  display: block;
  align-items: ${isMobile ? "" : "center"};
  justify-content: ${isMobile ? "" : "center"};
  margin-left: ${isMobile ? "85px" : "0px"};
  padding: ${isMobile ? "10px" : "10px"};
  margin-top: ${isMobile ? "110px" : ""};

  .company-text {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: ${isMobile ? "10px" : ""};
  }
  .website-div {
    width: ${isMobile ? "" : "455px"};
    margin: ${isMobile ? "" : "10px"};
    margin-top: ${isMobile ? "10px" : ""};
    margin-bottom: ${isMobile ? "10px" : ""};
  }
  .url-div {
    display: ${isMobile ? "" : "flex"};
    margin: ${isMobile ? "" : "10px"};
  }

  .facebook-url {
    margin-right: ${isMobile ? "" : "10px"};
    margin-top: ${isMobile ? "10px" : ""};
    margin-bottom: ${isMobile ? "10px" : ""};
  }
  .linkedin-url {
    margin-right: ${isMobile ? "" : "10px"};
    margin-top: ${isMobile ? "10px" : ""};
    margin-bottom: ${isMobile ? "10px" : ""};
  }
  .company-button-div {
    padding: ${isMobile ? "" : "10px"};
    float: ${isMobile ? "" : "right"};
    margin: ${isMobile ? "" : "10px"};
  }
  .company-next-button {
    border: none;
    background: #f88932;
    border-radius: 10px;
    width: 150px;
    height: 40px;
    color: #fff;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 400;
    font-size: 20px;
  }
  .company-skip-button {
    border: none;
    background: rgb(20 78 17 / 65%);
    border-radius: 10px;
    width: 135px;
    height: 40px;
    color: #fff;
    margin-top: 10px;
    font-weight: 400;
    font-size: 20px;
  }
`;
