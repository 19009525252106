import { css } from "@emotion/css";

export const invitePageStyle = (isMobile) => css`
  position: relative;
  .heroBanner {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 16px;
    left: 0;
    width: 80%;
    right: 0;
    ${isMobile
      ? `display: contents;`
      : `display: flex;
  align-items: center;
  justify-content: space-between;`};
    flex-direction: ${isMobile ? "column" : "row"};
  }
  .heroBannerInfo {
    width: ${isMobile ? "95%" : "40%"};
    ${isMobile &&
    `margin-top: -200px;
  margin-bottom: 60px;
  margin-left: 12px;
  `};
  }
  .backGroundImage {
    width: 100%;
    height: ${isMobile ? "253px" : "650px"};
  }
  .FormWrapper {
    background-color: white;
    border-radius: 4px;
    padding: 32px;
    width: ${isMobile ? "360px" : "474px"};
    height: ${isMobile ? "" : "600px"};
  }
`;
