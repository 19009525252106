import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import MidPage from "./MidPage";
import { backgroundStyle, machineStyle } from "./style";

const Truck = () => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title =
      "Sell Truck Online On IronList | Commercial Vehicle. Sell Used Trucks Online | Sell Your Used Truck | Truck in Ahmedabad | Second Hand Truck | Online Selling of Trucks";
  }, []);
  return (
    <div className={backgroundStyle}>
      <div style={{ overflow: "hidden" }}>
        <div className={machineStyle(isMobile)}>
          <div className="images-div">
            <div>
              <h4 className="h4-div">
                Sell Truck Online On IronList | Commercial Vehicle
              </h4>
              <div style={{ textAlign: "center" }}>
                <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
                  <button className="mid-sell-button">Start Selling</button>
                </Link>
              </div>
            </div>
            <img
              className="imgs"
              src={`./assets/images/Sell Truck Online Ironlist.jpg`}
              alt="Compactor"
            />
          </div>
        </div>
        <MidPage />
      </div>
    </div>
  );
};

export default Truck;
