import React, { useState, useCallback } from "react";
import { getSidebarTabs } from "./const";
import SideBarTab from "./SideBarTab";
import { tabContainerStyles } from "./style";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../context/IsMobile";

function SidebarTabs({ currentUser }) {
  const isMobile = useIsMobile();
  const path = window.location.pathname.slice(1).toUpperCase();
  let exactPath;
  if (path?.split("/")[0] === "LISTING") {
    exactPath = "LISTINGS";
  } else {
    exactPath = path?.split("/")[0];
  }
  const currentPath = getSidebarTabs.filter((tab) => tab.id === exactPath);
  const [value, setValue] = useState(currentPath[0] || getSidebarTabs[0]);
  const history = useHistory();
  const onChange = useCallback(
    (tab) => {
      setValue(tab);
      history.push(tab.link);
    },
    [history]
  );
  const filterTabs =
    currentUser && currentUser.claimListing === false
      ? getSidebarTabs.filter((tab) => tab.id !== "CLAIMLISTINGS")
      : getSidebarTabs;
  return (
    <>
      {!isMobile && (
        <div className={tabContainerStyles}>
          {filterTabs.map((tab) => (
            <SideBarTab
              key={tab.id}
              tab={tab}
              isSelected={value.id === tab.id}
              onClick={onChange}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default SidebarTabs;
