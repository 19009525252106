import { css } from "@emotion/css";

export const videoStyled = (isMobile) => css`
  display: ${isMobile ? "block" : "flex"};
  justify-content: space-around;
  margin-top: ${isMobile ? "" : "70px"};
  width: ${isMobile ? "" : "80%"};
  margin-left: ${isMobile ? "35px" : "auto"};
  margin-right: ${isMobile ? "50px" : "auto"};
  .video_text {
    width: ${isMobile ? "320px" : "500px"};
    margin-top: ${isMobile ? "10px" : ""};
  }
  .h3-text {
    font-weight: bold;
    margin-bottom: 35px;
  }
  .h5-text {
    font-weight: bold;
    font-size: 19px;
    color: #337ab7;
  }
  .mid-text {
    font-size: 20px;
  }
  .videos {
    width: ${isMobile ? "325px" : "500px"};
    height: ${isMobile ? "250px" : "315px"};
    border: 1.5px solid black;
    border-radius: 10px;
    margin-top: ${isMobile ? "10px" : ""};
  }
  .more {
    background-color: #f88932;
    color: #fff;
    height: 40px;
    text-decoration: none;
    margin-left: ${isMobile ? "" : "75%"};
    border: none;
    border-radius: 5px;
    font-weight: 600;
  }
`;
