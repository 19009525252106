import React, {useState} from "react";
import NewForm from "../NewForm";
import Form from "react-bootstrap/Form";
import {Row, Col} from "react-bootstrap";
import "./styles.css";
import FilterList from "../../../components/FilterList/FilterList";
import LocationSearchInput from "../LocationSearchInput";
import ValidationError from "../../../shared/ValidationError/index";
import AddIcon from "@mui/icons-material/Add";

const StepOne = ({
  onChange,
  autoSuggestion,
  handleOnClickSuggestion,
  showCategorySuggestion,
  product,
  setProduct,
  l1Data,
  l2Data,
  l3Data,
  getCategory,
  getSubCategory,
  showMakeSuggestion,
  showModelSuggestion,
  showYearSuggestion,
  setLatLng,
  setAdd,
  newLocation,
  latLng,
  isValidItemTitle,
  isValidSubCategory,
  setValidSubCategory,
  isValidMake,
  isValidModel,
  isValidCountry,
  isValidYear,
  onFocusHandler,
  setCategorySuggestion,
}) => {
  const [siteDetailData] = useState([]);
  // const { onAdd, onDelete, suggestions, tags } = useStepOne(autoSuggestion);

  const userWebsite = [];
  siteDetailData?.map((item) =>
    userWebsite.push({
      label: item.subdomain_name,
      value: item.subdomain_name,
    })
  );

  return (
    <div>
      <Form className="formStyle">
        {userWebsite.length > 0 && (
          <Form.Group className="mb-3" as={Row} controlId="Currency">
            <Col sm="6">
              <Form.Label>
                <strong>Sub Domain</strong>
              </Form.Label>
              <Form.Select name="subdomain_name" value={""}></Form.Select>
            </Col>
          </Form.Group>
        )}
        <NewForm
          inputKey="Enter Item/Category*"
          placeholder="Example:- 2014 Tata 380"
          name="item_title"
          value={product?.item_title}
          inputsHandler={onChange("item_title")}
          autoComplete={false}
          //   onBlurCapture={(e) => onBlurCaptureHandler("item_title")}
          onFocusCapture={(e) => onFocusHandler("item_title")}
        />
        {!isValidItemTitle && (
          <ValidationError show={true} message="This Field is required" />
        )}
        {showCategorySuggestion && (
          <div className="style-border">
            {autoSuggestion?.categoryl3Data?.getcategoryl3?.length > 0 &&
              autoSuggestion?.categoryl3Data?.getcategoryl3?.map((x, index) => (
                <ul
                  style={{
                    display: "flex",
                  }}
                >
                  <li
                    onClick={() => handleOnClickSuggestion(x, "category")}
                    id={index}
                    className="div-border"
                  >
                    {x?.categoryDisplayName}
                    <AddIcon className="icon-style" />
                  </li>
                </ul>
              ))}
          </div>
        )}
        <Form.Group
          width="100w"
          className="mb-3 col-10"
          as={Row}
          controlId="formPlanetextName"
        >
          <Form.Label>
            <strong>Categories</strong>
          </Form.Label>
          <div className="col-sm-4">
            <FilterList
              placeholder="search for industry"
              name="main_category"
              id="main_category_id"
              value={product?.main_category}
              onChange={onChange("main_category")}
              setValue={setProduct}
              data={l1Data}
              onCategoryChange={getCategory}
              isSelected={product?.main_category}
              isValidSubCategory={isValidSubCategory}
            />
          </div>

          <div className="col-sm-4">
            <FilterList
              placeholder="search for category"
              name="category"
              id="category_id"
              value={product?.category}
              onChange={onChange("category")}
              setValue={setProduct}
              data={product?.main_category && l2Data}
              onCategoryChange={getSubCategory}
              isSelected={product?.category}
              isValidSubCategory={isValidSubCategory}
            />
          </div>
          <div className="col-sm-4">
            <FilterList
              placeholder="search for subcategory"
              name="sub_category"
              id="sub_category_id"
              value={product?.sub_category}
              onChange={onChange("sub_category")}
              setValue={setProduct}
              data={product?.main_category && product?.category && l3Data}
              setValidSubCategory={setValidSubCategory}
              onCategoryChange={() => {}}
              isSelected={product?.sub_category}
              isValidSubCategory={isValidSubCategory}
            />
          </div>

          {!isValidSubCategory && (
            <ValidationError
              show={!isValidSubCategory}
              message="This Field is required"
            />
          )}
        </Form.Group>
        <NewForm
          inputKey="Enter Brand Name"
          name="brand"
          placeholder="Example:- Tata"
          inputsHandler={onChange("make")}
          value={product?.make}
          //   onBlurCapture={(e) => onBlurCaptureHandler("make")}
          onFocusCapture={(e) => onFocusHandler("make")}
        />

        {showMakeSuggestion && (
          <div className="style-border">
            {autoSuggestion?.makeData?.processed_make?.length > 0 &&
              autoSuggestion?.makeData?.processed_make?.map((x, index) => (
                <ul
                  style={{
                    display: "flex",
                  }}
                >
                  <li
                    onClick={() => handleOnClickSuggestion(x, "make")}
                    id={index}
                    className="div-border"
                  >
                    {x}
                    <AddIcon className="icon-style" />
                  </li>
                </ul>
              ))}
          </div>
        )}
        {!isValidMake && (
          <ValidationError
            show={!isValidMake}
            message="This Field is required"
          />
        )}
        <NewForm
          inputKey=" Enter Model Name"
          name="model"
          placeholder="Example:-  380"
          inputsHandler={onChange("model")}
          value={product?.model}
          //   onBlurCapture={(e) => onBlurCaptureHandler("model")}
          onFocusCapture={(e) => onFocusHandler("model")}
        />
        {/* <ReactTags
          tags={tags}
          suggestions={suggestionsModel}
          labelText="Select Model"
          onAdd={onAdd}
          onDelete={onDelete}
          noOptionsText="No matching Model"
        /> */}

        {showModelSuggestion && (
          <div className="style-border">
            {autoSuggestion?.modelData?.processed_model?.length > 0 &&
              autoSuggestion?.modelData?.processed_model?.map((x, index) => (
                <ul
                  style={{
                    display: "flex",
                  }}
                >
                  <li
                    onClick={() => handleOnClickSuggestion(x, "model")}
                    id={index}
                    className="div-border"
                  >
                    {x}
                    <AddIcon className="icon-style" />
                  </li>
                </ul>
              ))}
          </div>
        )}
        {!isValidModel && (
          <ValidationError
            show={!isValidModel}
            message="This Field is required"
          />
        )}
        <LocationSearchInput
          setAdd={setAdd}
          setLatLng={setLatLng}
          newLocation={newLocation}
          latLng={latLng}
        />
        {!isValidCountry && (
          <ValidationError
            show={!isValidCountry}
            message="This Field is required"
          />
        )}
        <NewForm
          inputKey="Year of make"
          name="year of make"
          inputsHandler={onChange("year")}
          value={product?.year}
          //   onBlurCapture={(e) => onBlurCaptureHandler("year")}
          onFocusCapture={(e) => onFocusHandler("year")}
        />
        {showYearSuggestion && (
          <div className="style-border">
            {autoSuggestion?.yearData?.processed_year && (
              <ul
                style={{
                  display: "flex",
                }}
              >
                <li
                  onClick={() =>
                    handleOnClickSuggestion(
                      autoSuggestion?.yearData?.processed_year,
                      "year"
                    )
                  }
                  id={0}
                  className="div-border"
                >
                  {autoSuggestion?.yearData?.processed_year}
                  <AddIcon className="icon-style" />
                </li>
              </ul>
            )}
          </div>
        )}
        {!isValidYear && (
          <ValidationError
            show={!isValidYear}
            message="This Field is required"
          />
        )}
      </Form>
    </div>
  );
};

export default StepOne;
