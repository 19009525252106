import React from "react";
import { useIsMobile } from "../../components/context/IsMobile";
import { tableContent } from "./styles";

const Content = () => {
  const isMobile = useIsMobile();
  return (
    <div className={tableContent(isMobile)}>
      <div className="content-div">
        <h2 className="content-title">Sell Machines on IronList</h2>
        <div className="text-div" id="about">
          <p>
            IronList is best online selling platform for a variety of used
            equipment and machinery, including construction, agricultural, and
            transportation equipment. If you want to sell machines that you
            would like to sell online, you can do so by creating a seller
            account and listing your items for sale on the platform.
          </p>
          <p>
            To sell machines on IronList, you will need to create an account and
            provide some basic information about yourself and your business.
            Once your account is set up, you can start listing your machines for
            sale by uploading photos and providing detailed descriptions of
            their features and condition. We provide tools and resources to help
            you create effective listings, including guidelines for writing
            descriptions, tips for taking good photos, and information on how to
            set the right price for your items.
          </p>
          IronList is a well-known and respected online selling platform and
          buying platform for used equipment, so it can be a good choice if
          you're looking to reach a wide audience of potential buyers. However,
          it's important to note that IronList charges fees for its services,
          including listing fees, promotion and marketing, so you'll need to
          factor these costs into your pricing when selling on the platform
        </div>
      </div>
      <h5 className="table-header">
        Buy or Sell Used Machinery & Heavy Equipment Online in India
      </h5>
      <div className="table-div">
        <table className="table-block">
          <tr>
            <th> IronList for Used Heavy Equipment Buyers </th>
            <th>IronList for Used Heavy Equipment Sellers</th>
          </tr>
          <tr>
            <td>
              • Extremely Convenient for users and buyers to find any used heavy
              equipment and machines anytime and anywhere.{" "}
            </td>
            <td> • Sell your items to interested buyers near you</td>
          </tr>
          <tr>
            <td>
              • Large selection of different categories and listing items with
              popular heavy equipment manufacturers, models and vendors.{" "}
            </td>
            <td> • High Impressions & Inquiry Rate for items</td>
          </tr>
          <tr>
            <td>
              • Compare items, Create Alerts, Watchlist and Calendars to track
              items and auctions.{" "}
            </td>
            <td> • Promote and Get featured on top ad spots</td>
          </tr>
          <tr>
            <td> • Sign Up for Free Today on Ironlist!! </td>
            <td>
              {" "}
              • Increased credibility for your brand and organisation by getting
              Register for free
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Content;
