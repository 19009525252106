import React from "react";
import PropTypes from "prop-types";
import {errorText} from "../styles/Validation.styled";

const ValidationError = ({show, message}) => {
  return show && <div className={errorText}>{message}</div>;
};

ValidationError.propTypes = {
  show: PropTypes.bool,
  message: PropTypes.string,
};

ValidationError.defaultProps = {
  show: false,
  message: "",
};

export default ValidationError;
