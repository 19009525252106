import React from "react";
import { Button } from "react-bootstrap";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Form from "react-validation/build/form";
import LocationSearchInput from "../product/LocationSearchInput";
import { ChevronleftThick, WarningThick } from "@ironlist/ironlist-icons";

function AddInfo({
  setStep,
  setValues,
  values,
  setAdd,
  setLatLng,
  newLocation,
}) {
  return (
    <div className="info-container">
      <h3 className="headerStyle">
        <ChevronleftThick
          onClick={() => {
            setStep(0);
          }}
          className="back-button"
        />
        Add Info
      </h3>
      <div className="form-container">
        <h5>
          <strong>Website URL</strong>
        </h5>
        <Form>
          <div className="website-domain">
            <p>Website URL: </p>
            <div className="form-group">
              <Input
                type="text"
                placeholder="example.ironlist.com"
                className="form-control"
                name="website_url"
                value={values?.subdomain_name}
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    subdomain_name: e.target.value,
                  }))
                }
              />
            </div>
            <WarningThick
              width={13}
              height={13}
              fill="#62656e"
              className="warning-icon"
            />
            <h5>
              <strong>Forever Fix</strong>
            </h5>
          </div>
          <div className="separator-line" />
          <div className="about-section">
            <h5>
              <strong>About organization</strong>
            </h5>
            <p>About your organization in max 100 words</p>
            <Textarea
              type="text"
              name="about"
              value={values?.about_us}
              onChange={(e) =>
                setValues((prevState) => ({
                  ...prevState,
                  about_us: e.target.value,
                }))
              }
            />
          </div>
          <div className="location-input">
            <LocationSearchInput
              setAdd={setAdd}
              setLatLng={setLatLng}
              newLocation={newLocation}
            />
          </div>
        </Form>
      </div>
      <div className="next-button" onClick={() => setStep(2)}>
        <Button variant="light" className="nextButton">
          Next
        </Button>
      </div>
    </div>
  );
}
export default AddInfo;
