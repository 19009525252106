import { css } from "@emotion/css";

export const plansContainer = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  height: ${isMobile ? "" : "850px"};
  margin-bottom: 64px;
  ${isMobile
    ? `
    margin: 0 auto;
     border-top: 1px solid #e0e1e3;`
    : ` margin-left: 20px;
        border: 1px solid #e0e1e3;
        border-radius: 4px;`};
  background-color: #ffffff;
  .rc-tabs-nav-operations,
  .rc-tabs-ink-bar {
    display: none;
  }
  .rc-tabs-nav-list {
    display: flex;
    align-items: center;
    ${isMobile
      ? `
      justify-content: space-around;
      padding: 4px 0px 0px 12px;`
      : `padding: 20px 24px 0px 24px;
      border-radius: 4px;`};
    border-bottom: 1px solid #e0e1e3;
  }
  .rc-tabs-tab {
    margin-right: ${isMobile ? "16px" : "72px"};
    opacity: 0.7;
    font-family: Geomanist;
    font-size: ${isMobile ? "14px" : "18px"};
    font-weight: 700;
    text-align: center;
    padding: 4px;
    border-radius: 4px;
  }
  .rc-tabs-tab:hover {
    opacity: 1;
  }
  .rc-tabs-tab-active {
    opacity: 1;
    border-bottom: 4px solid #f88932;
  }
  .rc-tabs-tab-btn {
    margin-bottom: 8px;
  }
  .CardBody {
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-color: #fafcfd;
  }
  .noActivePlans {
    height: 740px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .noActivePlansInfo {
    width: 270px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .description {
    width: 270px;
    text-align: center;
  }
  .TableContainer {
    height: auto;
  }
  .ActiveCardInfo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 20px; */
  }
  .BuyMore {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .PremiumPlans {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 16px auto;
    width: 90%;
  }
  .PlansHeader {
    margin-top: 24px;
    margin-left: 24px;
    font-size: 24px;
    font-weight: 700;
  }
`;
export const successMessage = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  height: 740px;
  ${isMobile
    ? `
     padding: 12px;
     margin: 0 auto;
     padding-bottom: 64px;
     border-top: 1px solid #e0e1e3;`
    : ` margin-left: 20px;
        border: 1px solid #e0e1e3;
        border-radius: 4px;
        margin-bottom: 64px;
        `};
  background-color: #ffffff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const plansTableStyle = css`
  table {
    width: 100%;
  }
  th {
    background-color: #fbfbfb;
    text-align: center;
    padding: 16px 15px;
    border-bottom: 1px solid #e0e1e3;
  }
  td {
    border-bottom: 1px solid #e0e1e3;
    text-align: center;
    padding: 16px 24px;
  }
  .invoice-button {
    background: white;
    border: 2px solid #f88932;
    color: #f88932;
    font-weight: 600;
  }
`;
export const mobileTableCard = css`
  width: 336px;
  height: 230px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 12px 10px 12px 8px;
  border-radius: 4px;
  border: solid 1px #e0e1e3;
  background-color: white;
  .top-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .items {
    display: flex;
    align-items: center;
  }
  .item-title {
    width: 150px;
    opacity: 0.7;
  }
  .invoice-button-mobile {
    background: white;
    border: 2px solid #f88932;
    color: #f88932;
    font-weight: 600;
    float: right;
  }
`;
