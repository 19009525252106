import { css } from "@emotion/css";

export const dashBoardStyle = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  margin: 0 auto;
  ${!isMobile && `margin-left: 20px;`};
  .CountContainer {
    display: flex;
    flex-wrap: wrap;
    ${isMobile
      ? `background-color:white;padding: 12px;justify-content: space-between;`
      : `justify-content: space-around;  height: 740px;`};
  }
`;
export const countCard = (color, isMobile) => css`
  padding: ${isMobile ? "8px" : "16px"};
  width: ${isMobile ? "162px" : "238px"};
  height: ${isMobile ? "112px" : "152px"};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${color};
  ${isMobile && `margin-bottom: 12px;`};

  .count {
    margin-bottom: 0px;
    margin-top: ${isMobile ? "12px" : ""};
    color: white;
    font-size: ${isMobile ? "24px" : "32px"};
  }
  .label {
    margin-bottom: 0px;
    color: white;
    font-size: ${isMobile ? "14px" : "18px"};
    text-align: center;
  }
  .div-dis {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
`;
