import React, { useState, useEffect, useMemo } from "react";
import { websiteContainer } from "./style";
import AddInfo from "./AddInfo";
import ManageWebsite from "./ManageWebsite";
import SelectTheme from "./SelectTheme";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import SuccessPage from "./SuccessPage";
import CreateWebsite from "./CreateWebsite";

const defaultValue = {
  template_name: "Theme1",
  subdomain_name: "",
  about_us: "",
  banner: "",
  vendor_logo: "",
  city: "",
  state: "",
  country: "",
  lat: null,
  long: null,
  company_name: "",
  facebook: "",
  instagram: "",
  linkedin: "",
  twitter: "",
};

function MyWebsite() {
  const [values, setValues] = useState(defaultValue);
  const [siteDetailData, setSiteDetailData] = useState([]);
  const [step, setStep] = useState(0);
  const [banners, setBanners] = useState([]);
  const [logoImage, setLogoImage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [add, setAdd] = useState();
  const [latLng, setLatLng] = useState();
  const [createSubDomain, setCreateSubDomain] = useState(false);
  const location = [];
  if (values.city) {
    location.push(values.city);
  }
  if (values.state) {
    location.push(values.state);
  }
  if (values.country) {
    location.push(values.country);
  }
  const newLocation = location.join();

  useMemo(() => {
    const add_split = add ? add.split(",") : "";

    setValues((prevState) => ({
      ...prevState,
      country: (add_split[add_split.length - 1] ?? "").trim(),
      state: (add_split[add_split.length - 2] ?? "").trim(),
      city: (add_split[add_split.length - 3] ?? "").trim(),
    }));
  }, [add]);
  useMemo(() => {
    if (latLng !== undefined) {
      if (latLng.latLng !== undefined) {
        setValues((prevState) => ({
          ...prevState,
          lat: latLng.latLng.lat ?? "",
          long: latLng.latLng.lng ?? "",
        }));
      }
    }
  }, [latLng]);
  const addSubdomain = () => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/addSubdomain`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "POST",
      data: {
        template_name: values.template_name,
        subdomain_name: values.subdomain_name,
        about_us: values?.about_us,
        city: values.city,
        state: values.state,
        country: values.country,
        lat: values.lat,
        long: values.long,
      },
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setStep(3);
        getSiteDetailData();
        if (step === 3 && values.subdomain_name) {
          onWebsiteChange(values.subdomain_name);
        }
      }
    });
  };
  const getSiteDetailData = () => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/getSiteDetailData`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "GET",
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setLoading(false);
        setSiteDetailData(response?.data?.response);
      }
    });
    const subDomainOptions = {
      url: `${process.env.REACT_APP_API_URL}/dashboard/isSubdomainCreate`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "GET",
    };
    axios(subDomainOptions).then((response) => {
      setLoading(false);
      setCreateSubDomain(response?.data?.success);
    });
    setTimeout(() => setLoading(false), 2000);
  };
  useEffect(() => {
    getSiteDetailData();
  }, []);
  const userWebsite = [];
  siteDetailData.map((item) =>
    userWebsite.push({
      label: item.subdomain_name,
      value: item.subdomain_name,
    })
  );

  const onWebsiteChange = (domain) => {
    setBanners([]);
    setLogoImage([]);
    const websiteInfo = siteDetailData.find(
      (item) => item.subdomain_name === domain
    );
    setValues(websiteInfo);
    if (websiteInfo?.banner) setBanners([websiteInfo?.banner]);
    if (websiteInfo?.vendor_logo) setLogoImage([websiteInfo?.vendor_logo]);
  };

  const updateSiteDataBySubDomain = () => {
    values.banner = banners && banners[0];
    values.vendor_logo = logoImage && logoImage[0];
    setValues(values);
    const options = {
      url: `${process.env.REACT_APP_API_URL}/updateSiteDataBySubDomain`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "Post",
      data: values,
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setStep(4);
        toast.success("WebSite Updated SuccessFully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <div className={websiteContainer}>
        {step === 0 && (
          <CreateWebsite
            setStep={setStep}
            Data={siteDetailData}
            onManageWebsite={onWebsiteChange}
            loading={loading}
            createSubDomain={createSubDomain}
          />
        )}
        {step === 1 && (
          <AddInfo
            setStep={setStep}
            setValues={setValues}
            values={values}
            setAdd={setAdd}
            setLatLng={setLatLng}
            newLocation={newLocation}
          />
        )}
        {step === 2 && (
          <SelectTheme
            setStep={setStep}
            setValues={setValues}
            values={values}
            addSubdomain={addSubdomain}
          />
        )}
        {step === 3 && (
          <ManageWebsite
            setStep={setStep}
            setBanners={setBanners}
            banners={banners}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            values={values}
            setValues={setValues}
            userWebsite={userWebsite}
            onWebsiteChange={onWebsiteChange}
            updateSiteDataBySubDomain={updateSiteDataBySubDomain}
            setAdd={setAdd}
            setLatLng={setLatLng}
            newLocation={newLocation}
          />
        )}
        {step === 4 && <SuccessPage setStep={setStep} />}
      </div>
    </>
  );
}
export default MyWebsite;
