import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

export default function EmailVerification({ match }) {
  const history = useHistory();
  const [verifiedMessage, setVerifiedMessage] = useState();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/email-verification/${match?.params?.token}`
      )
      .then((response) => {
        if (response.data.success) {
          setVerifiedMessage(response.data.message);
        }
        setTimeout(history.push("/"), 3000);
      });
  }, [match?.params?.token, history]);
  return (
    <div className="sm-center" style={{ flex: 4, placeItems: "center" }}>
      <Row>
        <Col md={4}></Col>
        <Col md={4} className="mx-3">
          <h3>Email Verified Successfully</h3>
          <h3>{verifiedMessage}</h3>
        </Col>
      </Row>
    </div>
  );
}
