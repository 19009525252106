import { css } from "@emotion/css";

export const plansCard = (isMobile, regular) => css`
  position: relative;
  width: ${isMobile ? "336px" : "245px"};
  margin-left: ${!isMobile && "50px"};
  margin-bottom: ${isMobile ? "270px" : "25px"};

  .ImageCard {
    width: ${isMobile ? "336px" : "234px"};
    height: 366px;
    object-fit: cover;
    border-radius: 10px;
  }
  .header {
    height: ${isMobile ? "40px" : "50px"};
    background-image: linear-gradient(#6795fe, #6795fe);
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
  }
  .header-second {
    height: ${isMobile ? "40px" : "50px"};
    background-image: linear-gradient(#e0bd56, #e0bd56);
    color: #fff;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
  }
  .header-last {
    height: ${isMobile ? "40px" : "50px"};
    background-image: linear-gradient(#f889328a, #f88932);
    color: #fff;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;
  }
  .container {
    width: ${isMobile ? "336px" : "312px"};
    border-radius: 10px;
    position: absolute;
    overflow: hidden;
    margin-left: ${isMobile ? "" : "-80px"};
  }
  .columns {
    width: ${isMobile ? "auto" : "260px"};
    padding: ${isMobile ? "5px" : "8px"};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }

  .price {
    list-style-type: none;
    border: 1px solid #eee;
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .price:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
  }

  .price .header {
    font-size: 25px;
  }

  .price li {
    padding: 5px;
    text-align: center;
  }

  .plansButton {
    margin-left: auto;
    margin-top: ${regular ? "16px" : ""};
    margin-right: auto;
    width: 204px;
    height: 40px;
    padding: 0 16px;
    border-radius: 4px;
    border: solid 1px #d2d3d6;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  .plansButton:hover {
    background-color: rgb(248 137 50 / 29%);
  }
  .amount {
    color: #f88932;
    font-weight: bold;
    font-size: 20px;
  }
  .plan-icon-div {
    display: flex;
    margin-left: ${isMobile ? "30px" : "10px"};
    margin-right: 10px;
  }
  .text-dis {
    opacity: 0.56;
  }
  .close-icon {
    fill: gray;
    opacity: 0.56;
  }
`;
