import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Register from "../Authentication/Register";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import VendorList from "./VendorList";
import ForgotPassword from "../Authentication/ForgotPassword";
// import PlansCard from "../../components/PlansCard/PlansCard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FAQ from "./Faq";
import { bannerStyle, HomePageStyle } from "./styles";
import { useIsMobile } from "../../components/context/IsMobile";
import VideoPage from "./VideoPage";
import PhoneLogin from "../Authentication/PhoneLogin";
import { Card, Typography } from "@mui/material";
import SellItems from "./SellItems";
import Header from "./Header/Header";
import Content from "./Content";

export default function Home() {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [isLogin, setIsLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [phoneLogin, setPhoneLogin] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [, setPlans] = useState([]);
  const [country, setCountry] = useState(
    localStorage.getItem("CurrentLocation")
  );
  useEffect(() => {
    function getPlans() {
      if (!localStorage.getItem("CurrentLocation")) {
        fetch("https://ipapi.co/json")
          .then((response) => {
            response.json().then((jsonData) => {
              const { country_name } = jsonData;
              setCountry(country_name);
              localStorage.setItem("CurrentLocation", country_name);
            });
          })
          .catch(() => {
            localStorage.setItem("CurrentLocation", "");
          });
        var now = new Date().getTime() / 1000;
        localStorage.setItem("setupTimeForLocation", parseInt(now));
      }

      axios
        .post(`${process.env.REACT_APP_API_URL}/dashboard/plans`, { country })
        .then((response) => {
          setPlans(response?.data);
        });
    }
    getPlans();
  }, [country]);
  useEffect(() => {
    async function vendorsList() {
      axios
        .get(`${process.env.REACT_APP_API_URL}/getVendors?format=all&size=4`)
        .then((response) => {
          setVendors(response.data.data);
        });
    }
    vendorsList();
  }, []);
  // const buyPlan = () => {
  //   document.body.scrollTop = 0;
  //   document.documentElement.scrollTop = 0;
  //   if (!localStorage.getItem("user")) {
  //     toast.warn("Login Require !!", {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   }
  // };
  // const regularPlans = plans?.regularPlans;
  // const premiumPlans = plans?.premiumPlans?.sort((a, b) => a.id - b.id);
  if (
    localStorage.getItem("user") &&
    !JSON.parse(localStorage.getItem("user"))?.pendingInfo
  ) {
    history.push("/listings");
    return null;
  }
  return (
    <>
      <Header />
      <div className={HomePageStyle(isMobile)}>
        <div className={bannerStyle(isMobile)}>
          <div style={{ marginTop: "100px" }}>
            {/* <button className="offer-button">LIMITED TIME MEGA OFFER</button> */}
            <h1 style={{ fontWeight: "bold", fontSize: "24px" }}>
              Sell Online - Heavy Equipment & Machines | Commercial Vehicle
            </h1>
            <p className="p-text">
              Become a Seller & Start Your Selling Journey On IronList.
            </p>
            <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
              <button className="sell-button">Start Selling</button>
            </Link>
          </div>
          <div>
            <img
              src={`./assets/images/Home.png`}
              alt="Sell Machiens - Sell Online"
              className="banner-img"
            />
          </div>
        </div>

        <div className="card-div">
          <Card className="card-ele">
            <div>
              <img
                className="icon-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRlZ6v1Bdgi6oQYmidIAqL4Xe6CYeA_Ka9TA&usqp=CAU"
                alt="buyer"
              />
              <Typography className="card-typo" style={{ fontWeight: 600 }}>
                25000+ Buyers
              </Typography>
            </div>
            <Typography className="card-typotext">
              Over 25000+ buyers visit ironlist.com & search for heavy
              equipment, show them your machinery now.
            </Typography>
          </Card>
          <Card className="card-ele">
            <div>
              <img
                className="view-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0bVBuveiEpBVPgBMBkLBG1MJ_coPcvS9JYEta6HH3J3adE3PHGs_BNuGpbvczNX1qmLI&usqp=CAU"
                alt="views"
              />
              <Typography className="card-typo">5000+ Views</Typography>
            </div>
            <Typography className="card-typotext">
              More than 5000 items are viewed every day get your machinery
              visible today & sell your used equipment.
            </Typography>
          </Card>
          <Card className="card-ele">
            <div>
              <img
                className="regis-icon"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT39nxSwTVNuzaCLUDHubHp4txvlPWJR8_vcA&usqp=CAU"
                alt="registration"
              />
              <Typography className="card-typo">
                0 Registration Charges
              </Typography>
            </div>
            <Typography className="card-typotext">
              Register for free to get 30 free trial of our platform & start
              selling your Used heavy Equipment like Loader Backhoes, tractors,
              trucks & many more .
            </Typography>
          </Card>
        </div>

        <SellItems />

        <div className="heroBanner">
          {/* <div className="heroBannerInfo">
            {!isMobile && (
              <img
                src={ironListLogo}
                alt=""
                style={{ width: "150px", height: "32px" }}
              />
            )}
            <h3 style={{ color: "white" }}>
              <strong>Welcome to IronList</strong>
            </h3>
            <h6 style={{ color: "white" }}>
              Ironlist is one of the fastest growing online aggregator sale and
              auction platform for used Heavy equipment and Machinery for
              sectors like construction, transportation, agriculture, energy,
              oil and gas, mining, and forestry worldwide.
            </h6>
          </div> */}

          {/* {isLogin && !forgotPassword && !phoneLogin && (
            <Login
              setIsLogin={setIsLogin}
              isLogin={isLogin}
              history={history}
              setForgotPassword={setForgotPassword}
              setPhoneLogin={setPhoneLogin}
              isMobile={isMobile}
            />
          )} */}
          {forgotPassword && (
            <ForgotPassword
              isMobile={isMobile}
              setForgotPassword={setForgotPassword}
            />
          )}
          {phoneLogin && (
            <PhoneLogin isMobile={isMobile} setPhoneLogin={setPhoneLogin} />
          )}
          {!isLogin && (
            <Register
              setIsLogin={setIsLogin}
              isLogin={isLogin}
              history={history}
              isMobile={isMobile}
            />
          )}
          {/* {isMobile && <div className="SeparatorLine" />} */}
        </div>
        <VideoPage />
        {/* {isMobile ? null : <HowItsWorks onBoardSteps={onBoardSteps} />} */}
        <VendorList vendors={vendors} />
        {/* <PlansCard
        regularPlans={regularPlans}
        plans={premiumPlans}
        buyPlan={buyPlan}
      /> */}
        {/* {isMobile ? null : <WhyIronList whyIronList={whyIronList} />} */}
        <Content />
        <FAQ />
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
}
