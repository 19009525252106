import React from "react";
import { overlay, popupContainer } from "./style";

export default function ConfirmationPopUp({
  show,
  message,
  onConfirmClick,
  onCancelClick,
  buttonName,
  isMobile,
}) {
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
        height: "auto",
      }}
      className={overlay}
    >
      <div className={popupContainer(isMobile)}>
        <h5>{message}</h5>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn"
            style={
              buttonName === "Delete"
                ? { background: "#de1609", color: "white" }
                : { background: "#f88932", color: "white" }
            }
            onClick={onConfirmClick}
          >
            {buttonName}
          </button>
          <button
            type="button"
            className="btn mx-3"
            style={{ background: "#e0e1e3" }}
            onClick={onCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
