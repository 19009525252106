import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import {useIsMobile} from "../context/IsMobile/index.jsx";
import {imageStyle} from "./style.js";

const ImagesGallery = ({image_url}) => {
  var image_arr = JSON.parse(image_url);
  const isMobile = useIsMobile();
  const images = [];

  image_arr.map((item) => {
    images.push({original: item, thumbnail: item});
    return true;
  });

  return images ? (
    <ImageGallery
      additionalClass={imageStyle(isMobile)}
      items={images}
      showPlayButton={false}
      showFullscreenButton={false}
      showNav={false}
      showBullets={true}
    />
  ) : null;
};
export default ImagesGallery;
