import React from "react";
import { useIsMobile } from "../../components/context/IsMobile";
import { vendorContainer, gridMobileStyles, gridStyles } from "./styles";

export default function VendorList({ vendors }) {
  const isMobile = useIsMobile();
  return (
    <div className={vendorContainer(isMobile)}>
      <h2 className="text-center">
        <strong>Popular vendors on Ironlist</strong>
      </h2>
      <div className={isMobile ? gridMobileStyles : gridStyles}>
        {vendors.map((item) => (
          <div className="vendorItem" key={item.name}>
            <img src={item.logo_url} alt={item.name} className="vendorImage" />
            <h6 className="name-container" variant="p5">
              {item.name}
            </h6>
          </div>
        ))}
      </div>

      <a
        href="https://ironlist.com/vendors"
        target="_blank"
        style={{ color: "#f88932", textDecoration: "none" }}
        rel="noreferrer"
      >
        <div className="SeeMoreButton">
          <strong> See More</strong>
        </div>
      </a>
    </div>
  );
}
