import { useState, useEffect, useCallback } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listingContainer, MobileListingCard, plansTableStyle } from "./style";
import { useIsMobile } from "../../components/context/IsMobile";
import Pagination from "../../components/Pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import SelectSubdomain from "./SelectSubdomain";
import { Checkbox } from "@mui/material";
import { getStorage, setStorage } from "../../utils/localStorage";

export default function ClaimListings() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const isMobile = useIsMobile();
  const [siteDetailData, setSiteDetailData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [subDomain, setSubDomain] = useState("");
  const [claimIds, setClaimIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");
  const getClaimData = useCallback(() => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/dashboard/getClaimListing`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "POST",
      data: { page: Number(page) || 1, size: 7 },
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setLoading(false);
      }
      setData(response?.data?.data);
      setCount(response?.data?.count);
    });
    setTimeout(() => setLoading(false), 2000);
  }, [page]);
  useEffect(() => {
    getClaimData();
  }, [getClaimData]);

  const onAddClaimClick = () => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/dashboard/claimListing`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "POST",
      data: { subdomain_name: subDomain, productID: selectAll ? [] : claimIds },
    };
    axios(options).then((response) => {
      if (response.data.success) {
        getClaimData();
        setStorage("claimListingId", []);
        setClaimIds([]);
        toast.success(`${response.data.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  const getSiteDetailData = () => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/getSiteDetailData`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "GET",
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setSiteDetailData(response?.data?.response);
        setSubDomain(response?.data?.response[0]?.subdomain_name);
      }
    });
  };
  useEffect(() => {
    getSiteDetailData();
    if (getStorage("claimListingId")) {
      setClaimIds(getStorage("claimListingId"));
    }
  }, []);
  const userWebsite = [];
  siteDetailData.map((item) =>
    userWebsite.push({
      label: item.subdomain_name,
      value: item.subdomain_name,
    })
  );

  const onAddItem = (itemId) => {
    const claimListingId = getStorage("claimListingId") || [];
    claimListingId?.push(itemId);
    setStorage("claimListingId", claimListingId);
    setClaimIds(getStorage("claimListingId"));
  };

  const onRemoveItem = (itemId) => {
    const claimListingId = getStorage("claimListingId");
    const idx = claimListingId?.indexOf(itemId);
    if (idx !== -1) {
      claimListingId?.splice(idx, 1);
    }
    setStorage("claimListingId", claimListingId);
    setClaimIds(getStorage("claimListingId"));
  };
  if (
    !localStorage.getItem("user") ||
    JSON.parse(localStorage.getItem("user"))?.pendingInfo ||
    !JSON.parse(localStorage.getItem("user"))?.claimListing
  ) {
    history.push("/");
    return null;
  }

  return (
    <>
      <div className={listingContainer(isMobile)}>
        <h3 className="ListingHeader">Listings For Claim</h3>
        {loading && <LoadingSpinner />}
        {data.length > 0 && !isMobile && (
          <div className={plansTableStyle}>
            <table>
              <thead>
                <tr>
                  <th>
                    <Checkbox
                      checked={selectAll}
                      inputProps={{
                        "aria-label": "controlled",
                        padding: "0px",
                      }}
                      sx={{
                        color: "#f88932",
                        "&.Mui-checked": {
                          color: "#f88932",
                        },
                        padding: "0px",
                      }}
                      onChange={() => {
                        setSelectAll(!selectAll);
                        setStorage("claimListingId", []);
                        setClaimIds([]);
                      }}
                    />
                  </th>
                  <th>Image</th>
                  <th>ID</th>
                  <th>Item Title</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => (
                  <tr key={item.item_key}>
                    <td>
                      {claimIds?.indexOf(item?.item_key) !== -1 &&
                      !selectAll ? (
                        <Checkbox
                          checked={
                            claimIds?.indexOf(Number(item.item_key)) !== -1
                          }
                          inputProps={{
                            "aria-label": "controlled",
                            padding: "0px",
                          }}
                          sx={{
                            color: "#f88932",
                            "&.Mui-checked": {
                              color: "#f88932",
                            },
                            padding: "0px",
                          }}
                          onChange={() => onRemoveItem(Number(item.item_key))}
                        />
                      ) : (
                        <Checkbox
                          checked={selectAll}
                          inputProps={{
                            "aria-label": "controlled",
                            padding: "0px",
                          }}
                          sx={{
                            color: "#f88932",
                            "&.Mui-checked": {
                              color: "#f88932",
                            },
                            padding: "0px",
                          }}
                          onChange={() => onAddItem(Number(item.item_key))}
                        />
                      )}
                    </td>
                    <td>
                      <img
                        src={item?.thumbnail_url}
                        alt="itemImage"
                        width={48}
                        height={48}
                        className="item-image"
                      />
                    </td>
                    <td>{page > 1 ? index + 1 + 7 * (page - 1) : index + 1}</td>
                    <td>{item?.item_title}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {data.length > 0 && isMobile && (
          <>
            {data.map((item) => (
              <div className={MobileListingCard} key={item.item_key}>
                <div>
                  <img
                    src={item?.thumbnail_url}
                    alt="itemImage"
                    width={72}
                    height={72}
                    className="item-image"
                  />
                </div>

                <h6 className="card-title">
                  <strong>{item?.item_title}</strong>
                </h6>
              </div>
            ))}
          </>
        )}
        {data.length === 0 && (
          <div className="NoListing">
            <h4 className="description">
              You have No Listing's available at This moments.
            </h4>
          </div>
        )}
        {count > 0 && (
          <Pagination
            totalCount={count}
            pageSize={7}
            pathname={window.location.pathname}
          />
        )}
        {data.length > 0 && (
          <div className="addClaim">
            <Button
              variant="light"
              className="ClaimButton"
              onClick={() => {
                siteDetailData.length > 0
                  ? setShowPopup(true)
                  : onAddClaimClick();
              }}
            >
              Claim Your Listings
            </Button>
          </div>
        )}
      </div>
      <ToastContainer />
      {showPopup && siteDetailData.length > 0 && (
        <SelectSubdomain
          show={showPopup}
          isMobile={isMobile}
          onCancelClick={() => setShowPopup(false)}
          onConfirmClick={() => {
            onAddClaimClick();
            setShowPopup(false);
          }}
          setSubDomain={setSubDomain}
          subDomain={subDomain}
          userWebsite={userWebsite}
        />
      )}
    </>
  );
}
