import React, { useState, useEffect } from "react";
import Tabs, { TabPane } from "rc-tabs";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router-dom";
import { plansContainer } from "./style";
import PremiumCard from "../../components/PremiumCard";
// import RegularCard from "../../components/RegularCard";
import { imageUrl } from "../../utils";
import PlansTable from "./PlansTable";
import { useIsMobile } from "../../components/context/IsMobile";
import LoadingSpinner from "../../components/LoadingSpinner";
import Invoice from "./Invoice/Invoice";
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";

const stripePromise = loadStripe(
  "pk_live_51KBeGtSALcKOt3x0eajL4QBaxJU1EqfwJgfJVEqlkylztg0OvNTN6BwZduzAo5bsVH3xJkpeo4FvCh6Hz9UEO6qN00X3VirFz4"
);

const Prints = () => (
  <div>
    <Invoice />
  </div>
);

export default function Plans() {
  const [count] = useState(1);
  const [data, setData] = useState([]);
  const [activePlans, setActivePlans] = useState([]);
  const [orders, setOrders] = useState([]);
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);

  const onBuyNowClick = (PlansType, PlanId) => {
    const body = {
      plan_id: PlanId,
      quota: count,
      is_premium: PlansType === "regularPlan" ? 0 : 1,
      country: localStorage.getItem("CurrentLocation"),
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}/dashboard/card`, body, {
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("user"))
            ?.accessToken,
        },
      })
      .then(async (response) => {
        if (response.data.success) {
          console.log(`response`, response);
          // When the customer clicks on the button, redirect them to Checkout.
          const stripe = await stripePromise;
          const result = await stripe.redirectToCheckout({
            sessionId: response.data.id,
          });

          if (result.success) {
            console.log(result.success);
          }
          if (result.error) {
            console.log(result.error.message);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
          }
        }
      });
  };

  useEffect(() => {
    const country = localStorage.getItem("CurrentLocation");
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/dashboard/activePlans`,
        { country },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        }
      )
      .then((response) => {
        setActivePlans(response?.data?.plans);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/dashboard/plans`,
        { country },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        }
      )
      .then((response) => {
        if (response?.data) {
          setLoading(false);
        }
        setData(response?.data);
      });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/dashboard/orders`,
        { country },
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        }
      )
      .then((response) => {
        setOrders(response?.data?.orders);
      });
    setTimeout(() => setLoading(false), 3000);
  }, []);
  // const regularPlans = data?.regularPlans;
  const premiumPlans = data?.premiumPlans?.sort((a, b) => a.id - b.id);

  const history = useHistory();
  if (
    !localStorage.getItem("user") ||
    JSON.parse(localStorage.getItem("user"))?.pendingInfo
  ) {
    history.push("/");
    return null;
  }

  const exportPDF = () => {
    const string = renderToString(<Prints />);
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.fromHTML(string);
    pdf.save("pdf");
  };

  return (
    <>
      <div className={plansContainer(isMobile)}>
        <Tabs defaultActiveKey={activePlans?.length > 0 ? "tab-1" : "tab-2"}>
          <TabPane tab="Active Plan" key="tab-1">
            <div className="TableContainer">
              {activePlans?.length > 0 ? (
                <>
                  <PlansTable data={activePlans} />
                </>
              ) : (
                <div className="noActivePlans">
                  <div className="noActivePlansInfo">
                    <img
                      src={imageUrl("/images/asset-vendor-home-02.svg")}
                      alt="No Active Plans"
                      width={140}
                      height={142}
                    />
                    <h5>
                      <strong>No active plans!</strong>
                    </h5>
                    <p className="description">
                      You don’t have any active plans yet. Click on Plans &
                      Pricing to get one!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </TabPane>
          <TabPane tab="Plans & Pricing" key="tab-2">
            <div className="Cards">
              {!isMobile && <h3 className="PlansHeader">All plans</h3>}
              {loading && <LoadingSpinner />}
              {!loading && (
                <div className="PremiumPlans">
                  {/* {regularPlans?.map((item) => (
                    <RegularCard
                      count={count}
                      setCount={setCount}
                      key={item?.id}
                      item={item}
                      buyPlan={() => onBuyNowClick("regularPlan", item.id)}
                    />
                  ))} */}
                  {premiumPlans?.map((item) => (
                    <PremiumCard
                      key={item?.id}
                      item={item}
                      buyPlan={() => onBuyNowClick("premiumPlans", item.id)}
                    />
                  ))}
                </div>
              )}
            </div>
          </TabPane>
          <TabPane tab="Purchases" key="tab-3">
            <div className="TableContainer">
              {orders?.length > 0 ? (
                <>
                  <PlansTable data={orders} exportPDF={exportPDF} />

                  {/* <Invoice />

                  <Button
                    style={{
                      backgroundColor: "#f88932",
                      color: "#fff",
                      // marginBottom: "10px",
                      marginRight: "50px",
                      float: "right",
                    }}
                    onClick={exportPDF}
                  >
                    Download Invoice
                    <DownloadIcon />
                  </Button> */}
                </>
              ) : (
                <div className="noActivePlans">
                  <div className="noActivePlansInfo">
                    <img
                      src={imageUrl("/images/asset-vendor-home-02.svg")}
                      alt="No Active Plans"
                      width={140}
                      height={142}
                    />
                    <h5>
                      <strong>No Purchase History!</strong>
                    </h5>
                    <p className="description">
                      You don’t have any Purchase History yet. Click on Plans &
                      Pricing to get one!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
