import axios from "axios";
export const apiEndpoint = ` ${process.env.REACT_APP_API_URL}`;

export const sendOtp = async (body) => {
  const response = await axios.post(`${apiEndpoint}/sendOtp`, body);
  // console.log(response);
  return response.data;
};
export const verifyMobileOTP = async (body) => {
  const tokenCookies = JSON.parse(localStorage.getItem("user"))?.accessToken;

  const response = await axios.post(`${apiEndpoint}/verifyMobileOTP`, body, {
    headers: {
      ...(tokenCookies && { "x-access-token": tokenCookies }),
    },
  });
  // console.log("Verify", response);
  return response.data;
};
