import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { selectedIndicatorStyles, separatorStyles, tabStyles } from "./style";
import { useIsMobile } from "../context/IsMobile";
import { setStorage } from "../../utils/localStorage";

function SideBarTab({ tab, isSelected, onClick }) {
  const isMobile = useIsMobile();
  const onItemClick = useCallback(() => {
    onClick(tab);
    setStorage("claimListingId", []);
  }, [onClick, tab]);

  return (
    <div>
      <div className={tabStyles} onClick={onItemClick}>
        {isSelected ? <div className={selectedIndicatorStyles} /> : null}
        {isSelected ? (
          <div className="selectedIcons">{tab.Icon}</div>
        ) : (
          <>{tab.Icon}</>
        )}
        <div>
          {isSelected ? (
            <h5 className="tabLabel">
              <strong>{tab.label}</strong>
            </h5>
          ) : (
            <h5 className="tabLabel">{tab.label}</h5>
          )}
        </div>
      </div>
      {!isMobile && <div className={separatorStyles} />}
    </div>
  );
}

SideBarTab.propTypes = {
  tab: PropTypes.objectOf(PropTypes.any).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SideBarTab;
