import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import MidPage from "./MidPage";
import { backgroundStyle, machineStyle } from "./style";

const Compector = () => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title =
      "Sell Compactor Online On IronList | Heavy Equipment & Machines. Used Compactor For Sale | Compactor Machine | roller compactor | Used Soil Compactor for sale.";
  }, []);
  return (
    <div className={backgroundStyle}>
      <div style={{ overflow: "hidden" }}>
        <div className={machineStyle(isMobile)}>
          <div className="images-div">
            <div>
              <h4 className="h4-div">
                Sell Compactor Online On IronList | Heavy Equipment & Machines
              </h4>
              <div style={{ textAlign: "center" }}>
                <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
                  <button className="mid-sell-button">Start Selling</button>
                </Link>
              </div>
            </div>
            <img
              className="imgs"
              src={`./assets/images/Sell Compactor Online Ironlist.jpg`}
              alt="Compactor"
            />
          </div>
        </div>
        <MidPage />
      </div>
    </div>
  );
};

export default Compector;
