import { countCard, dashBoardStyle } from "./style";
import { useState, useEffect } from "react";
import axios from "axios";
import // ActiveListingCount,
// LeadsCount,
// TotalViewCount,
// WatchListCount,
"../../components/Icons/index";
import { useIsMobile } from "../../components/context/IsMobile";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import ViewListIcon from "@mui/icons-material/ViewList";
import PeopleIcon from "@mui/icons-material/People";

export default function DashBoard() {
  const [listingCount, setListingCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [watchListCount, setWatchListCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const isMobile = useIsMobile();
  const history = useHistory();

  useEffect(() => {
    const getCounts = async () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/dashboard/products/getCount`, {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            setLoading(false);
          }
          setListingCount(response?.data?.publishedListing);
        });

      axios
        .get(`${process.env.REACT_APP_API_URL}/dashboard/leads`, {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            setLoading(false);
          }
          setLeadsCount(response?.data?.leadCount || 0);
        });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/dashboard/products/getWatchlistCount`,
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("user"))
                ?.accessToken,
            },
          }
        )
        .then((response) => {
          if (response?.data?.success) {
            setLoading(false);
          }
          setWatchListCount(response?.data?.count);
        });
    };
    getCounts();
    setTimeout(() => setLoading(false), 2000);
  }, []);
  const countData = [
    {
      label: "Active listing count",
      count: listingCount,
      Icon: <ViewListIcon style={{ color: "white", fontSize: "35px" }} />,
      backGround: "#61b1f5",
    },
    // {
    //   label: "Total View",
    //   count: 0,
    //   Icon: <TotalViewCount />,
    //   backGround: "#ffc133",
    // },
    {
      label: "No. Of Leads",
      leadCount: leadsCount,
      Icon: <PeopleIcon style={{ color: "white", fontSize: "35px" }} />,
      backGround: "#21548f",
    },
    {
      label: "WatchList Count",
      count: watchListCount,
      Icon: (
        <PlaylistAddCheckCircleIcon
          style={{ color: "white", fontSize: "35px" }}
        />
      ),
      backGround: "#6abc13",
    },
  ];

  return (
    <div className={dashBoardStyle(isMobile)}>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="CountContainer">
          {countData.map((item) => (
            <div
              className={countCard(item?.backGround, isMobile)}
              onClick={() =>
                item.label === "No. Of Leads"
                  ? history.push("/leads")
                  : item.label === "Active listing count"
                  ? history.push("/listings")
                  : null
              }
            >
              <h5 className="label"> {item.label}</h5>
              <div className="div-dis">
                <>{item.Icon}</>
                <h2 className="count">
                  {item.count ? item.count : item.leadCount}
                </h2>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
