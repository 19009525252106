import React from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../../components/context/IsMobile";
import { mainNavStyle, navMobileStyle } from "./style";

const Header = () => {
  const isMobile = useIsMobile();
  return (
    <div className={mainNavStyle}>
      <div className={navMobileStyle(isMobile)}>
        <a href="https://ironlist.com" target="_blank" rel="noreferrer">
          <img
            src="https://d3g7ucapfhrqqt.cloudfront.net/images/ironlist-logo-2.webp"
            alt="IronList"
            className="logo-div mx-3 my-2"
          />
        </a>
        <div className="mbuttons-div">
          <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
            <button className="mstart-button">Start Selling</button>
          </Link>
          <Link to={"/login"} style={{ textDecoration: "none" }}>
            <button className="mlogin-button">Login</button>
          </Link>
        </div>
      </div>
      {/* <div className={listStyle(isMobile)}>
        <ul className="mul_div">
          <li className="mli_div">Resources</li>
          <a href="/" className="faq-link">
            <li className="faq_div">FAQs</li>
          </a>
        </ul>
      </div> */}
    </div>
  );
};

export default Header;
