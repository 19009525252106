import { invitePageStyle } from "./style";
import { useState, useEffect } from "react";
import { isEmail } from "validator";
import authService from "../Authentication/auth.service";
import axios from "axios";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { ToastContainer, toast } from "react-toastify";
import { useIsMobile } from "../../components/context/IsMobile";
import { backGroundImage, ironListLogo } from "../../utils/const";
import { useHistory } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const InviteUser = ({ match }) => {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState(false);
  const [comparePassword, setComparePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          Please Enter Your Valid Email.
        </div>
      );
    }
  };
  useEffect(() => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/getInviteDetails`,
      headers: {
        "x-access-token": match?.params?.inviteUrl,
      },
      method: "POST",
    };
    axios(options)
      .then((response) => {
        if (response.data.success) {
          setCompanyName(response.data.companyName);
          setEmailExists(response.data.emailExists);
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => history.push("/"), 3000);
        }
      })
      .catch((response) => {
        if (response.response.status === 401) {
          toast.error(`Invite url not exists`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => history.push("/"), 3000);
        }
      });
  }, [match?.params?.inviteUrl, history]);

  const onCreate = (e) => {
    e.preventDefault();
    setLoading(true);
    var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    if (regex.test(password)) {
      setCheckPassword(false);
    } else {
      setCheckPassword(true);
    }
    if (password !== confirmPassword) {
      setComparePassword(true);
    }
    if (password === confirmPassword || checkPassword) {
      setComparePassword(false);
    }
    if (password === confirmPassword && regex.test(password)) {
      const options = {
        url: `${process.env.REACT_APP_API_URL}/setVendorInfo`,
        headers: {
          "x-access-token": match?.params?.inviteUrl,
        },
        method: "POST",
        data: {
          companyName,
          password,
          email,
        },
      };
      axios(options)
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
            toast.success(`${response.data.message}`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            if (email) {
              authService.resendEmailVerification(email).then((response) => {
                if (response.data.success) {
                  toast.success(`${response.data.message}`, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
              });
            }
            setTimeout(() => history.push("/"), 3000);
          }
        })
        .catch((response) => {
          if (response.response.status === 401) {
            setLoading(false);
            toast.error(`Invite url not exists`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setTimeout(() => history.push("/"), 3000);
          }
        });
    }
    setTimeout(setLoading(false), 3000);
  };
  if (localStorage.getItem("user")) {
    history.push("/listings");
    return null;
  }
  return (
    <>
      <ToastContainer />
      <div className={invitePageStyle(isMobile)}>
        <img className="backGroundImage" src={backGroundImage} alt="" />
        <div className="heroBanner">
          <div className="heroBannerInfo">
            {!isMobile && (
              <img
                src={ironListLogo}
                alt=""
                style={{ width: "150px", height: "32px" }}
              />
            )}
            <h3 style={{ color: "white" }}>
              <strong>Welcome to IronList</strong>
            </h3>
            <h6 style={{ color: "white" }}>
              Ironlist is one of the fastest growing online aggregator sale and
              auction platform for used Heavy equipment and Machinery for
              sectors like construction, transportation, agriculture, energy,
              oil and gas, mining, and forestry worldwide.
            </h6>
          </div>
          <div className="FormWrapper">
            <Form>
              {!emailExists && (
                <div className="form-group mt-4 mb-3">
                  <label htmlFor="email">Email</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    validations={[required, validEmail]}
                  />
                </div>
              )}
              <div className="form-group mb-3">
                <label htmlFor="company_name">Company Name</label>
                <Input
                  placeholder="Enter your name"
                  type="text"
                  className="form-control"
                  name="user name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  validations={[required]}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="new password">Create Password</label>
                <Input
                  placeholder="Enter password"
                  type="password"
                  className="form-control"
                  name="new password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setComparePassword(false);
                    setCheckPassword(false);
                  }}
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="confirm password">Confirm Password</label>
                <Input
                  placeholder="Enter confirm password"
                  type="password"
                  className="form-control"
                  name="confirm password"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setComparePassword(false);
                    setCheckPassword(false);
                  }}
                />
              </div>

              {checkPassword && (
                <p className="alert alert-danger" role="alert">
                  Minimum eight and maximum 10 characters, at least one
                  uppercase letter, one lowercase letter, one number and one
                  special character
                </p>
              )}
              {comparePassword && !checkPassword && (
                <p className="alert alert-danger" role="alert">
                  Password Do not match
                </p>
              )}
              <div className="form-group my-2">
                <button
                  className="btn"
                  disabled={loading}
                  style={{ background: "#f88932", color: "white" }}
                  onClick={onCreate}
                >
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Create Account</span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteUser;
