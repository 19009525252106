import React from "react";

export default function ProfileInfo({ userData, onClick }) {
  return (
    <div className="ProfileContainer">
      <div className="headerContainer">
        <h3 className="header">Profile</h3>
        <h6 className="updateProfile" onClick={onClick}>
          Edit Profile
        </h6>
      </div>

      <div className="userProfile">
        <div className="userFiled">
          <h6 className="tag">Name :</h6>
          <h6>
            <strong>
              {userData?.name} {userData?.lastName}{" "}
            </strong>
          </h6>
        </div>
        <div className="userFiled">
          <h6 className="tag">Email : </h6>
          <h6>
            <strong>{userData?.email}</strong>
          </h6>
        </div>
        <div className="userFiled">
          <h6 className="tag">Contact Number :</h6>
          <h6>
            <strong>
              +{userData?.dialCode} {userData?.phone}
            </strong>
          </h6>
        </div>
        <div className="userFiled">
          <h6 className="tag">Company Name: </h6>
          <h6>
            <strong>{userData?.companyName}</strong>
          </h6>
        </div>
        <div className="userFiled">
          <h6 className="tag">Vendor Type : </h6>
          <h6>
            <strong>{userData?.vendorType}</strong>
          </h6>
        </div>
        <div className="userFiled">
          <h6 className="tag">Country : </h6>
          <h6>
            <strong>{userData?.country}</strong>
          </h6>
        </div>
      </div>
    </div>
  );
}
