import { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import ProfileInfo from "./ProfileInfo";
import UpdateProfile from "./UpdateProfile";
import authService from "../Authentication/auth.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { profileStyle } from "./styles";
import { useIsMobile } from "../../components/context/IsMobile";
import LoadingSpinner from "../../components/LoadingSpinner";
export default function Profile() {
  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    companyName: "",
    vendorType: "agent",
    country: "",
    dialCode: "",
    name: "",
    phoneCountry: "",
  });
  const isMobile = useIsMobile();
  const [updateProfile, setUpdateProfile] = useState(false);
  const [oldNumber, setOldNumber] = useState("");
  const [otpInput, setOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [key, setKey] = useState("");
  const [verifyOTPSuccess, setVerifyOTPSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const getUserData = () => {
    if (localStorage.getItem("user")) {
      const options = {
        url: `${process.env.REACT_APP_API_URL}/getUserProfile`,
        headers: {
          "x-access-token": JSON.parse(localStorage.getItem("user"))
            ?.accessToken,
        },
        method: "POST",
      };
      axios(options).then((response) => {
        if (response?.data?.success) {
          setLoading(false);
        }
        setUserData(response.data.data);
        setOldNumber(
          response.data.data.dialCode.concat(response.data.data.phone)
        );
      });
    }
  };
  useEffect(() => {
    getUserData();
    setTimeout(() => setLoading(false), 2000);
  }, []);

  const onCancelClick = () => {
    setUpdateProfile(false);
    getUserData();
  };
  const submitUpdateProfile = (e) => {
    e.preventDefault();
    userData.firstName = userData.name;
    setUserData(userData);
    if (
      userData.dialCode.concat(userData.phone) !== oldNumber &&
      !verifyOTPSuccess
    ) {
      toast.error(`Please Verify Your Mobile Number!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (
      userData.dialCode.concat(userData.phone) === oldNumber ||
      (userData.dialCode.concat(userData.phone) !== oldNumber &&
        verifyOTPSuccess)
    )
      authService.updateProfile(userData).then((response) => {
        if (response?.data?.success) {
          setUpdateProfile(false);
          toast.success("Profile Updated Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getUserData();
        }
      });
  };
  const onSendOTP = (e) => {
    e.preventDefault();
    authService
      .sendOTP({
        phone: userData.phone,
        countryCode: userData.dialCode,
        phoneNo: userData.dialCode.concat(userData.phone),
      })
      .then((response) => {
        if (response.data.success) {
          setOtpInput(true);
          setKey(response.data.key);
          toast.success(`OTP Send Successfully !`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const onVerifyMobileOTP = (e) => {
    e.preventDefault();
    authService
      .verifyMobileOTP({
        phoneNo: userData.dialCode.concat(userData.phone),
        otp,
        key,
      })
      .then((response) => {
        if (response.data.success) {
          setVerifyOTPSuccess(true);
          setOtpInput(false);
          toast.success(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const history = useHistory();
  if (
    !localStorage.getItem("user") ||
    JSON.parse(localStorage.getItem("user"))?.pendingInfo
  ) {
    history.push("/");
    return null;
  }
  return (
    <>
      <div className={profileStyle(isMobile)}>
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {!updateProfile && !isMobile && (
              <ProfileInfo
                userData={userData}
                onClick={() => setUpdateProfile(true)}
              />
            )}
            {isMobile && !updateProfile && (
              <ProfileInfo
                userData={userData}
                onClick={() => setUpdateProfile(true)}
              />
            )}
            {(updateProfile || isMobile) && !isMobile && (
              <UpdateProfile
                isMobile={isMobile}
                userData={userData}
                onCancelClick={onCancelClick}
                setUserData={setUserData}
                submitUpdateProfile={submitUpdateProfile}
                onSendOTP={onSendOTP}
                onVerifyMobileOTP={onVerifyMobileOTP}
                otpInput={otpInput}
                otp={otp}
                setOtp={setOtp}
                verifyOTPSuccess={verifyOTPSuccess}
              />
            )}
            {isMobile && updateProfile && (
              <UpdateProfile
                isMobile={isMobile}
                userData={userData}
                onCancelClick={onCancelClick}
                setUserData={setUserData}
                submitUpdateProfile={submitUpdateProfile}
                onSendOTP={onSendOTP}
                onVerifyMobileOTP={onVerifyMobileOTP}
                otpInput={otpInput}
                otp={otp}
                setOtp={setOtp}
                verifyOTPSuccess={verifyOTPSuccess}
              />
            )}
          </>
        )}
      </div>
      <ToastContainer />
    </>
  );
}
