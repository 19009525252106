import {
  AccountThick,
  DocumentsThick,
  GridThick,
  PriceThick,
  UserFriendThick,
} from "@ironlist/ironlist-icons";
// import SummarizeIcon from "@mui/icons-material/Summarize";
import {MyWebsiteIcon} from "../Icons";

export const getSidebarTabs = [
  {
    id: "DASHBOARD",
    label: "Dashboard",
    Icon: <GridThick />,
    link: "/dashboard",
  },
  {
    id: "LISTINGS",
    label: "Listings",
    Icon: <PriceThick />,
    link: "/listings",
  },
  {
    id: "CLAIMLISTINGS",
    label: "Claim Listings",
    Icon: <PriceThick />,
    link: "/claimListings",
  },
  {
    id: "LEADS",
    label: "Leads",
    Icon: <UserFriendThick />,
    link: "/leads",
  },
  {
    id: "PLANS",
    label: "Plans",
    Icon: <DocumentsThick />,
    link: "/plans",
  },
  {
    id: "MYWEBSITE",
    label: "My WebSite",
    Icon: <MyWebsiteIcon />,
    link: "/myWebsite",
  },
  {
    id: "MYPROFILE",
    label: "My Profile",
    Icon: <AccountThick />,
    link: "/myProfile",
  },
];
