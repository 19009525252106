import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ModalStyle } from "./style";
import ImagesGallery from "../../components/Silder";
import { useIsMobile } from "../../components/context/IsMobile";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "1%",
  left: "30%",
  bottom: "3%",
  width: 525,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  width: 350,
  height: "auto",
  bgcolor: "background.paper",
  border: "none",
  p: 4,
  marginLeft: "15px",
  marginTop: "10px",
};

export default function ModalView({ data }) {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const images = data.img_url;
  // console.log(data);

  return (
    <>
      <VisibilityIcon style={{ color: "#f88932" }} onClick={handleOpen} />

      <div className={ModalStyle}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={isMobile ? style1 : style}>
            <CloseIcon
              onClick={handleClose}
              style={{ float: "right", margin: "-25px" }}
            />
            <div>
              <ImagesGallery image_url={images} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                  marginTop: "15px",
                }}
              >
                <p>Title</p>
                <p>{data.item_title}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Year</p>
                <p>{data.year}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Make</p>
                <p id="modal-modal-description" sx={{ mt: 2 }}>
                  {data.make}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Model</p>
                <p>{data.model}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Main Category</p>
                <p>{data.main_category}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Category</p>
                <p>{data.category}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>SubCategory</p>
                <p>{data.sub_category}</p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Price</p>
                <p>{data.price_sale}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "-15px",
                }}
              >
                <p>Address</p>
                <p>
                  {data.city},{data.state},{data.country}
                </p>
              </div>

              {/* <Button
                variant="contained"
                style={{
                  background: "#f88932",
                  width: "150px",
                  marginTop: "10px",
                  float: "right",
                }}
                onClick={handleClose}
              >
                Close
              </Button> */}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
