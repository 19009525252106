import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import { successMessage } from "../Plans/style";

const SubdomainPaymentSuccess = ({ match }) => {
  useEffect(() => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/subdomainCheckout?id=${match.params.id}`,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
      },
      method: "POST",
    };
    axios(options);
  }, [match?.params?.id]);
  const history = useHistory();
  const onGoToOrder = () => {
    history.push("/myWebsite");
  };
  const isMobile = useIsMobile();
  return (
    <div className={successMessage(isMobile)}>
      <h2>Thanks for your order!</h2>
      <h4>Your payment is successful.</h4>
      <p>
        We appreciate your business! If you have any questions, please email
        <a href="mailto:ironlist@ironlist.com"> ironlist@ironlist.com </a>.
      </p>
      <div>
        <button
          className="btn"
          style={{ background: "#f88932", color: "white" }}
          onClick={onGoToOrder}
        >
          Go to Order page
        </button>
      </div>
    </div>
  );
};

export default SubdomainPaymentSuccess;
