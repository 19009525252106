import { createContext, useContext, useEffect, useState } from "react";

const IsMobileContext = createContext(null);

export const useIsMobile = (query = "(max-width: 767px)") => {
  const defaultIsMobile = useContext(IsMobileContext);
  const [isMobile, setIsMobile] = useState(defaultIsMobile);

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);

    const onChange = () => {
      if (!mounted) {
        return;
      }
      setIsMobile(!!mql.matches);
    };

    mql.addListener(onChange);
    setIsMobile(mql.matches);

    return () => {
      mounted = false;
      mql.removeListener(onChange);
    };
  }, [query]);

  return isMobile;
};

export default IsMobileContext;
