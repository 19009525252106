import { css } from "@emotion/css";

export const productStyle = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  background-color: #ffffff;
  ${isMobile
    ? `
     margin: 0 auto;
     padding-bottom: 64px;
     border-top: 1px solid #e0e1e3;`
    : ` margin-left: 20px;
        border: 1px solid #e0e1e3;
        border-radius: 4px;
        margin-bottom: 64px;`};
  .backButton {
    cursor: pointer;
    margin-right: ${isMobile ? "8px" : "16px"};
  }
  .cancelButton {
    color: #f88932;
    margin-bottom: 0px;
    cursor: pointer;
    font-weight: 800;
    font-size: ${isMobile ? "14px" : ""};
  }
  .headerContainer {
    ${isMobile &&
    `    position: fixed;
         top: 0px;
         background-color: white;
        z-index: 1;
        width:100%;`};
    padding: ${isMobile ? "16px 12px" : "24px"};
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e1e3;
  }
  .header {
    text-align: center;
    font-size: ${isMobile ? "18px" : "22px"};
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    margin-bottom: 0;
  }
  .formStyle {
    ${isMobile
      ? `width:336px; margin: 0 auto; margin-top:16px;`
      : `padding:24px`};
  }
  .separatorLine {
    margin: 24px 0px;
    height: 1px;
    background-color: #e0e1e3;
  }
  .productTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .productAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }

  .productTop {
    display: flex;
  }

  .productTopLeft,
  .productTopRight {
    flex: 1;
  }

  .productTopRight {
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }

  .productInfoImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
  }

  .productInfoTop {
    display: flex;
    align-items: center;
  }

  .productName {
    font-weight: 600;
  }

  .productInfoBottom {
    margin-top: 10px;
  }

  .productInfoItem {
    width: 150px;
    display: flex;
    justify-content: space-between;
  }

  .productInfoValue {
    font-weight: 300;
  }

  .productBottom {
    padding: 20px;
    margin: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }

  .productForm {
    display: flex;
    justify-content: space-between;
  }

  .productFormLeft {
    display: flex;
    flex-direction: column;
  }

  .productFormLeft > label {
    margin-bottom: 10px;
    color: gray;
  }

  .productFormLeft > input {
    margin-bottom: 10px;
    border: none;
    padding: 5px;
    border-bottom: 1px solid gray;
  }

  .productFormLeft > select {
    margin-bottom: 10px;
  }

  .productUploadImg {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 20px;
  }

  .productFormRight {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .productUpload {
    display: flex;
    align-items: center;
  }

  .productButton {
    border: none;
    padding: 5px;
    border-radius: 5px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .autocomplete-dropdown-container {
    position: "absolute";
    z-index: 1000;
  }

  .location-search-input,
  .location-search-input:focus,
  .location-search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
  }

  .autocomplete-dropdown-container {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    z-index: 1000;
    width: ${isMobile ? "90%" : "30%"};
    border-radius: 10px;
    margin-top: 10px;
  }
  .specifications-desc {
    font-size: 14px;
    width: ${isMobile ? "336px" : "470px"};
  }
  .saveButton {
    width: ${isMobile ? "100%" : "140px"};
    background: #f88932;
    color: white;
    ${!isMobile && `margin-bottom:24px; float: right; margin-right: 20px`}
  }
  .save-button-div {
    ${isMobile &&
    ` width: 100%;
    margin:0 auto;
   height: 56px;
   padding: 8px;
   box-shadow: 0 1px 4px 0 rgba(55, 70, 95, 0.12);
   background-color: white;
   position:sticky;
   bottom:0px;
   margin-top:32px;`};
  }
  .year {
    border: none;
    background-color: rgb(244, 244, 245);
    height: 48px;
    width: ${isMobile ? "100%" : "49%"};
    padding: 12px;
  }
  .product-img {
    margin-left: ${isMobile ? "" : "40px"};
  }
  .product-textarea {
    height: ${isMobile ? "" : "100px"};
    margin-left: ${isMobile ? "" : "35px"};
  }
`;
