import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import authService from "./auth.service";
import OtpInput from "react-otp-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChevronleftThick } from "@ironlist/ironlist-icons";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const password = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};
const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        Please Enter Your Valid Email.
      </div>
    );
  }
};

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.sendOtp = this.sendOtp.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onConfirmPassword = this.onConfirmPassword.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
    this.onResendOtp = this.onResendOtp.bind(this);

    this.state = {
      email: "",
      otp: "",
      newPassword: "",
      confirmPassword: "",
      loading: false,
      message: "",
      successFull: false,
      done: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  }
  handleClickShowPassword = () => {
    this.setState({
      ...this.state.newPassword,
      showPassword: !this.state.showPassword,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      ...this.state.confirmPassword,
      showConfirmPassword: !this.state.showConfirmPassword,
    });
  };
  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
      message: "",
    });
  }

  onChangePassword(e) {
    this.setState({
      newPassword: e.target.value,
    });
  }
  onConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  sendOtp(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });
    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      authService.forgotPassword(this.state.email).then(
        (response) => {
          this.setState({
            loading: false,
            successFull: response.data.success,
            message: response.data.message,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  onClickDone(e) {
    e.preventDefault();
    this.setState({
      message: "",
      loading: true,
    });
    this.form.validateAll();
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        message: "Password Do Not Match",
        loading: false,
      });
    }
    if (
      this.checkBtn.context._errors.length === 0 &&
      this.state.newPassword === this.state.confirmPassword
    ) {
      authService
        .otpVerify(this.state.otp, this.state.newPassword, this.state.email)
        .then(
          (response) => {
            this.setState({
              loading: false,
              done: response.data.success,
              message: response.data.message,
            });
            if (this.state.done) {
              window.setTimeout(function () {
                window.location.href = "/";
              }, 2000);
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            this.setState({
              loading: false,
              message: resMessage,
            });
          }
        );
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  onResendOtp(e) {
    e.preventDefault();
    authService.forgotPassword(this.state.email).then((response) => {
      if (response.data.success) {
        toast.success(`${response.data.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  }

  render() {
    return (
      <div
        className="bg-white"
        style={{
          borderRadius: "4px",
          padding: "32px",
          width: this.props.isMobile ? "360px" : "474px",
          height: this.props.isMobile ? "" : "694px",
        }}
      >
        {!this.state.successFull && (
          <>
            <h3 className="signupHeader">
              <ChevronleftThick
                className="leftThick"
                onClick={() => this.props.history.push("/login")}
                title="Chevron left"
              />
              <strong>Forgot Password</strong>
            </h3>
            <p>{`Enter the email address associated with your account, and we’ll email you an OTP to reset your password.`}</p>
            <Form
              onSubmit={this.sendOtp}
              ref={(c) => {
                this.form = c;
              }}
            >
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChangeEmail}
                  validations={[required, email]}
                />
              </div>

              <div className="form-group my-2">
                <button
                  className="btn"
                  disabled={this.state.loading}
                  style={{ background: "#f88932", color: "white" }}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Send OTP</span>
                </button>
              </div>

              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
            {this.state.message && !this.state.successFull && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </>
        )}
        {this.state.successFull && (
          <>
            <h3 className="signupHeader">
              <ChevronleftThick
                className="leftThick"
                onClick={() =>
                  this.setState({
                    successFull: false,
                    message: "",
                  })
                }
                title="Chevron left"
              />
              <strong>Enter OTP and New Password</strong>
            </h3>
            {this.state.message !== "Password Do Not Match" &&
              this.state.successFull &&
              !this.state.done && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}

            <div className="form-group mt-3">
              <h5>Enter OTP</h5>
              <OtpInput
                autoComplete="off"
                value={this.state.otp}
                onChange={(value) => {
                  this.setState({ otp: value });
                }}
                numInputs={6}
                separator={" "}
                placeholder="0"
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  marginRight: "6px",
                  fontSize: "1.5rem",
                  borderRadius: 4,
                  border: "1px solid grey",
                }}
              />

              <h6
                className="mt-3"
                style={{
                  color: "#f88932",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={this.onResendOtp}
              >
                Resend OTP
              </h6>
            </div>
            <Form
              onSubmit={this.onClickDone}
              ref={(c) => {
                this.form = c;
              }}
            >
              <div className="form-group mt-3">
                <label htmlFor="newPassword">New Password</label>
                <Input
                  autoComplete="new-password"
                  type={this.state.showPassword ? "text" : "password"}
                  className="form-control"
                  name="New Password"
                  value={this.state.newPassword}
                  onChange={this.onChangePassword}
                  placeholder="Enter New Password"
                  validations={[required, password]}
                />{" "}
                {this.state.showPassword ? (
                  <Visibility
                    onClick={this.handleClickShowPassword}
                    style={{
                      marginTop: "-75px",
                      color: "gray",
                      marginLeft: "90%",
                    }}
                  />
                ) : (
                  <VisibilityOff
                    onClick={this.handleClickShowPassword}
                    style={{
                      marginTop: "-75px",
                      color: "gray",
                      marginLeft: "90%",
                    }}
                  />
                )}
              </div>
              <div className="form-group mt-3">
                <label htmlFor="confirmPassword">Confirm Password</label>
                <Input
                  autoComplete="confirm-password"
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  className="form-control"
                  name="Confirm Password"
                  value={this.state.confirmPassword}
                  onChange={this.onConfirmPassword}
                  placeholder="Enter Confirm Password"
                  validations={[required, password]}
                />
                {this.state.showConfirmPassword ? (
                  <Visibility
                    onClick={this.handleClickShowConfirmPassword}
                    style={{
                      marginTop: "-75px",
                      color: "gray",
                      marginLeft: "90%",
                    }}
                  />
                ) : (
                  <VisibilityOff
                    onClick={this.handleClickShowConfirmPassword}
                    style={{
                      marginTop: "-75px",
                      color: "gray",
                      marginLeft: "90%",
                    }}
                  />
                )}
              </div>
              <div className="form-group my-2">
                <button
                  className="btn"
                  style={{ background: "#f88932", color: "white" }}
                  disabled={this.state.loading}
                >
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Done</span>
                </button>
              </div>

              {this.state.message && this.state.done && (
                <div className="form-group">
                  <div className="alert alert-success" role="alert">
                    {this.state.message}
                  </div>
                </div>
              )}
              {this.state.message &&
                !this.state.done &&
                !this.state.successFull && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}
              {this.state.message === "Password Do Not Match" &&
                this.state.successFull &&
                !this.state.done && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}

              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </>
        )}
        <ToastContainer />
      </div>
    );
  }
}
