import { css } from "@emotion/css";

export const filterListStyle = (isMobile) => css`
  ${isMobile && `margin-bottom: 16px; width: 336px;`};
  .list {
    height: 200px;
    overflow: auto;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-top: 12px;
  }

  .list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e0e1e3;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #8f9197;
  }
  .list li {
    list-style-type: none;
    text-align: center;
    border-bottom: 1px solid #ced4da;
  }
  .list li:hover {
    background-color: #ced4da;
  }
  #myInput {
    width: 100%; /* Full-width */
    font-size: 16px; /* Increase font-size */
    height: 38px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da; /* Add a grey border */
    border-radius: 0.25rem;
    margin-bottom: 12px; /* Add some space below the input */
    transition: 0.15s ease-in-out;
  }
`;
