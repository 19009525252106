import "./product.css";
import "./prog-track.css";
import React, {useState} from "react";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import {productStyle} from "./style";
import {useIsMobile} from "../../components/context/IsMobile";
import useProduct from "./useProudct";
import "react-step-progress-bar/styles.css";
import MultiStepProgressBar from "./MultiStepProgressBar/MultiStepProgressBar";
import {useRouteMatch} from "react-router-dom";
import {createProduct, updateProduct} from "../../apis/product";
import {useHistory} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Product() {
  const isMobile = useIsMobile();
  const history = useHistory();
  const [product, setProduct] = useState({
    id: "",
    item_title: "",
    make: "",
    model: "",
    year: "",
    buying_format: "",
    img_url: [],
    currency: "Price On Request",
    price_sale: "",
    price_rent: "",
    rent_format: "Daily",
    is_sold: "",
    main_category: "",
    main_category_id: "",
    category: "",
    category_id: "",
    sub_category: "",
    sub_category_id: "",
    add_specification: [],
    description: "",
    youtube_link: "",
    is_attachment: "",
    condition: "used",
    city: "",
    state: "",
    country: "",
    i_lat: "",
    i_long: "",
    thumbnail_url: "",
    subdomain_name: "",
  });
  const [autoSuggestion, setAutoSuggestion] = useState([]);
  const [address, setAdd] = useState();
  const [latLng, setLatLng] = useState();
  const [l1Data, setL1Data] = useState([]);
  const [l2Data, setL2Data] = useState([]);
  const [l3Data, setL3Data] = useState([]);
  const [showCategorySuggestion, setCategorySuggestion] = useState(false);
  const [showMakeSuggestion, setMakeSuggestion] = useState(false);
  const [showModelSuggestion, setModelSuggestion] = useState(false);
  const [showYearSuggestion, setYearSuggestion] = useState(false);
  const [imageUrl, setImageUrl] = useState([]);
  const [isValidItemTitle, setValidItemTitle] = useState(true);
  const [isValidSubCategory, setValidSubCategory] = useState(true);
  const [isValidMake, setValidMake] = useState(true);
  const [isValidModel, setValidModel] = useState(true);
  const [isValidCountry, setValidCountry] = useState(true);
  const [isValidYear, setValidYear] = useState(true);
  const [isPriceDisable, setPriceDisable] = useState(false);
  const [tags, setTags] = useState([]);
  const [suggestionsModel, setAutoSuggestionModel] = useState([]);
  const match = useRouteMatch();
  const {
    onChange,
    getSubCategory,
    getCategory,
    handleOnClickSuggestion,
    newLocation,
    onBlurCaptureHandler,
    onFocusHandler,
  } = useProduct(
    setProduct,
    product,
    autoSuggestion,
    setAutoSuggestion,
    l1Data,
    setL1Data,
    l2Data,
    setL2Data,
    l3Data,
    setL3Data,
    showCategorySuggestion,
    setCategorySuggestion,
    showMakeSuggestion,
    setMakeSuggestion,
    showModelSuggestion,
    setModelSuggestion,
    showYearSuggestion,
    setYearSuggestion,
    address,
    setValidItemTitle,
    setValidMake,
    setValidModel,
    setValidYear,
    setValidCountry,
    setValidSubCategory,
    setPriceDisable,
    setImageUrl,
    setAutoSuggestionModel,
    setTags
  );
  const [page, setPage] = useState("pageone");
  const nextPage = async () => {
    if (page === "pageone") {
      if (!product?.item_title) setValidItemTitle(false);
      if (!product?.sub_category) setValidSubCategory(false);

      if (!product?.make) setValidMake(false);
      if (!product?.model) setValidModel(false);
      if (!product?.country) setValidCountry(false);
      if (!product?.year) setValidYear(false);
      if (
        product?.item_title &&
        product?.sub_category &&
        product?.make &&
        product?.model &&
        product?.country &&
        product?.year
      ) {
        setPage("pagetwo");
      }
    }
    if (page === "pagetwo") setPage("pagethree");
    if (page === "pagethree") {
      product.img_url = imageUrl;
      product.thumbnail_url = imageUrl && imageUrl[0];
      if (product?.buying_format === "sale") {
        product.price_sale = product?.price;
      } else {
        product.price_rent = product?.price;
      }
      delete product?.price;
      if (match.params.productId) {
        let response = await updateProduct(match.params.productId, product);
        if (response.success) {
          setTimeout(history.push("/listings"), 3000);
          toast.success("Item Updated Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        const response = await createProduct(product);
        if (response.success) {
          setTimeout(history.push("/listings"), 3000);
          toast.success("Item Created Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };
  console.log(product);
  const nextPageNumber = (pageNumber) => {
    console.log(pageNumber, "in switch case");
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        break;
      case "2":
        if (!product?.item_title) setValidItemTitle(false);
        if (!product?.sub_category) setValidSubCategory(false);

        if (!product?.make) setValidMake(false);
        if (!product?.model) setValidModel(false);
        if (!product?.country) setValidCountry(false);
        if (!product?.year) setValidYear(false);
        if (
          product?.item_title &&
          product?.sub_category &&
          product?.make &&
          product?.model &&
          product?.country &&
          product?.year
        ) {
          setPage("pagetwo");
        }
        break;
      case "3":
        setPage("pagethree");
        break;
      default:
        setPage("1");
    }
  };

  return (
    <>
      <div className={productStyle(isMobile)}>
        <ToastContainer />
        <MultiStepProgressBar page={page} onPageNumberClick={nextPageNumber} />
        {
          {
            pageone: (
              <StepOne
                autoSuggestion={autoSuggestion}
                onChange={onChange}
                handleOnClickSuggestion={handleOnClickSuggestion}
                showCategorySuggestion={showCategorySuggestion}
                product={product}
                setProduct={setProduct}
                l1Data={l1Data}
                l2Data={l2Data}
                l3Data={l3Data}
                getCategory={getCategory}
                getSubCategory={getSubCategory}
                showMakeSuggestion={showMakeSuggestion}
                showModelSuggestion={showModelSuggestion}
                showYearSuggestion={showYearSuggestion}
                setAdd={setAdd}
                setLatLng={setLatLng}
                newLocation={newLocation}
                latLng={latLng}
                isValidItemTitle={isValidItemTitle}
                setValidSubCategory={setValidSubCategory}
                isValidSubCategory={isValidSubCategory}
                isValidMake={isValidMake}
                isValidModel={isValidModel}
                isValidCountry={isValidCountry}
                isValidYear={isValidYear}
                onBlurCaptureHandler={onBlurCaptureHandler}
                onFocusHandler={onFocusHandler}
                suggestionsModel={suggestionsModel}
                tags={tags}
                setCategorySuggestion={setCategorySuggestion}
              />
            ),
            pagetwo: (
              <StepTwo
                imageUrl={imageUrl}
                setImageUrl={setImageUrl}
                onChange={onChange}
                product={product}
              />
            ),
            pagethree: (
              <StepThree
                onChange={onChange}
                product={product}
                isPriceDisable={isPriceDisable}
              />
            ),
          }[page]
        }
        <button
          onClick={nextPage}
          className="btn my-4 ps-2 text-center"
          style={{
            color: "#f88932",
            border: "2px solid #f88932",
            marginRight: "40px",
            height: "40px",
            width: "108px",
            marginLeft: "25px",
            textAlign: "center",
          }}
        >
          {page === "pagethree" ? "Submit" : "Next"}
        </button>
      </div>
    </>
  );
}
