import axios from "axios";

class AuthService {
  login(email, password) {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password,
      })
      .then((response) => {
        if (
          response.data.accessToken &&
          response.data.success &&
          response.data.emailVerified
        ) {
          localStorage.setItem("user", JSON.stringify(response.data));
          var now = new Date().getTime() / 1000;
          localStorage.setItem("setupTimeForLogin", parseInt(now));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("setupTimeForLogin");
  }

  register(body) {
    return axios.post(`${process.env.REACT_APP_API_URL}/vregistration`, body);
  }
  linkedinSignIn(body) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/registerLinkedin`,
      body
    );
  }

  facebookSignIn(body) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/registerFacebook`,
      body
    );
  }
  sendOTP(body) {
    return axios.post(`${process.env.REACT_APP_API_URL}/sendOtp`, body);
  }
  verifyMobileOTP(body) {
    return axios.post(`${process.env.REACT_APP_API_URL}/verifyMobileOTP`, body);
  }
  updateProfile(body) {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/updateProfile`,
      method: "POST",
      data: body,
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    return axios(options);
  }
  resendEmailVerification(email) {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/resendEmailVerification`,
      {
        email,
      }
    );
  }
  forgotPassword(email) {
    return axios.post(`${process.env.REACT_APP_API_URL}/forgotPassword`, {
      email,
    });
  }
  otpVerify(otp, password, email) {
    return axios.post(`${process.env.REACT_APP_API_URL}/verifyOTP`, {
      otp,
      password,
      email,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
