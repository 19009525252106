import { css } from "@emotion/css";

export const profileStyle = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  ${!isMobile && `margin:0 0 0 25px; height: 740px;`};
  position: relative;
  .backButton {
    cursor: pointer;
    margin-right: 16px;
  }
  .ProfileContainer {
    border-radius: 4px;
    ${!isMobile && ` border: solid 1px #e0e1e3;`};
    height: 450px;
    background-color: white;
  }
  .radio-button {
    ${isMobile
      ? `width:336px; margin: 0 auto;`
      : `margin-left:11px;flex-wrap:none;`};
  }
  .UpdateProfileContainer {
    border-radius: 4px;
    ${isMobile
      ? `padding-bottom:100px;
      width: 100%;
     margin: 0 auto;`
      : `border: solid 1px #e0e1e3;`};
    background-color: white;
  }
  .formStyle {
    width: 336px;
    margin: 0 auto;
  }
  .headerContainer {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e0e1e3;
  }
  .header {
    text-align: center;
    font-size: 22px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    margin-bottom: 0;
  }
  .userProfile {
    padding: 24px;
    display: flex;
    flex-direction: column;
  }
  .userFiled {
    display: flex;
    margin-top: 10px;
  }
  .tag {
    width: 40%;
  }

  .updateProfile {
    color: #f88932;
    font-weight: 1000;
    cursor: pointer;
  }
  .header-button {
    // position: fixed;
    // right: 12px;
    // top: 12px;
    display: flex;
    float: right;
    margin-top: -40px;
  }
  .separatorLine-header {
    height: 1px;
    background-color: #e0e1e3;
    margin-bottom: 16px;
  }
  .SubmitButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    padding: 8px;
    width: 100%;
    border-top: 1px solid #e0e1e3;
  }
`;
