import { css } from "@emotion/css";

export const websiteContainer = css`
  width: 993px;
  margin-left: 20px;
  border: 1px solid #e0e1e3;
  border-radius: 4px;
  position: relative;
  margin-bottom: 100px;
  background-color: #ffffff;
  .back-button {
    margin-right: 16px;
    cursor: pointer;
  }
  .headerStyle {
    height: 72px;
    position: relative;
    padding: 24px;
    border-bottom: 1px solid #e0e1e3;
    font-size: 22px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    margin-bottom: 0px;
  }
  .createWebsite {
    background-color: #f88932;
    color: white;
    position: absolute;
    right: 24px;
  }
  .noWebsite {
    height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .noWebsiteInfo {
    width: 270px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .description {
    width: 250px;
    text-align: center;
  }
  .form-container {
    padding: 24px;
  }
  .separator-line {
    height: 1px;
    background-color: #e0e1e3;
    margin: 24px 0px;
  }
  .website-domain {
    margin-bottom: 24px;
    p,
    h5 {
      margin-bottom: 0px;
    }
    display: flex;
    align-items: center;
    .form-control {
      width: 312px;
      margin: 0px 12px;
    }
    .warning-icon {
      width: 13px;
      height: 13px;
      fill: #62656e;
      margin-right: 8px;
    }
  }
  .about-section {
    width: 312px;
    margin-top: 24px;
    p {
      margin-bottom: 0px;
    }
  }
  .next-button {
    width: 100%;
    position: absolute;
    border-top: 1px solid #e0e1e3;
    padding: 16px;
    bottom: 0px;
    background-color: #ffffff;
  }
  .nextButton {
    background-color: #f88932;
    color: white;
    float: right;
    width: 136px;
  }
  .save-button {
    width: 100%;
    border-top: 1px solid #e0e1e3;
    background-color: #ffffff;
  }
  .save-publish {
    margin: 16px;
    background-color: #f88932;
    color: white;
    float: right;
  }
  .manage-website {
    height: fit-content;
  }
  .info-container {
    min-height: 740px;
  }
  .select-wrapper {
    width: 312px;
    height: 48px;
    margin: 4px 0;
    border-radius: 4px;
    background-color: #f4f4f5;
    border-color: #f4f4f5;
  }
  .select-theme {
    padding: 24px;
    display: flex;
    align-items: center;
  }
  .select-item {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
  }
  .check-box {
    display: flex;
    align-items: center;
    margin-top: 24px;
    h5 {
      margin-bottom: 0px;
    }
  }
  .image-box {
    border-radius: 4.8px;
    box-shadow: 0 0 8px 0 rgba(55, 70, 95, 0.36);
  }
  .location-input {
    width: 648px;
  }
`;
export const webSiteTables = css`
  margin-bottom: 20px;
  table {
    width: 100%;
  }
  th {
    background-color: #fbfbfb;
    text-align: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e0e1e3;
  }
  td {
    border-bottom: 1px solid #e0e1e3;
    text-align: center;
    padding: 8px 24px;
  }
  .item-image {
    border-radius: 4px;
  }
`;
