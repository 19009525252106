import { css } from "@emotion/css";

export const pagination = (isMobile) => css`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
  ul {
    padding-left: 0px;
  }
  ul li {
    list-style: none;
  }
  .rc-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
  }

  .rc-pagination-item {
    height: ${isMobile ? "16px" : "20px"};
    width: ${isMobile ? "16px" : "20px"};
    min-width: ${isMobile ? "12px" : "16px"};
    margin-right: ${isMobile ? "4px" : "8px"};
    border: none;
    display: flex;
    align-items: center;
  }

  .rc-pagination-item a {
    color: #666;
    height: ${isMobile ? "16px" : "20px"};
    width: ${isMobile ? "16px" : "20px"};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.43;
    opacity: 0.7;
  }

  .rc-pagination-item-active {
    background-color: #f88932;
    border-radius: 4px;
    color: white;
  }

  .rc-pagination-prev {
    margin-right: ${isMobile ? "4px" : "24px"};
  }
  .rc-pagination-next {
    margin-right: ${isMobile ? "4px" : "24px"};
  }

  .rc-pagination-next[aria-disabled="false"] button,
  .rc-pagination-prev[aria-disabled="false"] button {
    color: #f88932;
  }
  .rc-pagination-prev[aria-disabled="true"] button,
  .rc-pagination-next[aria-disabled="true"] button {
    cursor: no-drop;
  }

  .rc-pagination-item a:hover {
    color: #f88932;
  }

  .rc-pagination-item-active a:hover {
    color: white;
  }

  .rc-pagination-item-active a {
    color: white;
    opacity: 1;
  }

  .rc-pagination-prev button:after,
  .rc-pagination-next button:after {
    content: "";
  }
  .rc-pagination-item-link {
    background: 0 0;
    border: none;
    cursor: pointer;
    color: #666;
  }
  .rc-pagination-item-link:after {
    content: "•••";
  }

  .direction-button {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 800;
    color: #666;
  }

  .previous-icon {
    margin-right: 8px;
  }
  .next-icon {
    margin-left: 8px;
  }

  .page-state {
    position: absolute;
    left: 0;
  }
  .button {
    color: #f88932;
    border: none;
  }
`;
