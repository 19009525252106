import {Form} from "react-bootstrap";
import {filterListStyle} from "./style";
import {useIsMobile} from "../../components/context/IsMobile";
import CloseIcon from "@mui/icons-material/Close";
import {useState} from "react";

export default function FilterList({
  placeholder,
  name,
  id,
  value,
  onChange,
  setValue,
  data,
  onCategoryChange,
  setValidSubCategory,
  isSelected,
  isValidSubCategory,
}) {
  const isMobile = useIsMobile();
  const [showCancel, setShowCancel] = useState(true);
  const handleClear = () => {
    setShowCancel(false);
    setValue((prevValues) => ({
      ...prevValues,
      main_category: "",
      main_category_id: "",
      category: "",
      category_id: "",
      sub_category_id: "",
      sub_category: "",
    }));
    setValidSubCategory(false);
  };
  return (
    <div className={filterListStyle(isMobile)}>
      <Form.Control
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        showCancel={showCancel}
      />
      <CloseIcon
        style={{
          float: "right",
          marginTop: "-35px",
          marginRight: "3px",
          color: "gray",
        }}
        onClick={handleClear}
      />
      {!isValidSubCategory && data && (
        <div className="list">
          {data
            .filter((item) => {
              if (isSelected) {
                return false;
              } else if (!value) return true;
              else if (
                item?.name?.toLowerCase().includes(value) ||
                item?.name?.toUpperCase().includes(value)
              ) {
                return true;
              } else {
                return false;
              }
            })
            .map((item) => (
              <li
                key={item.category_key}
                onClick={() => {
                  setValue((prevState) => ({
                    ...prevState,
                    [name]: item.name,
                    [id]: item.category_key,
                  }));
                  onCategoryChange(item.category_key);
                  if (name === "sub_category") {
                    setValidSubCategory(true);
                  }
                }}
              >
                {item.name}
              </li>
            ))}
        </div>
      )}
    </div>
  );
}
