import { css } from "@emotion/css";

export const listingContainer = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  ${isMobile
    ? `
    margin: 0 auto;
     padding-bottom: 100px;
     border-top: 1px solid #e0e1e3;`
    : ` margin-left: 20px;
        border: 1px solid #e0e1e3;
        border-radius: 4px;
        position: relative;
        margin-bottom: 100px;
        height: 740px;`};
  .listingdiv {
    display: flex;
    border-bottom: 1px solid #e0e1e3;
  }
  background-color: #ffffff;
  .ListingHeader {
    padding: ${isMobile ? "12px" : "24px"};
    font-size: ${isMobile ? "16px" : "22px"};
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    margin-bottom: 0px;
  }
  .uploadListing {
    width: 80%;
    ${isMobile
      ? ` position:sticky; box-shadow: 0 1px 4px 0 rgba(55, 70, 95, 0.12);  padding: 8px;`
      : `border-top: 1px solid #e0e1e3; padding: 16px;`};
    bottom: 0px;
    padding: 16px;
    background-color: #ffffff;
  }
  .uploadButton {
    background-color: #f88932;
    color: white;
    ${isMobile ? `width:100%;` : `float: right;`};
  }

  .NoListing {
    padding: 16px;
    height: 500px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .description {
    width: ${isMobile ? "336px" : "550px"};
  }
`;
export const plansTableStyle = css`
  margin-bottom: 20px;
  table {
    width: 100%;
  }
  th {
    background-color: #fbfbfb;
    text-align: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e0e1e3;
  }
  td {
    border-bottom: 1px solid #e0e1e3;
    text-align: center;
    padding: 8px 24px;
  }
  .item-image {
    border-radius: 4px;
  }
`;

export const MobileListingCard = css`
  width: 336px;
  height: auto;
  margin: 16px 12px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #e0e1e3;
  background-color: white;
  .first-section {
    padding-bottom: 8px;
    display: flex;
    border-bottom: solid 1px #e0e1e3;
  }
  .card-title {
    margin-bottom: 0px;
    margin-left: 10px;
    text-align: center;
    font-size: 16px;
  }
  .card-price {
    font-size: 15px;
    color: #f88932;
    font-weight: 600;
    text-align: center;
  }
  .card-location {
    font-size: 15px;
    text-align: center;
    color: gray;
    font-weight: 400;
  }
  .second-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
  }
  .action-button {
    display: flex;
    align-items: center;
  }
  .item-image {
    border-radius: 4px;
    width: 100%;
    height: 195px;
  }
  .mid-section {
    border-bottom: solid 1px #e0e1e3;
  }
`;
