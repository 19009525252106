import { css } from "@emotion/css";

export const overlay = css`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
`;
export const pendingInfoStyle = (isMobile) => css`
  margin: 70px auto;
  padding: ${isMobile ? "12px" : "20px"};
  background: #fff;
  border-radius: 5px;
  width: ${isMobile ? "100%" : "30%"};
  height: 80%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 5s ease-in-out;
  .pendingInfoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .closeThick {
    cursor: pointer;
  }
  .popup content::-webkit-scrollbar {
    display: none;
  }
  .popup content {
    box-sizing: border-box;
  }
  .popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
  }
`;
