import React from "react";
import { css } from "@emotion/css";
import { useHistory } from "react-router-dom";
import { imageUrl } from "../../utils/index";

const loader = css`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1;
`;

function Loader() {
  const history = useHistory();
  if (
    !localStorage.getItem("user") ||
    JSON.parse(localStorage.getItem("user"))?.pendingInfo
  ) {
    history.push("/");
    return null;
  }
  return (
    <div className={loader}>
      <img
        src={imageUrl("/images/loader.gif")}
        height={200}
        width={200}
        alt="loader"
      />
    </div>
  );
}

export default Loader;
