import {TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {useIsMobile} from "../../components/context/IsMobile";
import {getKyc, updateKyc} from "../Authentication/Vregistration";
import {billingStyle} from "./style";

const Billing = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const [requiredFields, setRequiredFields] = useState([]);
  const [billData, setBillData] = useState({
    vendorType: JSON.parse(localStorage.getItem("user"))?.vendorType,
    companyName: JSON.parse(localStorage.getItem("user"))?.companyName,
    address: "",
    industry: "",
    panNumber: "",
    GST: "",
  });

  useEffect(() => {
    const getRequiredFields = async () => {
      const data = await getKyc();
      console.log(data);
      setRequiredFields(data?.requiredFields);
    };
    getRequiredFields();
  }, []);

  const handleUpdatekyc = () => {
    updateKyc(billData).then((res) => {
      if (res.success) {
        history.push("/companyInfo");
      }
      if (res?.data?.success) {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  return (
    <div className={billingStyle(isMobile)}>
      <Typography className="bill-text">Billing & GST Details</Typography>
      <div>
        <div className="bill-div">
          {requiredFields?.includes("industry") && (
            <TextField
              label="Industry"
              variant="outlined"
              className="inputs-div"
              value={billData.industry}
              onChange={(e) => {
                setBillData({...billData, industry: e.target.value});
              }}
            />
          )}
          {requiredFields?.includes("address") && (
            <TextField
              label="Billing Address"
              variant="outlined"
              className="inputs-div"
              value={billData.address}
              onChange={(e) =>
                setBillData({...billData, address: e.target.value})
              }
            />
          )}
        </div>

        <div className="bill-div">
          {requiredFields?.includes("GST") && (
            <TextField
              label="GSTIN"
              variant="outlined"
              className="inputs-div"
              value={billData.GST}
              onChange={(e) => setBillData({...billData, GST: e.target.value})}
            />
          )}
          {requiredFields?.includes("panNumber") && (
            <TextField
              label="PAN"
              variant="outlined"
              className="inputs-div"
              value={billData.panNumber}
              onChange={(e) =>
                setBillData({...billData, panNumber: e.target.value})
              }
            />
          )}
        </div>
      </div>
      <div className="button-div">
        <Link style={{textDecoration: "none"}} to={"/companyInfo"}>
          <button className="skip-button">Skip For Now</button>
        </Link>

        <button className="next-button" onClick={handleUpdatekyc}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Billing;
