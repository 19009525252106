import React, {useState, useEffect} from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {Row, Col, Form} from "react-bootstrap";

const LocationSearchInput = (props) => {
  const [address, setAddress] = useState(props.newLocation);
  const [latLng, setLatLng] = useState(props.latLng);
  props.setAdd(address);
  props.setLatLng(latLng);
  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLatLng((prevState) => ({
          ...prevState,
          latLng,
        }));
      })
      .catch((error) => console.error("Error", error));
  };
  useEffect(() => {
    setAddress(props.newLocation);
  }, [props.newLocation]);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      searchOptions={{
        types: ["(cities)"],
      }}
      onSelect={handleSelect}
    >
      {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
        <div>
          <Form.Group as={Row} className="mb-1" controlId="formPlanetextName">
            <Col sm="6">
              <Form.Label>
                <strong>Location</strong>
              </Form.Label>
              <Form.Control
                name="location"
                type="text"
                // required
                value={address}
                {...getInputProps({
                  placeholder: "Search Places ...",
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  const style = suggestion.active
                    ? {backgroundColor: "#fafafa", cursor: "pointer"}
                    : {backgroundColor: "#ffffff", cursor: "pointer"};
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Form.Group>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
