import axios from "axios";
import {getStorage} from "../../utils/localStorage";

export const getProductDetailsById = async (pId) => {
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_API_URL}/dashboard/products/${pId}`,
    headers: {
      "content-type": "application/json",
      "x-access-token": `${getStorage("user")?.accessToken}`,
    },
  };
  let productInfo = await axios(options);
  return productInfo.data;
};

export const updateProduct = async (updateProductId, input) => {
  const options = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/dashboard/products/update/${updateProductId}`,
    data: input,
    headers: {
      "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
    },
  };
  const response = await axios(options);
  return response?.data;
};

export const createProduct = async (input) => {
  const options = {
    method: "post",
    url: `${process.env.REACT_APP_API_URL}/dashboard/products/create`,
    data: input,
    headers: {
      "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
    },
  };
  const response = await axios(options);
  return response?.data;
};
