import React from "react";
import Form from "react-bootstrap/Form";
import NewForm from "../NewForm";
import Images from "../Images";
import {getStorage} from "../../../utils/localStorage";

const StepTwo = ({setImageUrl, imageUrl, product, onChange}) => {
  const user = getStorage("user");
  console.log(imageUrl);
  return (
    <div>
      <Form className="formStyle">
        <Images
          setImageUrl={setImageUrl}
          imageUrl={imageUrl}
          pathName={`${user?.companyName}/item`}
        />
        <NewForm
          inputKey="Description of item"
          name="description"
          check="textarea"
          value={product?.description}
          inputsHandler={onChange("description")}
        />
      </Form>
    </div>
  );
};

export default StepTwo;
