import axios from "axios";
export const apiEndpoint = ` ${process.env.REACT_APP_API_URL}`;

export const vRegistration = async (body) => {
  const response = await axios.post(`${apiEndpoint}/vregistration`, body);

  return response.data;
};
export const getKyc = async () => {
  const tokenCookies = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const response = await axios.get(`${apiEndpoint}/dashboard/getKYC`, {
    headers: {
      ...(tokenCookies && {"x-access-token": tokenCookies}),
    },
  });
  return response.data;
};
export const updateKyc = async (body) => {
  const tokenCookies = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const response = await axios.post(
    `${apiEndpoint}/dashboard/updateKYC`,
    body,
    {
      headers: {
        ...(tokenCookies && {"x-access-token": tokenCookies}),
      },
    }
  );
  return response.data;
};

export const companyInfo = async (body) => {
  const tokenCookies = JSON.parse(localStorage.getItem("user"))?.accessToken;
  const response = await axios.post(
    `${apiEndpoint}/dashboard/updateVendorCompanyDetail`,
    body,
    {
      headers: {
        ...(tokenCookies && {"x-access-token": tokenCookies}),
      },
    }
  );
  return response.data;
};
