import { css } from "@emotion/css";

export const HomePageStyle = (isMobile) => css`
  position: relative;
  background: #ff830012;
  border-top-left-radius: 400%;
  border-top-right-radius: 50%;
  .heroBanner {
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 16px;
    left: 0;
    width: 80%;
    right: 0;
    ${isMobile
      ? `display: contents;`
      : `display: flex;
    align-items: center;
    justify-content: space-between;`};
    flex-direction: ${isMobile ? "column" : "row"};
  }
  .heroBannerInfo {
    width: ${isMobile ? "95%" : "40%"};
    ${isMobile &&
    `margin-top: -200px;
    margin-bottom: 60px;
    margin-left: 12px;
    `};
  }
  .backGroundImage {
    width: 100%;
    height: ${isMobile ? "300px" : "800px"};
    // margin-top: ${isMobile ? "100px" : ""};
  }
  .SeparatorLine {
    width: 335px;
    height: 8px;
    background-color: #f4f4f5;
    margin-top: 45px;
    margin-left: 30px;
  }
  .card-div {
    display: ${isMobile ? "grid" : "flex"};
    margin: ${isMobile ? "10px" : ""};
    padding: ${isMobile ? "5px" : "10px 145px"};
  }
  .card-ele {
    margin: ${isMobile ? "10px" : "20px"};
    padding: ${isMobile ? "10px" : "20px"};
    width: ${isMobile ? "" : "40%"};
  }
  .card-typo {
    color: #f88932;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  .card-typotext {
    font-size: 13px;
    font-style: italic;
    color: #808080;
  }
  .icon-img {
    height: 50px;
    margin-left: ${isMobile ? "100px" : "93px"};
  }
  .view-img {
    height: 40px;
    margin-left: ${isMobile ? "140px" : "130px"};
    margin-bottom: 10px;
  }
  .regis-icon {
    height: 40px;
    margin-left: ${isMobile ? "135px" : "125px"};
  }
  .four-cards {
    display: flex;
    margin-left: 75px;
    margin-right: 25px;
    padding: 10px;
  }
  .three-cards {
    display: flex;
    margin-left: 260px;
    margin-right: 25px;
    padding: 10px;
  }
`;

export const howItWorks = (isMobile) => css`
  margin-top: ${isMobile ? "24px" : "48px"};
  width: ${isMobile ? "100%" : "50%"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Steps {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${isMobile ? "24px" : "48px"};
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: ${isMobile ? "column" : "row"};
  }
  .Step {
    width: ${isMobile ? "100%" : "240px"};
    display: flex;
    flex-direction: ${isMobile ? "row" : "column"};
    align-items: ${isMobile ? "center" : "center"};
    img {
      width: ${isMobile ? "115px" : "240px"};
      height: ${isMobile ? "115px" : "240px"};
      object-fit: contain;
      flex: ${isMobile && 0.3};
    }
    div {
      display: flex;
      flex-direction: column;
      flex: ${isMobile && 0.7};
    }
    .span-1 {
      text-align: center;
      font-size: ${isMobile ? "18px" : "22px"};
    }
    .span-2 {
      text-align: center;
      font-size: ${isMobile ? "14px" : "18px"};
    }
  }
`;

export const vendorContainer = (isMobile) => css`
  margin-top: ${isMobile ? "32px" : "64px"};
  // background-color: #f8f9fa;
  padding: ${isMobile ? "24px 0px" : "48px 0px"};
  width: 100%;
  .vendorList {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: ${isMobile ? "90%" : "80%"};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .vendorItem {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-color: white;
    border-radius: 6px;
    width: ${isMobile ? "162px" : "288px"};
    height: ${isMobile ? "98px" : "157px"};
    border: 1px solid #e0e1e3;
  }
  .SeeMoreButton {
    padding: 8px;
    width: ${isMobile ? "95%" : "138px"};
    color: #f88932;
    border-radius: 4px;
    border: 2px solid #f88932;
    margin-top: ${isMobile ? "24px" : "48px"};
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .name-container {
    font-size: ${isMobile ? "12px" : "16px"};
    padding: ${isMobile ? "8px" : "12px"};
    text-align: center;
    border-top: 1px solid #e0e1e3;
  }
  .vendorImage {
    margin: ${isMobile ? "4px" : "8px"};
    width: ${isMobile ? "154px" : "272px"};
    height: ${isMobile ? "57px" : "96px"};
    object-fit: cover;
  }
`;
export const gridStyles = css`
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 288px);
  grid-template-rows: repeat(auto-fit, 157px);
  grid-gap: 32px 40px;
`;

export const gridMobileStyles = css`
  margin-left: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, 98px);
  grid-gap: 12px;
`;

export const faqWrapper = (isMobile) => css`
  width: 100%;
  margin: 0 auto;
  margin-top: ${isMobile ? "32px" : "64px"};
  margin-bottom: ${isMobile ? "32px" : "64px"};
  .faqs {
    width: ${isMobile ? "100%" : "70%"};
    margin-left: auto;
    margin-right: auto;
  }
  .question-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding-top: 12px;
    padding-left: 12px;
  }
  .faq-wrapper {
    margin-bottom: 16px;
    padding-right: 12px;
  }
  .question {
    font-weight: 500;
    font-size: ${isMobile ? "18px" : "20px"};
  }
  .answer {
    padding-left: 12px;
    opacity: 0.7;
    font-size: ${isMobile ? "16px" : "18px"};
  }
`;
export const howToSellStyle = (isMobile) => css`
  margin: ${isMobile ? "30px 10px 30px 25px" : ""};
  padding: ${isMobile ? "5px" : ""};
  .sell-h1 {
    margin: ${isMobile ? "10px" : ""};
    padding: ${isMobile ? "10px" : ""};
  }
  .sell-heading {
    font-size: ${isMobile ? "15px" : ""};
    font-weight: ${isMobile ? "600" : ""};
    font-style: ${isMobile ? "normal" : ""};
    text-align: center;
  }
  .sell-icon {
    display: block;
    align-items: center;
    width: ${isMobile ? "20%" : "80"};
    height: ${isMobile ? "10%" : "80px"};
    margin-top: 10px;
    margin-left: ${isMobile ? "125px" : "85px"};
  }
  .sell-icons {
    display: block;
    align-items: center;
    width: ${isMobile ? "20%" : "80"};
    height: ${isMobile ? "10%" : "80px"};
    margin-top: 10px;
    margin-left: ${isMobile ? "125px" : "105px"};
  }
  .sell-h2 {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #f88932;
  }
  .sell-h3 {
    text-align: center;
    font-size: 16px;
    padding: 5px;
  }
  .slick-dots li button:before {
    font-size: 18px;
  }
  .slick-dots li.slick-active button:before {
    color: #f88932;
  }
  .cards {
    background: white;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    border: 1px solid gray;
    width: ${isMobile ? "" : "300px"};
    transition: width 2s, height 2s, transform 2s;
  }
  // .cards:hover {
  //   width: 300px;
  //   height: 300px;
  //   transform: rotate(180deg);}
`;
export const posterStyle = (isMobile) => css`
  background: #0a58ca14;
  margin-top: ${isMobile ? "" : "75px"};
  width: ${isMobile ? "" : "50%"};
  height: ${isMobile ? "" : "200px"};
  padding: ${isMobile ? "10px" : "20px"};
  margin: ${isMobile ? "25px" : ""};
  margin-left: ${isMobile ? "" : "27%"};
  .typo-div {
    font-size: 16px;
    font-weight: 600;
    text-align: ${isMobile ? "" : "center"};
  }
  .typo-text {
    margin: 10px;
    font-size: 15px;
    text-align: ${isMobile ? "" : "center"};
  }
  .sells-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 50px;
    color: white;
    background: #f88932;
    border-color: #f88932;
    box-shadow: rgb(223 225 229) 0px 0px 4px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    border-radius: 25px;
    margin-left: ${isMobile ? "70px" : "40%"};
    margin-top: ${isMobile ? "" : "25px"};
    margin-bottom: ${isMobile ? "" : "20px"};
  }
`;
export const bannerStyle = (isMobile) => css`
  display: ${isMobile ? "grid" : "flex"};
  justify-content: ${isMobile ? "" : "space-around"};
  margin-top: ${isMobile ? "50px" : "55px"};
  margin-left: ${isMobile ? "25px" : ""};
  .banner-img {
    width: ${isMobile ? "100%" : "500px"};
    height: ${isMobile ? "100%" : "325px"};
    margin-top: ${isMobile ? "10px" : ""};
    margin-left: ${isMobile ? "0px" : ""};
    border-radius: ${isMobile ? "10px" : ""};
  }
  .p-text {
    font-size: ${isMobile ? "14px" : "16px"};
    text-align: ${isMobile ? "inherit" : "center"};
  }
  .sell-button {
    background-color: #f88932;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border: none;
    border-radius: 25px;
    width: 140px;
    height: 55px;
    margin-left: ${isMobile ? "100px" : "250px"};
  }
  .offer-button {
    border: none;
    border-radius: 5px;
    background-color: #f88932;
    color: white;
    width: 300px;
    font-size: 20px;
    font-weight: 500;
  }
`;
export const sliderdiv = (isMobile) => css`
  width: ${isMobile ? "95%" : ""};
`;
export const typoStyle = (isMobile) => css`
  margin: ${isMobile ? "10px" : ""};
  padding: ${isMobile ? "10px" : "20px"};
  font-size: ${isMobile ? "20px" : "30px"};
  font-style: italic;
  font-weight: bold;
  border-bottom: 1px solid gray;
  text-align: center;
  margin-top: ${isMobile ? "" : "25px"};
  margin-left: ${isMobile ? "" : ""};
`;
export const tableContent = (isMobile) => css`
  width: 90%;
  margin: auto;
  .content-title {
    text-align: center;
    font-weight: 600;
  }
  .content-div {
    width: ${isMobile ? "" : "60%"};
    margin: auto;
  }
  .table-div {
    margin-top: ${isMobile ? "20px" : "15px"};
  }
  .text-div {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 500;
  }
  #about {
  }
  table,
  th {
    text-align: center;
    border: 1px solid black;
    border-collapse: collapse;
  }
  ,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  .table-block {
    width: ${isMobile ? "100%" : "60%"};
    margin: auto;
  }
  .table-header {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
`;
