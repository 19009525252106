import { Card, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import Footer from "../../components/Footer/Footer";
import FAQ from "../home/Faq";
import Header from "../home/Header/Header";
import VideoPage from "../home/VideoPage";
import { midCardStyle, MidPageStyle, sitePosterStyle } from "./style";

const MidPage = () => {
  const isMobile = useIsMobile();

  const howToSell = [
    {
      icon: (
        <img
          src="https://www.freeiconspng.com/thumbs/seller-icon/seller-icon-png-0.png"
          alt="image1"
          className="mid-sell-icons"
        />
      ),
      title: "Create An Seller Account for free",
      description:
        "Create an seller account for free all you need is Email address,Mobile Number, Pan & GST Detail for setup.",
    },
    {
      icon: (
        <img
          src="https://www.freeiconspng.com/thumbs/upload-documents-icon/orange-square-button-document-file-page-up-upload-icon-5.jpeg"
          alt="imge2"
          className="mid-sell-icons"
        />
      ),
      title: " Upload Items, Create Page & publish",
      description:
        " Put up all the equipment, machinery and commercial vehicles you want to sell along with images, description and publish it.",
    },
    {
      icon: (
        <img
          src="https://user-images.githubusercontent.com/44617192/48874969-6e1e0900-edf6-11e8-98a4-9812e9a99b60.png"
          alt="imge3"
          className="mid-sell-icons"
        />
      ),
      title: " Start getting leads on item",
      description:
        " Once you have uploaded the equipment & machinery to sell you will start getting inquiries on it from buyers converse with them & sell your item.",
    },
  ];
  const whyIronlist = [
    {
      icon: (
        <img
          src="https://icons.iconarchive.com/icons/custom-icon-design/pretty-office-11/512/sale-icon.png"
          alt="img1"
          className="mid-sell-icon"
        />
      ),
      title: " Sell to genuine interested buyers",
      description:
        "  Sign up and sell your items to right buyers across the global.",
    },
    {
      icon: (
        <img
          src="https://cdn-icons-png.flaticon.com/512/3628/3628260.png"
          alt="img2"
          className="mid-sell-icon"
        />
      ),
      title: " Low Cost of doing business",
      description:
        " Get the best deal price in the market with Ironlist expert selling & marketing assistance .",
    },
    {
      icon: (
        <img
          src="https://cdn-icons-png.flaticon.com/512/1998/1998557.png"
          alt="img3"
          className="mid-sell-icon"
        />
      ),
      title: "Ease & Transparency",
      description:
        "From product info to promotional banners, leads management,Ironlist has a solution for you.",
    },
    {
      icon: (
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/staff-management-5706036-4755478.png"
          alt="img4"
          className="mid-sell-icon"
        />
      ),
      title: "Manage Business one go",
      description:
        "With the Ironlist web app you can manage your business, solve issues, and respond to customers – anywhere, anytime.",
    },
  ];
  return (
    <>
      <Header />
      <div className={MidPageStyle(isMobile)}>
        <div className="mid-card-div">
          <Card className="mid-card-ele">
            <div>
              <img
                className="mid-icon-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRlZ6v1Bdgi6oQYmidIAqL4Xe6CYeA_Ka9TA&usqp=CAU"
                alt="buyer"
              />
              <Typography className="mid-card-typo" style={{ fontWeight: 600 }}>
                25000 + Buyers
              </Typography>
            </div>
            <Typography className="mid-card-typotext">
              Over 25000+ buyers visit ironlist.com & search for heavy
              equipment, show them your machinery now.
            </Typography>
          </Card>
          <Card className="mid-card-ele">
            <div>
              <img
                className="mid-view-img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0bVBuveiEpBVPgBMBkLBG1MJ_coPcvS9JYEta6HH3J3adE3PHGs_BNuGpbvczNX1qmLI&usqp=CAU"
                alt="views"
              />
              <Typography className="mid-card-typo">5000+ Views</Typography>
            </div>
            <Typography className="mid-card-typotext">
              More than 5000 items are viewed every day get your machinery
              visible today & sell your used equipment.
            </Typography>
          </Card>
          <Card className="mid-card-ele">
            <div>
              <img
                className="mid-regis-icon"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT39nxSwTVNuzaCLUDHubHp4txvlPWJR8_vcA&usqp=CAU"
                alt="registration"
              />
              <Typography className="mid-card-typo">
                0 Registration Charges
              </Typography>
            </div>
            <Typography className="mid-card-typotext">
              Register for free to get 30 free trial of our platform & start
              selling your Used heavy Equipment like Loader Backhoes, tractors,
              trucks & many more .
            </Typography>
          </Card>
        </div>
      </div>
      <div className={midCardStyle(isMobile)}>
        <p className="heading-p">
          How To Sell Used Heavy Equipment & Machinery Online On IronList
        </p>
        <div className="mid-three-cards">
          {howToSell.map((x) => (
            <div className="mid-cards">
              {x.icon}
              <Typography className="mid-sell-h2">{x.title}</Typography>
              <Typography className="mid-sell-h3">{x.description}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={midCardStyle(isMobile)}>
        <p className="heading-p">
          Why To Sell Used Heavy Equipment & Machinery Online On IronList
        </p>
        <div className="mid-four-cards">
          {whyIronlist.map((x) => (
            <div className="mid-cards">
              {x.icon}
              <Typography className="mid-sell-h2">{x.title}</Typography>
              <Typography className="mid-sell-h3">{x.description}</Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={sitePosterStyle(isMobile)}>
        <Typography className="site-typo-div">
          Start Selling Your Equipment & Machines
        </Typography>
        <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
          <button className="site-sells-button">Start Selling</button>
        </Link>
        <Typography className="site-typo-text">
          Whether you are a brand owner, a reseller,are new to selling, or have
          been doing it for years-ironlist.com has a wid range of options to
          help you find your customer and grow your business.
        </Typography>
      </div>
      <VideoPage />
      <FAQ />
      <Footer />
    </>
  );
};

export default MidPage;
