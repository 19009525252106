/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { imageUrl } from "../../utils";
import { footerStyle } from "./style";
import { useIsMobile } from "../context/IsMobile";
import { Link } from "react-router-dom";

export default function Footer() {
  const isMobile = useIsMobile();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={footerStyle(isMobile)}>
      <div className="main-icon">
        <div className="footer-icons">
          <img
            src={imageUrl("/images/ironlist-logo.png")}
            alt="ironlist-logo"
            className="footerLogo"
          />
        </div>

        {isMobile ? (
          ""
        ) : (
          <div className="mid-footer">
            <div className="link-div">
              <Link to={"/about"} className="sell-on">
                About Us
              </Link>
              <Link to={""} className="sell-on">
                Contact Us
              </Link>
              <Link to={"/register"} className="sell-on">
                SignUp
              </Link>
              <Link to={"/login"} className="sell-on">
                SignIn
              </Link>
              {/* <h5 className="terms">Terms Of Use & Privacy Policy</h5> */}
            </div>
            <div>
              <h5 className="sell-on">Sell Machines on IronList</h5>
              <div className="sitemap-div">
                <Link to={"/sell-excavator-online"} className="sitemap-link">
                  Sell Excavator Online Ironlist
                </Link>
                <Link to={"/sell-compactor-online"} className="sitemap-link">
                  Sell Compactor Online Ironlist
                </Link>
                <Link to={"/sell-dozer-online"} className="sitemap-link">
                  Sell Dozer Online Ironlist
                </Link>
                <Link to={"/sell-dumpTruck-online"} className="sitemap-link">
                  Sell Dump Truck Online Ironlist
                </Link>
                <Link
                  to={"/sell-loaderBackhoe-online"}
                  className="sitemap-link"
                >
                  Sell Loader Backhoe Online Ironlist
                </Link>
                <Link to={"/sell-tractor-online"} className="sitemap-link">
                  Sell Tractor Online Ironlist
                </Link>
                <Link to={"/sell-truck-online"} className="sitemap-link">
                  Sell Truck Online Ironlist
                </Link>
              </div>
            </div>
          </div>
        )}
        <div>
          <h4 className="down">Download our App</h4>
          <div className="play-store">
            <img
              className="android-app"
              src="https://static-assets-web.flixcart.com/fk-sp-static/images/apple_store.png"
              alt=""
            />
            <img
              className="app-div"
              src="https://static-assets-web.flixcart.com/fk-sp-static/images/play_store.png"
              alt=""
            />
          </div>
          <div className="icon-div">
            <img
              className="linked-icon"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/800px-LinkedIn_logo_initials.png"
              alt="linked"
            />
            <img
              className="facebook-icon"
              src="https://www.freepnglogos.com/uploads/facebook-logo-13.png"
              alt="facebook"
            />
            <img
              className="insta-icon"
              src="https://logos-world.net/wp-content/uploads/2020/04/Instagram-icon-Logo-2016-present.png"
              alt="insta"
            />
            <img
              className="twitter-icon"
              src="https://cdn-icons-png.flaticon.com/512/124/124021.png"
              alt="twiter"
            />
          </div>
        </div>
      </div>
      {isMobile ? (
        <div className="mid-footer">
          <div className="link-div">
            <Link to={"/about"} className="sell-on">
              About Us
            </Link>
            <Link to={""} className="sell-on">
              Contact Us
            </Link>
            <Link to={"/register"} className="sell-on">
              SignUp
            </Link>
            <Link to={"/login"} className="sell-on">
              SignIn
            </Link>
            {/* <h5 className="terms">Terms Of Use & Privacy Policy</h5> */}
          </div>
          <div>
            <h5 className="sell-on">Sell Machines on IronList</h5>
            <div className="sitemap-div">
              <Link to={"/sell-excavator-online"} className="sitemap-link">
                Sell Excavator Online Ironlist
              </Link>
              <Link to={"/sell-compactor-online"} className="sitemap-link">
                Sell Compactor Online Ironlist
              </Link>
              <Link to={"/sell-dozer-online"} className="sitemap-link">
                Sell Dozer Online Ironlist
              </Link>
              <Link to={"/sell-dumpTruck-online"} className="sitemap-link">
                Sell Dump Truck Online Ironlist
              </Link>
              <Link to={"/sell-loaderBackhoe-online"} className="sitemap-link">
                Sell Loader Backhoe Online Ironlist
              </Link>
              <Link to={"/sell-tractor-online"} className="sitemap-link">
                Sell Tractor Online Ironlist
              </Link>
              <Link to={"/sell-truck-online"} className="sitemap-link">
                Sell Truck Online Ironlist
              </Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="icon-footer">
        <h5 className="copyRight">
          Copyright 2023 Ironlist. All right reserved
        </h5>
      </div>
    </div>
  );
}
