import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import { companyInfo } from "../Authentication/Vregistration";
import { companyInfoStyle } from "./style";

const CompanyInfo = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const [website, setWebsite] = useState("");
  const [facebookPageUrl, setFacebookPageUrl] = useState("");
  const [linkedinPageUrl, setLinkedinPageUrl] = useState("");

  const handleSubmitInfo = () => {
    companyInfo({
      website: website,
      facebook_page_url: facebookPageUrl,
      linkedin_page_url: linkedinPageUrl,
    }).then((response) => {
      if (response.success) {
        history.push("/dashboard");
      }
    });
  };

  return (
    <div className={companyInfoStyle(isMobile)}>
      <Typography className="company-text">Company Information</Typography>
      <div>
        <div className="web-div">
          <TextField
            label="Website"
            variant="outlined"
            className="website-div"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>
        <div className="url-div">
          <TextField
            label="Facebook Page Url"
            variant="outlined"
            className="facebook-url"
            value={facebookPageUrl}
            onChange={(e) => setFacebookPageUrl(e.target.value)}
          />
          <TextField
            label="LinkedIns Page Url"
            variant="outlined"
            className="linkedin-url"
            value={linkedinPageUrl}
            onChange={(e) => setLinkedinPageUrl(e.target.value)}
          />
        </div>
      </div>
      <div className="company-button-div">
        <Link
          style={{ textDecoration: "none" }}
          to={"/dashboard"}
          className="company-skip-button"
        >
          Skip For Now
        </Link>

        <button className="company-next-button" onClick={handleSubmitInfo}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default CompanyInfo;
