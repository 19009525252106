import { css } from "@emotion/css";

export const footerContainer = (isMobile) => css`
  background-color: #;f6f6f6
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  flex-wrap: ${isMobile ? "" : "wrap"};
  position: ${isMobile ? "" : "relative"};
  ${isMobile && `flex-direction: column;`};
  padding-top: ${isMobile ? "" : "1.5rem"};
  padding-left: ${isMobile ? "" : "75px"};
  padding-right: ${isMobile ? "" : "75px"};

  .footer-top {
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${isMobile ? "0px 12px" : ""};
    justify-content: space-between;
    ${isMobile ? ` border-bottom: 1px solid #979797 ` : ""};
  }
  .footer-top svg {
    margin-left: ${isMobile ? "16px" : "32px"};
  }

  .footerLinkStyle {
    display: flex;
    flex-basis: 10%;
    ${
      isMobile
        ? ` 
    flex-wrap: wrap;
    flex-direction: column;
    height: 140px;
    padding: 16px;`
        : `
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0px 16px 16px 16px;`
    };
  }

  .footer-link:hover {
    color: #f88932;
    opacity: 1;
    cursor: pointer;
  }
  .footer-link {
    text-decoration: none;
    margin-right: 40px;
    font-size: ${isMobile ? "16px" : "18px"};
    color: #1f2330;
    opacity: 0.8;
  }
  .copyRight {
    ${
      !isMobile
        ? ` position: absolute;
        right: 70px;
        bottom: 16px;
        font-size: 18px;`
        : `border-top: 1px solid #979797;
      display: flex; 
      align-items:center; 
      justify-content: center;   
      font-size: 14px;
      padding: 12px 0px;`
    };
    color: #1f2330;
    opacity: 0.8;
  }
`;
export const footerStyle = (isMobile) => css`
  .main-icon {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }
  .copyRight {
    font-size: ${isMobile ? "15px" : ""};
    margin-top: ${isMobile ? "15px" : ""};
    margin-right: ${isMobile ? "5px" : "10px"};
    margin-left: ${isMobile ? "15px" : ""};
  }
  .terms {
    font-size: ${isMobile ? "15px" : ""};
    text-align: ${isMobile ? "" : "center"};
    margin-left: ${isMobile ? "50px" : ""};
  }
  background: #f4f4f5;
  color: black;
  clear: both;
  bottom: 0px;
  .icon-footer {
    display: ${isMobile ? "grid" : "flex"};
    justify-content: center;
    background-color: #f4f4f5;
    margin-left: ${isMobile ? "" : "10px"};
    margin-right: ${isMobile ? "" : "25px"};
  }
  .footerLogo {
    width: ${isMobile ? "90px" : "200px"};
    height: ${isMobile ? "45px" : ""};
  }
  .app-div {
    margin-right: ${isMobile ? "-15px" : "10px"};
    margin-top: ${isMobile ? "5px" : ""};
  }
  .android-app {
    margin-right: ${isMobile ? "10px" : "10px"};
    margin-top: ${isMobile ? "5px" : ""};
  }
  .copy-div {
    text-align: ${isMobile ? "center" : ""};
    margin: 10px;
    padding: 10px;
    display: ${isMobile ? "" : "flex"};
  }
  .copy-text {
    font-size: 18px;
    margin-left: ${isMobile ? "10px" : "30px"};
  }
  .terms-text {
    display: flex;
    justify-content: space-around;
  }
  .play-store {
    display: ${isMobile ? "flex" : "flex"};
    justify-content: ${isMobile ? "" : "end"};
    padding: 10px;
  }
  .down {
    text-align: ${isMobile ? "center" : "end"};
    font-size: 16px;
    font-weight: 600;
    margin-right: ${isMobile ? "" : "100px"};
  }
  .sell-on {
    margin-top: 10px;
    font-size: 20px;
    text-decoration: none;
    color: #000;
  }
  .sell-list {
    list-style: none;
  }
  .mid-footer {
    display: flex;
    justify-content: ${isMobile ? "space-between" : "space-evenly"};
    margin: 10px;
  }
  .link-div {
    display: grid;
    margin-left: ${isMobile ? "" : "65px"};
    margin-right: ${isMobile ? "" : "200px"};
  }
  .sitemap-div {
    display: grid;
  }
  .sitemap-link {
    text-decoration: none;
    color: #000;
  }
  .icon-div {
    margin-left: ${isMobile ? "35px" : "45px"};
  }
  .linked-icon {
    width: 30px;
    margin: 10px;
  }
  .facebook-icon {
    width: 30px;
    margin-left: 10px;
  }
  .insta-icon {
    width: 55px;
    margin-left: 10px;
  }
  .twitter-icon {
    width: 30px;
    margin-left: 10px;
  }
`;
