import { useHistory } from "react-router-dom";
import { successMessage } from "./style";
import { useIsMobile } from "../../components/context/IsMobile";

const StripePaymentCancel = () => {
  const history = useHistory();
  const onGoToOrder = () => {
    history.push("/plans");
  };
  const isMobile = useIsMobile();
  return (
    <div className={successMessage(isMobile)}>
      <h4>Oops! Your payment has been cancelled.</h4>
      <p>
        We appreciate your business! If you have any questions, please email
        <a href="mailto:ironlist@ironlist.com"> ironlist@ironlist.com </a>.
      </p>
      <div>
        <button
          className="btn"
          style={{ background: "#f88932", color: "white" }}
          onClick={onGoToOrder}
        >
          Go to Order page
        </button>
      </div>
    </div>
  );
};

export default StripePaymentCancel;
