/* eslint-disable react/prop-types */
import React from "react";
import {LeadsDetailsStyle} from "./style";
import {CloseThick} from "@ironlist/ironlist-icons";
import {useIsMobile} from "../../components/context/IsMobile";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function LeadsDetails({show, detailsData, onClose}) {
  const image = detailsData?.thumbnail_url || detailsData?.imageurl;
  const isMobile = useIsMobile();
  const data = [
    {label: "Name:", value: detailsData?.name},
    {label: "Budget:", value: detailsData?.budget},
    {label: "Address:", value: detailsData?.address},
    {label: "City:", value: detailsData?.city},
    {label: "State:", value: detailsData?.state},
    {label: "Country:", value: detailsData?.country},
    // { label: "Contact:", value: detailsData?.phone_no },
    // { label: "Email:", value: detailsData?.email_id },
    {label: "Language:", value: detailsData?.language_preference},
    {label: "Buyer Type:", value: detailsData?.buyer_type},
    {label: "Message:", value: detailsData?.message},
  ];
  const number = detailsData.phone_no;
  const dialCode = detailsData.dial_code;
  const email = detailsData.email_id;
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
        height: "auto",
      }}
      className={LeadsDetailsStyle(isMobile)}
    >
      <div className="popupContainerStyle">
        <div className="detailHeader">
          <img
            src={image}
            alt="itemImage"
            width={64}
            height={64}
            className="item-image"
          />
          <h6 className="itemTitle">{detailsData?.item_title}</h6>
          <CloseThick className="closeThick" onClick={onClose} />
        </div>
        <div className="leadsInfo">
          <h3 className="infoTitle">Lead information</h3>
          {data.map((item, index) => (
            <div className="information" key={index}>
              <h5 className="infoLabel">{item?.label}</h5>
              <h5 className="infoValue">{item?.value}</h5>
            </div>
          ))}
          <div style={{display: "flex"}}>
            <h5 className="infoLabel">Contact:</h5>
            {number}
            <a href={`tel:${number}`} style={{textDecoration: "none"}}>
              <LocalPhoneIcon style={{marginLeft: "10px"}} />
            </a>
            <a
              href={`https://web.whatsapp.com/send?phone=+${dialCode}${number}&text=Hello ${detailsData?.name} Hope You doing Well! I found An enquiry for this ${detailsData?.item_title} at Ironlist.If you are still looking for this you can call me on this number.`}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppIcon className="whatsapp" />
            </a>
          </div>
          <div style={{display: "flex"}}>
            <h5 className="infoLabel">Email: </h5>
            <a className="infoValue" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
