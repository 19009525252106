import * as React from "react";

const SvgComponent = ({ title, titleId, ...props }) => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm4.411-5.805a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zm-2 0a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5zM20 18h-4V9h4v9z"
      fill="#1F2330"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgComponent;
