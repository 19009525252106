import React, { useState } from "react";
import { faq } from "../../utils/const";
import { ChevrondownThick, ChevronupThick } from "@ironlist/ironlist-icons";
import { faqWrapper } from "./styles";
import { useIsMobile } from "../../components/context/IsMobile";
export default function FAQ() {
  const isMobile = useIsMobile();
  const [activeAnswerId, setActiveAnswerId] = useState("");
  const questionClick = (id) => {
    if (activeAnswerId === id) {
      setActiveAnswerId("");
    } else {
      setActiveAnswerId(id);
    }
  };
  return (
    <div className={faqWrapper(isMobile)}>
      <div className="faqs">
        <h2 className="text-center">
          <strong> Frequently asked questions</strong>
        </h2>
        {faq.map(({ question, id, answer }) => (
          <div className="faq-wrapper" key={id}>
            <div className="question-wrapper" onClick={() => questionClick(id)}>
              <h5 variant="p2" className="question">
                {question}
              </h5>
              {activeAnswerId === id ? (
                <ChevronupThick />
              ) : (
                <ChevrondownThick />
              )}
            </div>
            {activeAnswerId === id && <h5 className="answer">{answer}</h5>}
          </div>
        ))}
      </div>
    </div>
  );
}
