import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import MidPage from "./MidPage";
import { backgroundStyle, machineStyle } from "./style";

const LoaderBackhoe = () => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title =
      "Sell Loader Backhoe Online On IronList | Heavy Equipment & Machines. Used Backhoe Loader for sale | jcb price | loader with backhoe | jcb backhoe loader";
  }, []);
  return (
    <div className={backgroundStyle}>
      <div style={{ overflow: "hidden" }}>
        <div className={machineStyle(isMobile)}>
          <div className="images-div">
            <div>
              <h4 className="h4-div">
                Sell Loader Backhoe Online On IronList | Heavy Equipment &
                Machines
              </h4>
              <div style={{ textAlign: "center" }}>
                <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
                  <button className="mid-sell-button">Start Selling</button>
                </Link>
              </div>
            </div>
            <img
              className="imgs "
              src={`./assets/images/Sell Loader Backhoe Online Ironlist.jpg`}
              alt="excavator"
            />
          </div>
        </div>
        <MidPage />
      </div>
    </div>
  );
};

export default LoaderBackhoe;
