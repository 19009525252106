import { css } from "@emotion/css";

export const overlay = css`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
`;
export const popupContainer = (isMobile) => css`
  margin: 100px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: ${isMobile ? "336px" : "30%"};
  position: relative;
  transition: all 5s ease-in-out;
`;
