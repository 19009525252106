import React from "react";
import { ChevronleftThick } from "@ironlist/ironlist-icons";
import { imageUrl } from "../../utils";
import { Link } from "react-router-dom";

function SuccessPage({ setStep }) {
  return (
    <div className="info-container">
      <h3 className="headerStyle">
        <ChevronleftThick
          onClick={() => {
            setStep(3);
          }}
          className="back-button"
        />
        Add Items OR Manage WebSite
      </h3>

      <div className="noWebsite">
        <div className="noWebsiteInfo">
          <img
            src={imageUrl("/images/asset-3-site.webp")}
            alt="No Website Yet"
            width={268}
            height={220}
          />
          <h5>
            <strong>Your website is ready!</strong>
          </h5>
          <p className="description">
            You can now add items in your website or manage it.
          </p>
          <div className="d-flex">
            <button
              className="btn"
              style={{
                border: "2px solid #f88932",
                color: "#f88932",
                marginRight: "16px",
              }}
              onClick={() => {
                setStep(3);
              }}
            >
              Manage
            </button>
            <Link to={"/listing/new"}>
              <button
                className="btn"
                style={{
                  background: "#f88932",
                  color: "white",
                }}
              >
                Add Items
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SuccessPage;
