import PhoneInput from "react-phone-input-2";
import { useState } from "react";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { Checkbox } from "@mui/material";
import { CloseThick } from "@ironlist/ironlist-icons";
import { CountryDropdown } from "react-country-region-selector";
import authService from "../../pages/Authentication/auth.service";
import { useHistory } from "react-router-dom";
import { orange } from "@mui/material/colors";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useIsMobile } from "../context/IsMobile";
import { overlay, pendingInfoStyle } from "./style";
import { useEffect } from "react";
import { getStorage } from "../../utils/localStorage";

export default function PendingInfo({ show, email }) {
  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [vendorType, setVendorType] = useState("agent");
  const [country, setCountry] = useState("");
  const [checked, setChecked] = useState(false);
  const [otpInput, setOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [key, setKey] = useState("");
  const [verifyOTPSuccess, setVerifyOTPSuccess] = useState(false);
  const [isValidContactNumber, setValidContactNumber] = useState(false);
  const history = useHistory();

  const isMobile = useIsMobile();

  useEffect(() => {
    const user = getStorage("user");
    if (user) {
      console.log(user);
      setCountry(user.country);
      setCompanyName(user.companyName);
      setDialCode(user.dialCode);
      setPhone(user.phone);
      setVendorType(user.vendorType?.toLowerCase());
    }
  }, []);

  const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

  const submitPendingInfo = (e) => {
    e.preventDefault();
    // if (!verifyOTPSuccess) {
    //   toast.error(`Please Verify Your Mobile Number!`, {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // }
    if (!phone) {
      setValidContactNumber(true);
    }
    if (phone && companyName && vendorType && country) {
      const body = {
        dialCode,
        phone,
        companyName,
        vendorType,
        email,
        country,
      };
      authService.updateProfile(body).then((response) => {
        if (response?.data.success) {
          const user = JSON.parse(localStorage.getItem("user"));
          user.pendingInfo = false;
          localStorage.setItem("user", JSON.stringify(user));
          history.push("/listings");
          window.location.reload();
        }
      });
    }
  };
  const onLogOut = (e) => {
    e.preventDefault();
    authService.logout();
    window.location.reload();
  };
  const onSendOTP = (e) => {
    e.preventDefault();
    authService
      .sendOTP({
        phone,
        countryCode: dialCode,
        phoneNo: dialCode.concat(phone),
      })
      .then((response) => {
        if (response.data.success) {
          setOtpInput(true);
          setKey(response.data.key);
          toast.success(`OTP Send Successfully !`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const onVerifyMobileOTP = (e) => {
    e.preventDefault();
    authService
      .verifyMobileOTP({
        phoneNo: dialCode.concat(phone),
        otp,
        key,
      })
      .then((response) => {
        if (response.data.success) {
          setVerifyOTPSuccess(true);
          setOtpInput(false);
          toast.success(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
        height: "auto",
      }}
      className={overlay}
    >
      <div className={pendingInfoStyle(isMobile)}>
        <div className="pendingInfoHeader">
          <h3>Pending Info</h3>
          <CloseThick
            width={20}
            height={20}
            className="closeThick"
            onClick={onLogOut}
          />
        </div>
        <div className="content">
          <Form onSubmit={submitPendingInfo}>
            <div className="form-group mt-3">
              <label htmlFor="contactNumber">Contact Number</label>
              <PhoneInput
                inputProps={{
                  name: "phone_number",
                  required: true,
                }}
                value={`+${dialCode} ${phone}`}
                specialLabel=""
                name="phone_number"
                country={"us"}
                onChange={(value, data, event, formattedValue) => {
                  setDialCode(data.dialCode);
                  setPhone(value.slice(data.dialCode.length));
                  setValidContactNumber(true);
                }}
                required
                containerStyle={{
                  border: "none",
                  marginTop: "4px",
                  height: "48px",
                }}
                inputStyle={{
                  backgroundColor: "#f4f4f5",
                  border: "1px solid #f4f4f5",
                  height: "48px",
                  width: isMobile ? "336px" : "330px",
                  "&:focus": {
                    borderBottom: "1px solid black",
                  },
                }}
              />
              {!otpInput && !verifyOTPSuccess && (
                // <h6
                //   style={{
                //     color: "#f88932",
                //     fontWeight: "bold",
                //     cursor: "pointer",
                //     float: "right",
                //     marginTop: "5px",
                //   }}
                //   onClick={onSendOTP}
                // >
                //   Send OTP
                // </h6>
                <></>
              )}
              {!otpInput && verifyOTPSuccess && (
                <div
                  className="d-flex align-items-center"
                  style={{
                    float: "right",
                    marginTop: "5px",
                  }}
                >
                  <Checkbox
                    checked
                    inputProps={{ "aria-label": "controlled", padding: "0px" }}
                    sx={{
                      color: "#f88932",
                      "&.Mui-checked": {
                        color: "#f88932",
                      },
                      padding: "0px",
                    }}
                  />
                  <h6
                    style={{
                      marginBottom: "0px",
                      marginLeft: "4px",
                      color: "#f88932",
                      fontWeight: "bold",
                    }}
                  >
                    Verified
                  </h6>
                </div>
              )}
              {otpInput && (
                <div className="form-group mt-3">
                  <h5>Enter OTP</h5>
                  <OtpInput
                    autoComplete="off"
                    value={otp}
                    onChange={(value) => {
                      setOtp(value);
                    }}
                    numInputs={6}
                    separator={" "}
                    placeholder="0"
                    inputStyle={{
                      width: "2rem",
                      height: "2rem",
                      marginRight: "6px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "1px solid grey",
                    }}
                  />
                  <div className="d-flex">
                    <h6
                      className="mt-3"
                      style={{
                        color: "#f88932",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={onSendOTP}
                    >
                      Resend OTP
                    </h6>
                    <h6
                      className="mt-3"
                      style={{
                        color: "#f88932",
                        fontWeight: "bold",
                        cursor: "pointer",
                        marginLeft: "16px",
                      }}
                      onClick={onVerifyMobileOTP}
                    >
                      Verify OTP
                    </h6>
                  </div>
                </div>
              )}
              {!!isValidContactNumber && required(phone)}
            </div>
            <div className="form-group mt-3">
              <label htmlFor="companyName">Company Name</label>
              <Input
                required
                type="text"
                className="form-control"
                name="companyName"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                validations={[required]}
              />
            </div>
            <div className="form-group mt-3">
              <div className="row">
                <FormControl
                  component="fieldset"
                  style={{ marginLeft: "11px" }}
                >
                  <label htmlFor="vendorType">I am</label>
                  <RadioGroup
                    row
                    aria-label="vendorType"
                    name="row-radio-buttons-group"
                    value={vendorType}
                    onChange={(e) => {
                      setVendorType(e.target.value);
                    }}
                    validations={[required(vendorType)]}
                  >
                    <FormControlLabel
                      value="agent"
                      control={
                        <Radio
                          sx={{
                            color: orange[800],
                            "&.Mui-checked": {
                              color: orange[800],
                            },
                          }}
                        />
                      }
                      label="Agent"
                      className="ml-3"
                    />
                    <FormControlLabel
                      value="dealer"
                      control={
                        <Radio
                          sx={{
                            color: orange[800],
                            "&.Mui-checked": {
                              color: orange[800],
                            },
                          }}
                        />
                      }
                      label="Dealer"
                    />
                    <FormControlLabel
                      value="vendor"
                      control={
                        <Radio
                          sx={{
                            color: orange[800],
                            "&.Mui-checked": {
                              color: orange[800],
                            },
                          }}
                        />
                      }
                      label="Vendor"
                    />
                    <FormControlLabel
                      value="manufacturer"
                      control={
                        <Radio
                          sx={{
                            color: orange[800],
                            "&.Mui-checked": {
                              color: orange[800],
                            },
                          }}
                        />
                      }
                      label="Manufacturer"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="form-group mt-3">
              <label htmlFor="country">Country</label>
              <CountryDropdown
                style={{
                  width: "100%",
                  height: "38px",
                  borderRadius: "4px",
                }}
                value={country}
                onChange={(e) => {
                  setCountry(e);
                }}
                required
                validations={[required(country)]}
              />
            </div>
            <div className="form-group mt-3">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "#f88932",
                    "&.Mui-checked": {
                      color: "#f88932",
                    },
                    padding: "0px",
                  }}
                />
                <h6 style={{ marginBottom: "0px", marginLeft: "4px" }}>
                  I agree to IronList{" "}
                  <a
                    href="https://ironlist.com/term-of-use"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#f88932" }}
                  >
                    {" "}
                    Terms of use & Privacy Policy.
                  </a>
                </h6>
              </div>
            </div>
            <div className="form-group my-2">
              {checked ? (
                <button
                  type="submit"
                  className="btn"
                  style={{ background: "#f88932", color: "white" }}
                >
                  Submit
                </button>
              ) : (
                <button
                  className="btn"
                  style={{ background: "#f88932", color: "white" }}
                  disabled
                >
                  Submit
                </button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
