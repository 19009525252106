import React from "react";
import c from "classnames";
import { useHistory } from "react-router-dom";
import ReactPaginate from "rc-pagination";
import { ChevronleftThick, ChevronrightThick } from "@ironlist/ironlist-icons";
import { pagination } from "./style";
import { useIsMobile } from "../context/IsMobile";

export default function Pagination({
  totalCount,
  pageSize,
  pathname = "/listings",
}) {
  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get("page");
  const history = useHistory();
  const onChange = (current) => {
    history.push({
      pathname,
      search: `?page=${current}`,
    });
  };
  const Item = (_, type, element) => {
    if (type === "prev") {
      return (
        <button className="btn direction-button">
          <ChevronleftThick
            height={16}
            className={c("direction-icon", "previous-icon")}
            title="Chevron left"
          />
          Previous
        </button>
      );
    }
    if (type === "next") {
      return (
        <button className="btn direction-button">
          Next
          <ChevronrightThick
            height={16}
            className={c("direction-icon", "next-icon")}
            title="Chevron right"
          />
        </button>
      );
    }
    return element;
  };
  const isMobile = useIsMobile();
  return (
    <div className={pagination(isMobile)}>
      <ReactPaginate
        locale={"en_GB"}
        current={parseInt(Number(page), 10) || 1}
        total={totalCount}
        pageSize={pageSize}
        itemRender={Item}
        onChange={onChange}
      />
    </div>
  );
}
