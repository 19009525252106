import { DeleteThick, EditThick } from "@ironlist/ironlist-icons";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner";
import { imageUrl } from "../../utils";
import { webSiteTables } from "./style";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ConfirmationPopUp from "../../components/ConfirmationPopup/ConfirmationPopup";
import { useIsMobile } from "../../components/context/IsMobile";

function CreateWebsite({
  setStep,
  Data,
  onManageWebsite,
  loading,
  createSubDomain,
}) {
  const history = useHistory();
  const isMobile = useIsMobile();
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDeleteMyWebsite = (id) => {
    setShowPopup(false);
    setData(data.filter((item) => item.id !== id));
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/dashboard/products/delete/${id}`,
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setDeleteId("");
          toast.error("Item Deleted Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  return (
    <>
      <div className="info-container">
        <div className="headerStyle">
          <Button
            variant="light"
            className="createWebsite"
            onClick={() => {
              createSubDomain ? setStep(1) : history.push("/websitePlans");
            }}
          >
            Create New Website
          </Button>
        </div>
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {Data.length > 0 && (
              <div className={webSiteTables}>
                <table>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Banner</th>
                      <th>Logo</th>
                      <th>Sub Domain</th>
                      <th>Manage</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data?.map((item, index) => (
                      <tr key={item?.subdomain_name}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            src={item?.banner}
                            alt="website banner"
                            width={150}
                            height={100}
                            className="item-image"
                          />
                        </td>
                        <td>
                          <img
                            src={item?.vendor_logo}
                            alt="website logo"
                            width={48}
                            height={48}
                            className="item-image"
                          />
                        </td>
                        <td>{item?.subdomain_name}</td>
                        <td>
                          <div>
                            <EditThick
                              className="mx-2"
                              style={{ color: "black", opacity: 0.7 }}
                              onClick={() => {
                                setStep(3);
                                onManageWebsite(item?.subdomain_name);
                              }}
                            />
                            <DeleteThick
                              onClick={() => {
                                setShowPopup(true);
                                setDeleteId(item?.id);
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          <a
                            href={`https://${item?.subdomain_name}.ironlist.com`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#f88932" }}
                          >
                            Click
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {Data.length === 0 && (
              <div className="noWebsite">
                <div className="noWebsiteInfo">
                  <img
                    src={imageUrl("/images/create-website.webp")}
                    alt="No Website Yet"
                    width={268}
                    height={220}
                  />
                  <h5>
                    <strong>No websites created!</strong>
                  </h5>
                  <p className="description">
                    You don’t have any websites yet. Click here to create one!
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <ToastContainer />
      {showPopup && (
        <ConfirmationPopUp
          show={showPopup}
          message="Are you sure you want to delete this item?"
          onConfirmClick={() => handleDeleteMyWebsite(deleteId)}
          onCancelClick={() => setShowPopup(false)}
          buttonName="Delete"
          isMobile={isMobile}
        />
      )}
    </>
  );
}
export default CreateWebsite;
