import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { isEmail } from "validator";
import CheckButton from "react-validation/build/button";
import authService from "./auth.service";
import PendingInfo from "../../components/PendingInfo/PendingInfo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleLogin from "react-google-login";
import axios from "axios";
import { LinkedIn } from "react-linkedin-login-oauth2";
import {
  FacebookLoginIcon,
  LinkedinLoginIcon,
} from "../../components/Icons/index";
import FacebookLogin from "react-facebook-login";
import { Checkbox } from "@mui/material";
import { VisibilityOff } from "@material-ui/icons";
import { Visibility } from "@mui/icons-material";
import { CallThick } from "@ironlist/ironlist-icons";
import { Link } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const email = (value) => {
  if (!isEmail(value?.trim())) {
    return (
      <div className="alert alert-danger" role="alert">
        Please Enter Your Valid Email.
      </div>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onResendEmail = this.onResendEmail.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.LinkedInSuccess = this.LinkedInSuccess.bind(this);

    this.state = {
      email: "",
      remail: "",
      password: "",
      loading: false,
      message: "",
      show: JSON.parse(localStorage.getItem("user"))?.pendingInfo || false,
      resendEmail: false,
      isChecked: true,
      showPassword: false,
    };
  }
  handleClickShowPassword = () => {
    this.setState({
      ...this.state.password,
      showPassword: !this.state.showPassword,
    });
  };
  onChangeEmail(e) {
    this.setState({
      email: e.target.value?.trim(),
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value?.trim(),
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      authService.login(this.state.email, this.state.password).then(
        (response) => {
          if (response.success && response.emailVerified) {
            if (response.pendingInfo) {
              this.setState({
                show: true,
              });
            }
            if (response.pendingInfo === false) {
              this.props.history.push("/dashboard");
              window.location.reload();
            }
          }
          if (response.success && !response.emailVerified) {
            this.setState({
              loading: false,
              resendEmail: true,
            });
            toast.warn(`Please Verify Your Email`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  }
  onResendEmail(e) {
    e.preventDefault();
    authService.resendEmailVerification(this.state.email).then((response) => {
      if (response.data.success) {
        toast.success(`${response.data.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  }
  responseGoogle(response) {
    const options = {
      url: process.env.REACT_APP_API_URL + "/vregisterGoogle",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: { tokenId: response.tokenId, agreeTerms: true },
    };
    axios(options).then((value) => {
      localStorage.setItem("user", JSON.stringify(value.data));
      if (value.data.pendingInfo === false) {
        this.props.history.push("/dashboard");
        window.location.reload();
      }
      if (value.data.pendingInfo) {
        this.setState({ show: true, remail: value.data.email });
      }
    });
  }
  responseGoogleFailure(response) {
    if (!response === 200) {
      toast.warn(`Something went wrong please try again later`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }
  LinkedInSuccess = (code) => {
    authService.linkedinSignIn({ code, agreeTerms: true }).then((value) => {
      localStorage.setItem("user", JSON.stringify(value.data));
      if (value.data.pendingInfo === false) {
        this.props.history.push("/dashboard");
        window.location.reload();
      }
      if (value.data.pendingInfo) {
        this.setState({ show: true, remail: value.data.email });
      }
    });
  };

  responseFacebook = (response) => {
    authService
      .facebookSignIn({
        accessToken: response.accessToken,
        agreeTerms: true,
      })
      .then((value) => {
        localStorage.setItem("user", JSON.stringify(value.data));
        if (value.data.pendingInfo === false) {
          this.props.history.push("/dashboard");
          window.location.reload();
        }
        if (value.data.pendingInfo) {
          this.setState({ show: true, remail: value.data.email });
        }
      });
  };
  render() {
    return (
      <div
        className="bg-white"
        style={{
          borderRadius: "4px",
          padding: "32px",
          marginTop: this.props.isMobile ? "100px" : "",
        }}
      >
        <h3>
          <strong>Sign In</strong>
        </h3>
        <Form
          onSubmit={this.handleLogin}
          ref={(c) => {
            this.form = c;
          }}
        >
          <div className="form-group mt-4">
            <label htmlFor="email">Email</label>
            <Input
              type="text"
              className="form-control"
              name="email"
              value={this.state.email.trim()}
              onChange={this.onChangeEmail}
              validations={[required, email]}
            />
          </div>

          <div className="form-group mt-3">
            <label htmlFor="password">Password</label>
            <Input
              type={this.state.showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              value={this.state.password}
              onChange={this.onChangePassword}
              validations={[required]}
            />{" "}
            {this.state.showPassword ? (
              <Visibility
                onClick={this.handleClickShowPassword}
                style={{ marginTop: "-75px", color: "gray", marginLeft: "90%" }}
              />
            ) : (
              <VisibilityOff
                onClick={this.handleClickShowPassword}
                style={{ marginTop: "-75px", color: "gray", marginLeft: "90%" }}
              />
            )}
          </div>

          <div className="form-group my-2">
            <h6 className="my-3">
              <strong
                className="mx-1"

                // onClick={() => {
                //   this.props.setForgotPassword(true);
                // }}
              >
                <Link
                  style={{
                    color: "#f88932",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to="/forgotPassword"
                >
                  Forgot Password?
                </Link>
              </strong>
            </h6>
            <button
              className="btn btn-block mb-3"
              disabled={this.state.loading}
              style={{
                background: "#f88932",
                color: "white",
                width: "40%",
              }}
            >
              {this.state.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Sign In</span>
            </button>
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <div
              className="GoogleLogIn"
              style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
            >
              <GoogleLogin
                clientId="985398972528-kh601re0qhje07jhgfr1rpm1fd7boub4.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                buttonText="Login with Google"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogleFailure}
                disabled={!this.state.isChecked}
                className="my-3"
              />
            </div>
            <div className="linkedInSignUp my-3">
              <LinkedIn
                clientId="77i11fj4mstd2n"
                redirectUri={`${window.location.origin}/linkedin`}
                onSuccess={(code) => this.LinkedInSuccess(code)}
                scope="r_liteprofile,r_emailaddress,w_member_social"
                onError={(error) => {
                  console.log(error);
                }}
              >
                {({ linkedInLogin }) => (
                  <div
                    className="LinkedInButton"
                    onClick={this.state.isChecked && linkedInLogin}
                    style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
                  >
                    <LinkedinLoginIcon className="LinkedInIcon" />
                    <span className="facebookText">Login with LinkedIn</span>
                  </div>
                )}
              </LinkedIn>
            </div>
            <div
              className="facebookSignIn my-3"
              style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
            >
              <FacebookLogin
                cssClass="LinkedInButton"
                appId="270638241929286"
                icon={<FacebookLoginIcon className="facebookIcon" />}
                textButton={
                  <span className="facebookText">Login with Facebook</span>
                }
                callback={this.responseFacebook}
                isDisabled={!this.state.isChecked}
              />
            </div>
            <div
              className="phoneSignIn"
              style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
            >
              <div>
                <CallThick className="phoneIcon" />
                <span
                  className="phoneText"
                  onClick={() => {
                    this.props.setPhoneLogin(true);
                  }}
                >
                  Login with Mobile Number
                </span>
              </div>
            </div>
            <div className="form-group my-3">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={(e) => {
                    this.setState({ isChecked: e.target.checked });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "#f88932",
                    "&.Mui-checked": {
                      color: "#f88932",
                    },
                    padding: "0px",
                  }}
                />
                <h6 style={{ marginBottom: "0px", marginLeft: "4px" }}>
                  I agree to IronList{" "}
                  <a
                    href="https://ironlist.com/term-of-use"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#f88932" }}
                  >
                    Terms of use & Privacy Policy.
                  </a>
                </h6>
              </div>
            </div>
            <h6 className="my-3">
              Don’t have an account yet?
              <strong
                className="mx-1"

                // onClick={() => {
                //   this.props.setIsLogin(false);
                // }}
              >
                <Link
                  style={{
                    color: "#f88932",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  to="/register"
                >
                  Sign Up
                </Link>
              </strong>
            </h6>
          </div>

          <CheckButton
            style={{ display: "none" }}
            ref={(c) => {
              this.checkBtn = c;
            }}
          />
        </Form>
        {this.state.show && (
          <PendingInfo
            show={this.state.show}
            email={this.state.remail || this.state.email}
          />
        )}
        {this.state.resendEmail && (
          <h6
            style={{
              color: "#f88932",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={this.onResendEmail}
          >
            Resend Email Verification Link
          </h6>
        )}
        <ToastContainer />
      </div>
    );
  }
}
