import { css } from "@emotion/css";

export const mainNavStyle = css`
  top: 0px;
  width: 100%;
  display: block;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
  height: auto;
  position: fixed;
  z-index: 1;
  background: #f6f6f5;
`;
export const navMobileStyle = (isMobile) => css`
  display: flex;
  justify-content: space-between;
  margin: 10px;
  .logo-div {
    width: 100px;
    height: 20px;
  }
  .mbuttons-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }
  .mstart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 40px;
    color: white;
    background: #f88932;
    border-color: #f88932;
    box-shadow: rgb(223 225 229) 0px 0px 4px;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    border-radius: 6px;
    margin-right: 10px;
  }
  .mlogin-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${isMobile ? "15px" : "16px"};
    line-height: 20px;
    justify-content: center;
    border: 1px solid #337ab7;
    background-color: #337ab7;
    color: #ffffff;
    height: 38px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 4px 0px;
    margin-right: ${isMobile ? "25px" : "16px"};
    width: ${isMobile ? "75px" : "100px"};
  }
`;
export const listStyle = (isMobile) => css`
  .mul_div {
    display: flex;
    list-style: none;
    margin-top: -20px;
    margin-left: -13px;
  }
  .mli_div {
    display: inline;
    list-style-position: inside;
    padding: 14px 8px;
    line-height: 20px;
    font-weight: 600;
    color: rgb(99, 99, 99);
    font-size: 15px;
  }
  .faq_div {
    list-style-position: inside;
    margin-top: ${isMobile ? "15px" : ""};
    margin-left: ${isMobile ? "10px" : ""};
    line-height: 20px;
    font-weight: 600;
    color: rgb(99, 99, 99);
    font-size: 15px;
    padding: ${isMobile ? "" : "14px 8px"};
  }
  .faq-link {
    text-decoration: none;
  }
`;
