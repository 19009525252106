/* eslint-disable react/prop-types */
import * as React from "react";

const SvgComponent = ({ title, titleId, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    viewBox="0 0 172 172"
    style={{
      fill: "#000",
    }}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g
      fill="none"
      strokeMiterlimit={10}
      fontFamily="none"
      fontWeight="none"
      fontSize="none"
      textAnchor="none"
      style={{
        mixBlendMode: "normal",
      }}
    >
      <path d="M0 172V0h172v172z" />
      <path
        d="M150.5 132.583c0 9.897-8.02 17.917-17.917 17.917H39.417c-9.894 0-17.917-8.02-17.917-17.917V39.417c0-9.898 8.023-17.917 17.917-17.917h93.166c9.897 0 17.917 8.02 17.917 17.917z"
        fill="#0a66c2"
      />
      <path
        d="M43 68.083h17.917V129H43zm8.905-7.166h-.1c-5.347 0-8.805-3.985-8.805-8.962C43 46.87 46.565 43 52.008 43c5.45 0 8.808 3.87 8.909 8.955 0 4.973-3.458 8.962-9.012 8.962zM129 129h-17.917V96.395c0-7.876-4.39-13.25-11.438-13.25-5.378 0-8.288 3.625-9.7 7.13-.516 1.254-.362 4.723-.362 6.475V129H71.667V68.083h17.916v9.374c2.584-3.999 6.63-9.374 16.978-9.374 12.821 0 22.435 8.063 22.435 26.065L129 129z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgComponent;
