import { css } from "@emotion/css";

export const mobileHeader = css`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .mobile-user-location {
    margin-bottom: 0px;
  }
  .active-tabs {
    margin-left: 12px;
  }

  .mobile-header-left {
    display: flex;
    align-items: center;
  }

  .mobile-header-right {
    display: flex;
    align-items: center;
  }

  .sidebar-icon:hover {
    cursor: pointer;
  }

  .menu-icon-wrapper {
    padding: 14px 0px 14px 30px;
    display: flex;
    margin-right: 8px;
    margin-left: -24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    h5 {
      margin-bottom: 0px;
    }
  }
  .sidebar-item {
    cursor: pointer;
  }

  .cursor-pointer svg:nth-child(1) {
    cursor: pointer;
  }

  .mobile-header-logo {
    transform: scale(0.65);
    margin-left: -25px;
  }

  .icon {
    fill: #62656e;
  }
  .sidebar-background {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99998;
  }

  .sidebar {
    a {
      text-decoration: none;
    }
    width: 280px;
    background: white;
    height: 100%;
    position: relative;
    z-index: 99999;
  }

  .sidebar-item {
    display: flex;
    margin-left: 24px;
  }

  .mobile-sidebar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    background-color: #fbfbfb;
  }

  .sidebar-item-name {
    color: #1f2330;
    margin-left: 24px;
  }

  .profile-section-divider {
    background-color: #e0e1e3;
    width: 100%;
    height: 1px;
  }

  .sidebar-top-container {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }

  .sidebar-name-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
  }

  .initials-circle {
    font-size: 1.4em;
    color: #f88932;
    opacity: 0.7;
    font-size: 12px;
    font-weight: 800;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin-right: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: grid;
    place-items: center;
  }

  .sidebar-hello-text {
    color: #1f2330;
    margin-bottom: 0px;
    font-size: 14px;
    opacity: 0.7;
  }
`;
