import { css } from "@emotion/css";

export const tabContainerStyles = css`
  width: 240px;
  border-radius: 4px;
  border: 1px solid #e0e1e3;
  background-color: white;
  margin-left: 6%;
  height: fit-content;
`;

export const tabStyles = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 16px 16px 24px;
  .tabLabel {
    margin-left: 12px;
    margin-bottom: 0px !important;
  }
  .selectedIcons {
    width: 32px;
    height: 32px;
    opacity: 0.43;
    border-radius: 16px;
    background-color: #fbbe8f;
    text-align: center;
  }
`;

export const separatorStyles = css`
  height: 1px;
  background-color: #e0e1e3;
`;

export const descriptionStyles = css`
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #e0e1e3;
`;

export const selectedIndicatorStyles = css`
  position: absolute;
  width: 4px;
  height: 20px;
  left: 0;
  top: 50%;
  background-color: #f88932;
  transform: translateY(-50%);
`;
