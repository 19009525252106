import React, {useState} from "react";
import ImageUploading from "react-images-uploading";
import S3 from "react-aws-s3";
import {AddAPhoto} from "@material-ui/icons";
import {v4 as uuidv4} from "uuid";

function Images({setImageUrl, imageUrl, pathName}) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateIndex, setUpdateIndex] = useState("");
  const [uploadedImageList, setUploadedImageList] = useState([]);
  const maxNumber = 69;
  const ReactS3Client = new S3({
    bucketName: "vendorportal1",
    dirName: pathName,
    region: "ap-south-1",
    accessKeyId: "AKIAVEWLVLET6D3OVZTD",
    secretAccessKey: "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",
  });

  const onChange = async (imageList) => {
    imageList = imageList.filter(
      (image) => !uploadedImageList.includes(image.data_url)
    );
    for (let i = 0; i < imageList.length; i++) {
      setLoading(true);
      await ReactS3Client.uploadFile(
        imageList[i]?.file,
        `${uuidv4()}_${imageList[i]?.file?.name}`
      )
        .then((data) => {
          if (data.location) {
            setUploadedImageList((arr) => [...arr, imageList[i]?.data_url]);
            if (updateIndex || updateIndex === 0) {
              let updateImage = imageUrl;
              updateImage[updateIndex] = data.location;
              setImageUrl([...imageUrl, updateImage]);

              setLoading(false);
              setUpdateIndex("");
              setImages([]);
            }
            if (!updateIndex && updateIndex !== 0) {
              setImageUrl((arr) => [...arr, data.location]);
              setImages(imageList);
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };

  const onRemoveImage = (removeIndex) => {
    setImageUrl(imageUrl.filter((item, index) => index !== removeIndex));
  };

  const removeAllImage = () => {
    setImageUrl([]);
    setUploadedImageList([]);
    setImages([]);
  };

  return (
    <div className="my-2">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              className="my-2 btn"
              disabled={loading && !updateIndex && updateIndex !== 0}
              style={
                isDragging
                  ? {background: "#e0e1e3"}
                  : {background: "#f88932", color: "white"}
              }
              onClick={(e) => {
                e.preventDefault();
                onImageUpload();
              }}
              {...dragProps}
            >
              {loading && !updateIndex && updateIndex !== 0 && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <AddAPhoto style={{marginBottom: 5, marginRight: 5}} /> Add Images
            </button>
            &nbsp;
            <button
              className="my-2  mx-3 btn"
              style={{background: "#e0e1e3", height: "43px"}}
              onClick={(e) => {
                e.preventDefault();
                removeAllImage();
              }}
            >
              Remove all images
            </button>
            <div className="d-flex flex-wrap flex-3 align-items-center justify-content-space-around">
              {imageUrl?.map((image, index) => (
                <div key={index} className="mx-2">
                  <img src={image} alt="" width="200" height="200" />
                  <div className="image-item__btn-wrapper my-2">
                    <button
                      disabled={loading && updateIndex === index}
                      className="my-2 btn"
                      style={{background: "#f88932", color: "white"}}
                      onClick={(e) => {
                        e.preventDefault();
                        onImageUpdate(index);
                        setUpdateIndex(index);
                      }}
                    >
                      {loading && updateIndex === index && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      Update
                    </button>
                    <button
                      className="my-2 mx-3 btn"
                      style={{background: "#e0e1e3"}}
                      onClick={(e) => {
                        e.preventDefault();
                        onRemoveImage(index);
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default Images;
