import { css } from "@emotion/css";

export const backgroundStyle = css`
  background: #ff830012;
  border-top-left-radius: 400%;
  border-top-right-radius: 50%;
`;
export const machineStyle = (isMobile) => css`
  .imgs {
    width: ${isMobile ? "80%" : "500px"};
    height: ${isMobile ? "100%" : "325px"};
    border-radius: ${isMobile ? "" : "10px"};
    margin: ${isMobile ? "" : "10px"};
    padding: ${isMobile ? "10px" : ""};
    margin-left: ${isMobile ? "42px" : ""};
  }
  .images-div {
    display: ${isMobile ? "" : "flex"};
    justify-content: ${isMobile ? "" : "space-around"};
    margin-top: ${isMobile ? "" : "10px"};
  }
  .div-text {
    text-align: ${isMobile ? "center" : "center"};
    font-size: ${isMobile ? "18px" : "18px"};
    margin-left: ${isMobile ? "90px" : "35%"};
    font-weight: 600;
  }
  .h4-div {
    font-weight: bold;
    margin-top: 100px;
    color: blueviolet;
    text-align: ${isMobile ? "center" : ""};
    font-size: ${isMobile ? "18px" : ""};
  }
  .mid-sell-button {
    background-color: #f88932;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border: none;
    border-radius: 25px;
    width: 140px;
    height: 55px;
    margin-left: ${isMobile ? "140px" : ""};
    margin-top: ${isMobile ? "10px" : "20px"};
  }
`;
export const MidPageStyle = (isMobile) => css`
  .mid-card-div {
    display: ${isMobile ? "grid" : "flex"};
    margin: ${isMobile ? "10px" : ""};
    padding: ${isMobile ? "5px" : "10px 145px"};
  }
  .mid-card-ele {
    margin: ${isMobile ? "10px" : "20px"};
    padding: ${isMobile ? "10px" : "20px"};
    width: ${isMobile ? "" : "40%"};
  }
  .mid-card-typo {
    color: #f88932;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
  }
  .mid-card-typotext {
    font-size: 13px;
    font-style: italic;
    color: #808080;
  }
  .mid-icon-img {
    height: 50px;
    margin-left: ${isMobile ? "100px" : "93px"};
  }
  .mid-view-img {
    height: 40px;
    margin-left: ${isMobile ? "140px" : "130px"};
    margin-bottom: 10px;
  }
  .mid-regis-icon {
    height: 40px;
    margin-left: ${isMobile ? "135px" : "125px"};
  }
`;
export const sitePosterStyle = (isMobile) => css`
  background: #0a58ca14;
  margin-top: ${isMobile ? "" : "75px"};
  width: ${isMobile ? "" : "50%"};
  height: ${isMobile ? "" : "200px"};
  padding: ${isMobile ? "10px" : "20px"};
  margin: ${isMobile ? "25px" : ""};
  margin-left: ${isMobile ? "" : "27%"};
  .site-typo-div {
    font-size: 16px;
    font-weight: 600;
    text-align: ${isMobile ? "" : "center"};
  }
  .site-typo-text {
    margin: 10px;
    font-size: 15px;
    text-align: ${isMobile ? "" : "center"};
  }
  .site-sells-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 50px;
    color: white;
    background: #f88932;
    border-color: #f88932;
    box-shadow: rgb(223 225 229) 0px 0px 4px;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    border-radius: 25px;
    margin-left: ${isMobile ? "70px" : "40%"};
    margin-top: ${isMobile ? "10px" : "25px"};
    margin-bottom: ${isMobile ? "" : "20px"};
  }
`;

export const midCardStyle = (isMobile) => css`
  margin: ${isMobile ? "30px 10px 30px 25px" : ""};
  padding: ${isMobile ? "5px" : ""};
  .heading-p {
    text-align: center;
    font-size: ${isMobile ? "" : "25px"};
    font-weight: bold;
  }
  .mid-sell-h2 {
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #f88932;
  }
  .mid-sell-h3 {
    text-align: center;
    font-size: 16px;
    padding: 5px;
  }
  .mid-sell-icon {
    display: block;
    align-items: center;
    width: ${isMobile ? "25%" : "80"};
    height: ${isMobile ? "35%" : "80px"};
    margin-top: 10px;
    margin-left: ${isMobile ? "125px" : "85px"};
  }
  .mid-sell-icons {
    display: block;
    align-items: center;
    width: ${isMobile ? "25%" : "80"};
    height: ${isMobile ? "35%" : "80px"};
    margin-top: 10px;
    margin-left: ${isMobile ? "110px" : "105px"};
  }
  .mid-four-cards {
    display: ${isMobile ? "grid" : "flex"};
    margin-left: ${isMobile ? "" : "75px"};
    margin-right: ${isMobile ? "" : "25px"};
    padding: 10px;
  }
  .mid-three-cards {
    display: ${isMobile ? "grid" : "flex"};
    margin-left: ${isMobile ? "" : "260px"};
    margin-right: ${isMobile ? "" : "25px"};
    padding: 10px;
  }
  .mid-cards {
    background: white;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    border: 1px solid gray;
    width: ${isMobile ? "" : "300px"};
    transition: width 2s, height 2s, transform 2s;
  }
`;
