import React from "react";
import { Button } from "react-bootstrap";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import Form from "react-validation/build/form";
import { ChevronleftThick } from "@ironlist/ironlist-icons";
import Images from "../product/Images";
import LocationSearchInput from "../product/LocationSearchInput";
const userTheme = [
  {
    label: "Classic Theme",
    value: "Theme1",
  },
  {
    label: "Blue Theme",
    value: "Theme2",
  },
];
function ManageWebsite({
  setStep,
  banners,
  setBanners,
  logoImage,
  setLogoImage,
  userWebsite,
  values,
  setValues,
  onWebsiteChange,
  updateSiteDataBySubDomain,
  setAdd,
  setLatLng,
  newLocation,
}) {
  return (
    <div className="manage-website">
      <h3 className="headerStyle">
        <ChevronleftThick
          onClick={() => {
            setStep(2);
          }}
          className="back-button"
        />
        Manage Website
      </h3>
      <div className="form-container">
        <h5>
          <strong>Select Website</strong>
        </h5>
        <select
          className="select-wrapper"
          onChange={(e) => onWebsiteChange(e.target.value)}
          value={values?.subdomain_name}
        >
          {userWebsite?.map((item) => (
            <option value={item?.value}>{item?.label}</option>
          ))}
        </select>
        <Form>
          <div className="separator-line" />
          <div className="about-section">
            <h5>
              <strong>Edit Information</strong>
            </h5>
            <p>About us</p>
            <Textarea
              type="text"
              name="about"
              value={values?.about_us}
              onChange={(e) =>
                setValues((prevState) => ({
                  ...prevState,
                  about_us: e.target.value,
                }))
              }
            />
          </div>
          <div className="form-group">
            <div className="about-section">
              <p>Company Name</p>
              <Input
                type="text"
                placeholder="Enter your company name"
                className="form-control"
                name="company_name"
                value={values?.company_name}
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    company_name: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="about-section">
              <p>Facebook</p>
              <Input
                type="text"
                placeholder="Enter your Facebook url"
                className="form-control"
                name="facebook"
                value={values?.facebook}
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    facebook: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="about-section">
              <p>Twitter</p>
              <Input
                type="text"
                placeholder="Enter your twitter url"
                className="form-control"
                name="twitter"
                value={values?.twitter}
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    twitter: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="about-section">
              <p>Linkedin</p>
              <Input
                type="text"
                placeholder="Enter your linkedin url"
                className="form-control"
                name="linkedin"
                value={values?.linkedin}
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    linkedin: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="about-section">
              <p>Instagram</p>
              <Input
                type="text"
                placeholder="Enter your company name"
                className="form-control"
                name="instagram"
                value={values?.instagram}
                onChange={(e) =>
                  setValues((prevState) => ({
                    ...prevState,
                    instagram: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className="location-input">
            <LocationSearchInput
              setAdd={setAdd}
              setLatLng={setLatLng}
              newLocation={newLocation}
            />
          </div>
          <div className="separator-line" />
          <h5>
            <strong>Change Theme</strong>
          </h5>
          <p>Select the theme to apply</p>
          <select
            className="select-wrapper"
            value={values.template_name}
            onChange={(e) =>
              setValues((prevState) => ({
                ...prevState,
                template_name: e.target.value,
              }))
            }
          >
            {userTheme?.map((item) => (
              <option value={item?.value}>{item?.label}</option>
            ))}
          </select>
          <div className="separator-line" />
        </Form>
        <h5>
          <strong>Add Banner</strong>
        </h5>
        <Images
          setImageUrl={setBanners}
          imageUrl={banners}
          pathName="SiteBanner"
        />
        <div className="separator-line" />
        <h5>
          <strong>Add Logo</strong>
        </h5>
        <Images
          setImageUrl={setLogoImage}
          imageUrl={logoImage}
          pathName="SiteLogo"
        />
      </div>
      <div className="save-button">
        <Button
          variant="light"
          className="save-publish"
          onClick={() => updateSiteDataBySubDomain()}
        >
          Save And Publish
        </Button>
      </div>
    </div>
  );
}
export default ManageWebsite;
