import { css } from "@emotion/css";

export const listingContainer = (isMobile) => css`
  width: ${isMobile ? "100%" : "993px"};
  ${isMobile
    ? `
    margin: 0 auto;
     padding-bottom: 100px;
     border-top: 1px solid #e0e1e3;`
    : ` margin-left: 20px;
        border: 1px solid #e0e1e3;
        border-radius: 4px;
        position: relative;
        margin-bottom: 100px;
        height: 740px;`};
  background-color: #ffffff;
  .ListingHeader {
    padding: ${isMobile ? "12px" : "24px"};
    border-bottom: 1px solid #e0e1e3;
    font-size: ${isMobile ? "16px" : "22px"};
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    margin-bottom: 0px;
  }
  .addClaim {
    width: 100%;
    ${isMobile
      ? ` position:sticky; box-shadow: 0 1px 4px 0 rgba(55, 70, 95, 0.12); padding: 8px;`
      : `position: absolute;border-top: 1px solid #e0e1e3;padding: 16px;`};
    bottom: 0px;
    background-color: #ffffff;
  }
  .ClaimButton {
    background-color: #f88932;
    color: white;
    ${isMobile ? `width:100%;` : `float: right;`};
  }
  .NoListing {
    padding: 16px;
    height: 500px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .description {
    width: ${isMobile ? "336px" : "550px"};
  }
`;
export const plansTableStyle = css`
  margin-bottom: 20px;
  table {
    width: 100%;
  }
  th {
    background-color: #fbfbfb;
    text-align: center;
    padding: 16px 24px;
    border-bottom: 1px solid #e0e1e3;
  }
  td {
    border-bottom: 1px solid #e0e1e3;
    text-align: center;
    padding: 8px 24px;
  }
  .item-image {
    border-radius: 4px;
  }
`;

export const MobileListingCard = css`
  width: 336px;
  margin: 16px 12px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #e0e1e3;
  background-color: white;
  display: flex;
  .card-title {
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .item-image {
    border-radius: 4px;
  }
`;
export const overlay = css`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
`;
export const popupContainer = (isMobile) => css`
  margin: 100px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: ${isMobile ? "336px" : "30%"};
  position: relative;
  transition: all 5s ease-in-out;
`;
