import React from "react";
import "./style.css";

const Invoice = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12">
          <div className="invoice-title">
            <h3 className="pull-right"></h3>
            <h1>Invoice</h1>
          </div>

          <hr />

          <div className="col-xs-6 d-flex justify-content-between">
            <h6 style={{ fontWeight: "bold" }}>FROM : </h6>
            <div className="detail-div">
              <text>Company Name: </text>
              <text>Email Address: </text>
              <text>Phone Number:</text>
              <text>Address: </text>
              <text>GST: </text>
            </div>
            <h6 style={{ fontWeight: "bold" }}>TO : </h6>
            <div className="detail-div">
              <text>Client Name:</text>
              <text>Client Email:</text>
              <text>Client Phone Number:</text>
              <text>Client Address:</text>
              <text>Client Pan Number:</text>
              <text>Org Name:</text>
              <text>Amount</text>
              <text>GST:</text>
            </div>
          </div>
          <div className="col-xs-6 text"></div>
        </div>
      </div>
      <div>
        <h6 style={{ fontWeight: "bold" }}>Trems : </h6>
        <text></text>
        <h6 style={{ fontWeight: "bold" }}>DUE : </h6>
        <text></text>
      </div>
      <div>
        <table className="table">
          <tr>
            <th className="th">Plan Description</th>
            <th className="th">Quantity</th>
            <th className="th">Price</th>
            <th className="th">Amount</th>
          </tr>
          <tr>
            <td className="td">1</td>
            <td className="td">1</td>
            <td className="td">1</td>
            <td className="td">1</td>
          </tr>
          <tr>
            <td
              className="td"
              align="right"
              colspan="3"
              style={{ fontSize: "20px", fontWeight: "400" }}
            >
              SubTotal
            </td>
            <td>subtotal</td>
          </tr>
          <tr>
            <td
              className="td"
              align="right"
              colspan="3"
              style={{ fontSize: "20px", fontWeight: "400" }}
            >
              Tax 18% GST
            </td>
            <td>Tax</td>
          </tr>
          <tr>
            <td
              className="td"
              align="right"
              colspan="3"
              style={{ fontSize: "22px", fontWeight: "bold" }}
            >
              Amount
            </td>
            <td>Balance Due</td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default Invoice;
