import { css } from "@emotion/css";

export const ModalStyle = css`
  .button_div {
    float: right;
  }
  .modal-div {
    width: 500px;
    border: none;
  }
  .texts {
    display: flex;
    justify-content: space-between;
  }
`;
