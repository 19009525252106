import React from "react";
import { spinnerContainer } from "./style";

export default function LoadingSpinner() {
  return (
    <div className={spinnerContainer}>
      <div className="loading-spinner"></div>
    </div>
  );
}
