import React, { useState, useCallback } from "react";
import {
  MenuThick,
  CloseThick,
  ChevronleftandroidThickCopy2,
} from "@ironlist/ironlist-icons";
import { mobileHeader } from "./style";
import { imageUrl } from "../../utils";
import { getSidebarTabs } from "../SidebarTabs/const";
import SideBarTab from "../SidebarTabs/SideBarTab";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../context/IsMobile";

const MobileHeader = ({ currentUser, logoutClick }) => {
  const isMobile = useIsMobile();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const path = window.location.pathname.slice(1).toUpperCase();
  let exactPath;
  if (path?.split("/")[0] === "LISTING") {
    exactPath = "LISTINGS";
  } else {
    exactPath = path?.split("/")[0];
  }
  const currentPath = getSidebarTabs.filter((tab) => tab.id === exactPath);
  const [value, setValue] = useState(currentPath[0] || getSidebarTabs[0]);
  const history = useHistory();
  const onChange = useCallback(
    (tab) => {
      setValue(tab);
      history.push(tab.link);
      setSidebarOpen((o) => !o);
    },
    [history]
  );
  const filterTabs =
    currentUser && currentUser.claimListing === false
      ? getSidebarTabs.filter((tab) => tab.id !== "CLAIMLISTINGS")
      : getSidebarTabs;
  const TABS = !isMobile
    ? filterTabs
    : filterTabs.filter((tab) => tab.id !== "MYWEBSITE");
  return (
    <div className={mobileHeader}>
      {currentUser && (
        <div className="mobile-header-left">
          <div
            onClick={() => setSidebarOpen((o) => !o)}
            className={`menu-icon-wrapper sidebar-icon `}
          >
            <MenuThick height={12} width={20} title="Menu" />
            {currentUser && (
              <h5 className="active-tabs">
                <strong> {value.label}</strong>
              </h5>
            )}
          </div>
        </div>
      )}
      {sidebarOpen && (
        <div className="sidebar-background">
          <div className="sidebar">
            <div className="mobile-sidebar-top">
              <img
                style={{ marginTop: "0" }}
                src={imageUrl("/images/ironlist-logo-2.webp")}
                alt="ironlist-logo"
                className="mobile-header-logo"
              />
              <div
                onClick={() => setSidebarOpen((o) => !o)}
                className="menu-icon-wrapper"
              >
                <CloseThick height={13} width={13} title="Close" />
              </div>
            </div>

            {currentUser && (
              <>
                <div className="sidebar-top-container">
                  <div className="initials-circle">{currentUser.name[0]}</div>
                  <div className="sidebar-name-container">
                    <h5 className="sidebar-hello-text">Hello,</h5>
                    <h5 className="mobile-user-location">{currentUser.name}</h5>
                  </div>
                </div>
                <div className="profile-section-divider" />
              </>
            )}
            {TABS.map((tab) => (
              <SideBarTab
                key={tab.id}
                tab={tab}
                isSelected={value.id === tab.id}
                onClick={onChange}
              />
            ))}

            {currentUser && (
              <>
                <div className="profile-section-divider" />
                <div
                  onClick={() => {
                    logoutClick();
                    setSidebarOpen((o) => !o);
                  }}
                  className="sidebar-item"
                >
                  <div className="menu-icon-wrapper logout-icon">
                    <ChevronleftandroidThickCopy2
                      height={24}
                      width={24}
                      title="Exit_Logout"
                      fill="#62656e"
                    />
                    <h5 className="sidebar-item-name">Logout</h5>
                  </div>
                </div>
                <div className="profile-section-divider" />
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
