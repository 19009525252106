import { css } from "@emotion/css";

export const imageStyle = (isMobile) => css`
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: ${isMobile ? "75px" : "92px"};
    height: ${isMobile ? "45px" : "75px"};
    border-radius: 0px;
  }
  .image-gallery-slide {
    width: 460px;
    border-radius: 6px;
    object-fit: cover;
  }
  .image-gallery-slide .image-gallery-image {
    width: 71%;
    height: 270px;
    object-fit: contain;
    margin-right: ${isMobile ? "150px" : "0px"};
    margin-bottom: ${isMobile ? "-35px" : "35px"};
    margin-top: ${isMobile ? "-40px" : ""};
  }
`;
