import React, { useState } from "react";
import { videoStyled } from "./VideoStyle";
import { useIsMobile } from "../../components/context/IsMobile";
import { Typography } from "@mui/material";

const VideoPage = () => {
  const isMobile = useIsMobile();
  const [show, setShow] = useState(true);
  const contents =
    "Ironlist Have now made easy for you to sell your used equipment and machinery with wide range of category choices, easy to sell interface and expert advice to get you the best price and deals for your item online.";
  const aboutMore = contents.toString().slice(0, 215);
  const toggle = () => {
    setShow(!show);
  };
  return (
    <div className={videoStyled(isMobile)}>
      <div className="video_text">
        <h3 className="h3-text">Sell Machine Online - IronList </h3>

        <h5 className="h5-text">One-step Solution To Sell your Equipment</h5>
        <text className="mid-text">{aboutMore}</text>
        <Typography onClick={toggle}>
          <a href="#about">
            <button className="more">
              {show ? "...read more" : "read less"}
            </button>
          </a>
        </Typography>
      </div>
      <div>
        <video
          className="videos"
          controls
          muted
          autoPlay={"autoplay"}
          preLoad="auto"
          loop
        >
          <source
            src="https://vendorportal1.s3.ap-south-1.amazonaws.com/HomePageVideo/Ironlist-video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default VideoPage;
