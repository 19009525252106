import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import authService from "./auth.service";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PhoneInput from "react-phone-input-2";
import "./style.css";
import { CountryDropdown } from "react-country-region-selector";
import { Checkbox } from "@mui/material";
import { orange } from "@mui/material/colors";
import GoogleLogin from "react-google-login";
import PendingInfo from "../../components/PendingInfo/PendingInfo.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { LinkedIn } from "react-linkedin-login-oauth2";
import {
  FacebookLoginIcon,
  LinkedinLoginIcon,
} from "../../components/Icons/index";
import FacebookLogin from "react-facebook-login";
import { ChevronleftThick, SmsThick } from "@ironlist/ironlist-icons";
import { VisibilityOff } from "@material-ui/icons";
import { Visibility } from "@mui/icons-material";
import { Link } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-danger" role="alert">
        Please Enter Your Valid Email.
      </div>
    );
  }
};

const firstName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The FirstName must be between 3 and 20 characters.
      </div>
    );
  }
};
const lastName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The LastName must be between 3 and 20 characters.
      </div>
    );
  }
};

const password = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeContactNumber = this.onChangeContactNumber.bind(this);
    this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
    this.onVendorChange = this.onVendorChange.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.LinkedInSuccess = this.LinkedInSuccess.bind(this);
    this.onSendOTP = this.onSendOTP.bind(this);
    this.onVerifyMobileOTP = this.onVerifyMobileOTP.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      companyName: "",
      contactNumber: "",
      dialCode: "",
      vendorType: "agent",
      email: "",
      password: "",
      country: "",
      successful: false,
      message: "",
      isChecked: true,
      remail: "",
      show: JSON.parse(localStorage.getItem("user"))?.pendingInfo || false,
      otpInput: false,
      otp: "",
      key: "",
      verifyOTPSuccess: true,
      emailSignUp: false,
      isValidContact: false,
      showPassword: false,
    };
  }

  onChangeFirstName(e) {
    this.setState({
      firstName: e.target.value,
    });
  }
  onChangeLastName(e) {
    this.setState({
      lastName: e.target.value,
    });
  }
  onChangeCompanyName(e) {
    this.setState({
      companyName: e.target.value,
    });
  }
  onChangeContactNumber(value, data, event, formattedValue) {
    this.setState({
      dialCode: data.dialCode,
      contactNumber: value.slice(data.dialCode.length),
      isValidContact: true,
    });
  }
  onVendorChange(e) {
    this.setState({
      vendorType: e.target.value,
    });
  }
  onChangeCountry(e) {
    this.setState({ country: e });
  }
  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleClickShowPassword = () => {
    this.setState({
      ...this.state.password,
      showPassword: !this.state.showPassword,
    });
  };
  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();
    if (!this.state.contactNumber) {
      this.setState({ isValidContact: true });
    }
    if (!this.state.verifyOTPSuccess) {
      toast.error(`Please Verify Your Mobile Number!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    if (
      this.checkBtn.context._errors.length === 0 &&
      this.state.contactNumber
    ) {
      const body = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        dialCode: this.state.dialCode,
        phone: this.state.contactNumber,
        companyName: this.state.companyName,
        vendorType: this.state.vendorType,
        email: this.state.email,
        password: this.state.password,
        country: this.state.country,
        isVendor: 1,
      };
      authService.register(body).then(
        (response) => {
          if (response?.success) {
            this.setState({
              message: response.data.message,
              successful: true,
            });
          } else {
            this.setState({
              message: response.data.message,
              successful: false,
            });
          }
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }
  onSendOTP(e) {
    e.preventDefault();
    authService
      .sendOTP({
        phone: this.state.contactNumber,
        countryCode: this.state.dialCode,
        phoneNo: this.state.dialCode.concat(this.state.contactNumber),
      })
      .then((response) => {
        if (response.data.success) {
          this.setState({ otpInput: true, key: response.data.key });
          toast.success(`OTP Send Successfully !`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  }
  onVerifyMobileOTP(e) {
    e.preventDefault();
    authService
      .verifyMobileOTP({
        phoneNo: this.state.dialCode.concat(this.state.contactNumber),
        otp: this.state.otp,
        key: this.state.key,
      })
      .then((response) => {
        if (response.data.success) {
          this.setState({ verifyOTPSuccess: true, otpInput: false });
          toast.success(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        if (!response.data.success) {
          toast.error(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  }
  responseGoogle(response) {
    const options = {
      url: process.env.REACT_APP_API_URL + "/vregisterGoogle",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: { tokenId: response.tokenId, isVendor: 1, agreeTerms: true },
    };
    axios(options).then((value) => {
      localStorage.setItem("user", JSON.stringify(value.data));
      if (value.data.pendingInfo === false) {
        this.props.history.push("/listings");
        window.location.reload();
      }
      if (value.data.pendingInfo) {
        this.setState({ show: true, remail: value.data.email });
      }
    });
  }
  responseGoogleFailure(response) {
    toast.warn(`Something went wrong please try again later`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  LinkedInSuccess = (code) => {
    authService
      .linkedinSignIn({ code, isVendor: 1, agreeTerms: true })
      .then((value) => {
        localStorage.setItem("user", JSON.stringify(value.data));
        if (value.data.pendingInfo === false) {
          this.props.history.push("/listings");
          window.location.reload();
        }
        if (value.data.pendingInfo) {
          this.setState({ show: true, remail: value.data.email });
        }
      });
  };

  responseFacebook = (response) => {
    authService
      .facebookSignIn({
        accessToken: response.accessToken,
        isVendor: 1,
        agreeTerms: true,
      })
      .then((value) => {
        localStorage.setItem("user", JSON.stringify(value.data));
        if (value.data.pendingInfo === false) {
          this.props.history.push("/listings");
          window.location.reload();
        }
        if (value.data.pendingInfo) {
          this.setState({ show: true, remail: value.data.email });
        }
      });
  };
  render() {
    return (
      <div
        className="bg-white"
        style={{
          borderRadius: "4px",
          padding: "20px",
          width: this.props.isMobile ? "360px" : "474px",
          height: this.props.isMobile ? "" : "765px",
        }}
      >
        <h3 className="signupHeader">
          {this.state.emailSignUp && (
            <ChevronleftThick
              className="leftThick"
              onClick={() =>
                this.setState({
                  emailSignUp: false,
                })
              }
              title="Chevron left"
            />
          )}
          <strong>Sign Up</strong>
        </h3>
        {this.state.emailSignUp ? (
          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group mt-3">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="firstName">First Name</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.onChangeFirstName}
                        validations={[required, firstName]}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="lastName">Last Name</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.onChangeLastName}
                        validations={[required, lastName]}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="contactNumber">Contact Number</label>
                  <PhoneInput
                    specialLabel=""
                    country={"us"}
                    onChange={this.onChangeContactNumber}
                    validations={[required(this.state.contactNumber)]}
                    // inputProps={{disabled: !!this.state.verifyOTPSuccess}}
                    containerStyle={{
                      border: "none",
                      marginTop: "4px",
                      height: "48px",
                    }}
                    inputStyle={{
                      backgroundColor: "#f4f4f5",
                      border: "1px solid #f4f4f5",
                      height: "48px",
                      width: this.isMobile ? "336px" : "330px",
                      "&:focus": {
                        borderBottom: "1px solid black",
                      },
                    }}
                  />
                  {!!this.state.isValidContact &&
                    required(this.state.contactNumber)}
                  {!this.state.otpInput && !this.state.verifyOTPSuccess && (
                    // <h6
                    //   style={{
                    //     color: "#f88932",
                    //     fontWeight: "bold",
                    //     cursor: "pointer",
                    //     float: "right",
                    //     marginTop: "5px",
                    //   }}
                    //   onClick={this.onSendOTP}
                    // >
                    //   Send OTP
                    // </h6>
                    <></>
                  )}
                  {!this.state.otpInput && this.state.verifyOTPSuccess && (
                    // <div
                    //   className="d-flex align-items-center"
                    //   style={{
                    //     float: "right",
                    //     marginTop: "5px",
                    //   }}
                    // >
                    //   <Checkbox
                    //     checked
                    //     inputProps={{
                    //       "aria-label": "controlled",
                    //       padding: "0px",
                    //     }}
                    //     sx={{
                    //       color: "#f88932",
                    //       "&.Mui-checked": {
                    //         color: "#f88932",
                    //       },
                    //       padding: "0px",
                    //     }}
                    //   />
                    //   <h6
                    //     style={{
                    //       marginBottom: "0px",
                    //       marginLeft: "4px",
                    //       color: "#f88932",
                    //       fontWeight: "bold",
                    //     }}
                    //   >
                    //     Verified
                    //   </h6>
                    // </div>
                    <></>
                  )}
                  {this.state.otpInput && (
                    <div className="form-group mt-3">
                      <h5>Enter OTP</h5>
                      <OtpInput
                        autoComplete="off"
                        value={this.state.otp}
                        onChange={(value) => {
                          this.setState({ otp: value });
                        }}
                        numInputs={6}
                        separator={" "}
                        placeholder="0"
                        inputStyle={{
                          width: "2rem",
                          height: "2rem",
                          marginRight: "6px",
                          fontSize: "1rem",
                          borderRadius: 4,
                          border: "1px solid grey",
                        }}
                      />
                      <div className="d-flex">
                        <h6
                          className="mt-3"
                          style={{
                            color: "#f88932",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={this.onSendOTP}
                        >
                          Resend OTP
                        </h6>
                        <h6
                          className="mt-3"
                          style={{
                            color: "#f88932",
                            fontWeight: "bold",
                            cursor: "pointer",
                            marginLeft: "16px",
                          }}
                          onClick={this.onVerifyMobileOTP}
                        >
                          Verify OTP
                        </h6>
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-group mt-3" style={{ clear: "both" }}>
                  <div className="row">
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: "11px" }}
                    >
                      <label htmlFor="vendorType">I am</label>
                      <RadioGroup
                        row
                        aria-label="vendorType"
                        name="row-radio-buttons-group"
                        value={this.state.vendorType}
                        onChange={this.onVendorChange}
                      >
                        <FormControlLabel
                          value="agent"
                          control={
                            <Radio
                              sx={{
                                color: orange[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                              }}
                            />
                          }
                          label="Agent"
                          className="ml-3"
                        />
                        <FormControlLabel
                          value="dealer"
                          control={
                            <Radio
                              sx={{
                                color: orange[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                              }}
                            />
                          }
                          label="Dealer"
                        />
                        <FormControlLabel
                          value="vendor"
                          control={
                            <Radio
                              sx={{
                                color: orange[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                              }}
                            />
                          }
                          label="Vendor"
                        />
                        <FormControlLabel
                          value="manufacturer"
                          control={
                            <Radio
                              sx={{
                                color: orange[800],
                                "&.Mui-checked": {
                                  color: orange[800],
                                },
                              }}
                            />
                          }
                          label="Manufacturer"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <div className="row">
                    <div className="col">
                      <label htmlFor="email">Email</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        validations={[required, email]}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="companyName">Company Name</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="companyName"
                        value={this.state.companyName}
                        onChange={this.onChangeCompanyName}
                        validations={[required]}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="password">Password</label>
                  <Input
                    type={this.state.showPassword ? "text" : "password"}
                    className="form-control"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, password]}
                  />{" "}
                  {this.state.showPassword ? (
                    <Visibility
                      onClick={this.handleClickShowPassword}
                      style={{
                        marginTop: "-75px",
                        color: "gray",
                        marginLeft: "90%",
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={this.handleClickShowPassword}
                      style={{
                        marginTop: "-75px",
                        color: "gray",
                        marginLeft: "90%",
                      }}
                    />
                  )}
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="country">Country</label>
                  <CountryDropdown
                    style={{
                      width: "100%",
                      height: "38px",
                      borderRadius: "4px",
                    }}
                    value={this.state.country}
                    onChange={this.onChangeCountry}
                    validations={[required]}
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="d-flex align-items-center">
                    <Checkbox
                      checked={this.state.isChecked}
                      onChange={(e) => {
                        this.setState({ isChecked: e.target.checked });
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        color: "#f88932",
                        "&.Mui-checked": {
                          color: "#f88932",
                        },
                        padding: "0px",
                      }}
                    />
                    <h6 style={{ marginBottom: "0px", marginLeft: "4px" }}>
                      I agree to IronList{" "}
                      <a
                        href="https://ironlist.com/term-of-use"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#f88932" }}
                      >
                        Terms of use & Privacy Policy.
                      </a>
                    </h6>
                  </div>
                </div>
                <div className="">
                  <button
                    className="btn "
                    style={{
                      background: "#f88932",
                      color: "white",
                      marginTop: "10px",
                      marginBottom: "10px",
                      width: "160px",
                    }}
                    disabled={!this.state.isChecked}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
        ) : (
          <>
            <div
              className="GoogleSignup"
              style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
            >
              <GoogleLogin
                clientId="985398972528-kh601re0qhje07jhgfr1rpm1fd7boub4.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                buttonText="Sign Up with google"
                onSuccess={this.responseGoogle}
                onFailure={this.responseGoogleFailure}
                className="my-3"
                disabled={!this.state.isChecked}
              />
            </div>
            <div className="linkedInSignUp my-3">
              <LinkedIn
                clientId="77i11fj4mstd2n"
                redirectUri={`${window.location.origin}/linkedin`}
                onSuccess={(code) => this.LinkedInSuccess(code)}
                scope="r_liteprofile,r_emailaddress,w_member_social"
                onError={(error) => {
                  console.log(error);
                }}
              >
                {({ linkedInLogin }) => (
                  <div
                    className="LinkedInButton"
                    onClick={this.state.isChecked && linkedInLogin}
                    style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
                  >
                    <LinkedinLoginIcon className="LinkedInIcon" />
                    <span className="facebookText">Sign Up with LinkedIn</span>
                  </div>
                )}
              </LinkedIn>
            </div>
            <div
              className="facebookSignIn my-3"
              style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
            >
              <FacebookLogin
                cssClass="LinkedInButton"
                appId="270638241929286"
                icon={<FacebookLoginIcon className="facebookIcon" />}
                textButton={
                  <span className="facebookText">Sign Up with Facebook</span>
                }
                callback={this.responseFacebook}
                isDisabled={!this.state.isChecked}
              />
            </div>
            <div className="linkedInSignUp my-3">
              <div
                onClick={() =>
                  this.setState({
                    emailSignUp: this.state.isChecked,
                  })
                }
                style={{ opacity: this.state.isChecked ? 1 : 0.5 }}
              >
                <SmsThick
                  width={28}
                  height={28}
                  fill="#62656e"
                  className="EmailIcon"
                />
                <span className="facebookText">Sign Up With Email</span>
              </div>
            </div>
            <div className="form-group my-3">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={this.state.isChecked}
                  onChange={(e) => {
                    this.setState({ isChecked: e.target.checked });
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    color: "#f88932",
                    "&.Mui-checked": {
                      color: "#f88932",
                    },
                    padding: "0px",
                  }}
                />
                <h6 style={{ marginBottom: "0px", marginLeft: "4px" }}>
                  I agree to IronList{" "}
                  <a
                    href="https://ironlist.com/term-of-use"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#f88932" }}
                  >
                    Terms of use & Privacy Policy.
                  </a>
                </h6>
              </div>
            </div>
          </>
        )}

        {this.state.show && (
          <PendingInfo show={this.state.show} email={this.state.email} />
        )}
        <h6>
          Already have an account?
          <strong
            className="mx-1"

            // onClick={() => {
            //   this.props.setIsLogin(true);
            // }}
          >
            <Link
              style={{
                color: "#f88932",
                cursor: "pointer",
                textDecoration: "none",
              }}
              to="/login"
            >
              {" "}
              Sign In
            </Link>
          </strong>
        </h6>
      </div>
    );
  }
}
