import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import "./App.css";
// import EventBus from "./common/EventBus";
import SidebarTabs from "./components/SidebarTabs";
import Home from "./pages/home/Home";
import Product from "./pages/product/Product";
import authService from "./pages/Authentication/auth.service";
import Profile from "./pages/Profile/Profile";
import EmailVerification from "./pages/Authentication/EmailVerification";
import Plans from "./pages/Plans/Plans";
import StripePaymentSuccessSend from "./pages/Plans/stripePaymentSuccessSend";
import StripePaymentCancel from "./pages/Plans/StripePaymentCancel";
// import Footer from "./components/Footer/Footer";
import Leads from "./pages/Leads";
import Listings from "./pages/Listings";
// import SubdomainPlans from "./pages/SubDomainPlans/Plans";
import DashBoard from "./pages/DashBoard";
import LinkedInPage from "./pages/LinkedIn";
import Navbar from "./components/Navbar";
import ClaimListings from "./pages/ClaimListings";
import Loader from "./components/LoaderWrapper/Loader";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import MyWebsite from "./pages/MyWebsite";
import InviteUser from "./pages/InviteUser";
import SubdomainPaymentSuccess from "./pages/SubDomainPlans/subdomainPaymentSuccess";
import StartSelling from "./pages/StartSelling/StartSelling";
import Login from "./pages/Authentication/Login";
import Billing from "./pages/StartSelling/Billing";
import CompanyInfo from "./pages/StartSelling/CompanyInfo";
import Register from "./pages/Authentication/Register";
import ForgotPassword from "./pages/Authentication/ForgotPassword";
import Excavator from "./pages/Sitemap/Excavator";
import Compector from "./pages/Sitemap/Compector";
import Dozer from "./pages/Sitemap/Dozer";
import DumpTruck from "./pages/Sitemap/DumpTruck";
import LoaderBackhoe from "./pages/Sitemap/LoaderBackhoe";
import About from "./pages/About/About";
import ContactUs from "./pages/ContactUs/ContactUs";
import Tractor from "./pages/Sitemap/Tractor";
import Truck from "./pages/Sitemap/Truck";

Sentry.init({
  dsn: "https://fb753b823b3544c7835bfd8aec6ca1bc@o1255190.ingest.sentry.io/6423573",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = authService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
      });
    }
    // EventBus.on("logout", () => {
    //   this.logOut();
    // });
    var hours = 24 * 7; // to clear the localStorage after 24*7 means one week
    var now = new Date().getTime() / 1000;
    var setupTimeForLogin = localStorage.getItem("setupTimeForLogin");
    if (setupTimeForLogin) {
      if (parseInt(now) - setupTimeForLogin > hours * 60 * 60) {
        localStorage.clear();
        window.location.reload();
      }
    }
    var setupTimeForLocation = localStorage.getItem("setupTimeForLocation");
    if (setupTimeForLocation) {
      if (parseInt(now) - setupTimeForLocation > 24 * 60 * 60) {
        localStorage.removeItem("setupTimeForLocation");
        window.location.reload();
        localStorage.removeItem("CurrentLocation");
      }
    }
  }
  componentWillMount() {
    function UserCurrentLocation() {
      fetch("https://ipapi.co/json")
        .then((response) => {
          response.json().then((jsonData) => {
            let user = localStorage.getItem("user");
            const { country_name, ip, region, city } = jsonData;
            localStorage.setItem("CurrentLocation", country_name);
            if (user) {
              user = JSON.parse(user);
              let { id: userId } = user;
              let isSetUserId = false;
              for (let item of window?.dataLayer) {
                if (
                  typeof item === "object" &&
                  item.event === "fetchUserDetails"
                ) {
                  isSetUserId = true;
                }
              }
              if (userId && !isSetUserId) {
                window.dataLayer.push({
                  event: "fetchUserId",
                  userId: userId,
                  userName: user.name,
                  companyName: user.companyName,
                  ip: ip,
                  Address: `${city || ""}, ${region || ""}, ${
                    country_name || ""
                  }`,
                  loginDate: new Date().toISOString(),
                });
              }
            }
          });
        })
        .catch(() => {
          localStorage.setItem("CurrentLocation", "");
          let user = localStorage.getItem("user");
          if (user) {
            let userId = localStorage.getItem("customerId");
            let isSetUserId = false;
            for (let item of window?.dataLayer) {
              if (
                typeof item === "object" &&
                item.event === "fetchUserDetails"
              ) {
                isSetUserId = true;
              }
            }
            if (userId && !isSetUserId) {
              window.dataLayer.push({
                event: "fetchUserId",
                userId: userId,
                userName: user?.name,
                ip: "",
                Address: "",
              });
            }
          }
        });
      var now = new Date().getTime() / 1000;
      localStorage.setItem("setupTimeForLocation", parseInt(now));
    }
    // if (!localStorage.getItem("CurrentLocation")) {
    UserCurrentLocation();
    // }
  }
  //   componentWillUnmount() {
  //     EventBus.remove("logout");
  //   }

  logOut() {
    authService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;
    return (
      <Router>
        <div>
          <Navbar currentUser={currentUser} logoutClick={this.logOut} />
          <Switch>
            <Route exact path={["/", "/home"]} component={Home} />
          </Switch>
          <Switch>
            <Route exact path="/invite/:inviteUrl" component={InviteUser} />
          </Switch>
          <div className="container-fluid">
            {currentUser &&
              window.location.pathname !== "/" &&
              window.location.pathname !== "/billing" &&
              window.location.pathname !== "/companyInfo" && (
                // window.location.pathname !== "/sell-excavator-online" &&
                <SidebarTabs currentUser={currentUser} />
              )}
            <Switch>
              <Route
                exact
                path="/email-verification/:token"
                component={EmailVerification}
              />
              <Route exact path="/linkedin" component={LinkedInPage} />
              <Route exact path="/dashboard" component={DashBoard} />
              <Route exact path="/myProfile" component={Profile} />
              <Route exact path="/leads" component={Leads} />
              {!currentUser ? (
                <Route exact path="/startSelling" component={StartSelling} />
              ) : (
                <Route
                  exact
                  path="/listings"
                  component={currentUser ? Listings : Loader}
                />
              )}
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/forgotPassword" component={ForgotPassword} />
              <Route exact path="/billing" component={Billing} />
              <Route exact path="/companyInfo" component={CompanyInfo} />
              <Route
                exact
                path="/listings"
                component={currentUser ? Listings : Loader}
              />
              <Route
                exact
                path="/listing/new"
                render={(currentUser) => <Product user={currentUser} />}
              />
              <Route exact path="/listing/:productId" component={Product} />
              <Route exact path="/plans" component={Plans} />
              <Route exact path="/about" component={About} />
              <Route exact path="/contactUs" component={ContactUs} />
              <Route
                exact
                path="/sell-excavator-online"
                component={Excavator}
              />
              <Route
                exact
                path="/sell-compactor-online"
                component={Compector}
              />
              <Route exact path="/sell-dozer-online" component={Dozer} />
              <Route
                exact
                path="/sell-dumpTruck-online"
                component={DumpTruck}
              />
              <Route
                exact
                path="/sell-loaderBackhoe-online"
                component={LoaderBackhoe}
              />
              <Route exact path="/sell-tractor-online" component={Tractor} />
              <Route exact path="/sell-truck-online" component={Truck} />
              {/* <Route exact path="/websitePlans" component={SubdomainPlans} /> */}
              <Route exact path="/claimListings" component={ClaimListings} />
              <Route exact path="/myWebsite" component={MyWebsite} />
              <Route
                exact
                path="/stripepaymentsuccesssend/:id"
                component={StripePaymentSuccessSend}
              />
              <Route
                exact
                path="/subdomainPaymentSuccess/:id"
                component={SubdomainPaymentSuccess}
              />
              <Route
                exact
                path="/StripePaymentCancel"
                component={StripePaymentCancel}
              />
            </Switch>
          </div>
          {/* {currentUser && <Footer />} */}
        </div>
      </Router>
    );
  }
}

export default App;
