import {useIsMobile} from "../context/IsMobile";
import {plansCard} from "./style";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

export default function PremiumCard({item, buyPlan, subDomainPlan}) {
  const isMobile = useIsMobile();
  return (
    <div className={plansCard(isMobile)}>
      <div className="container">
        <div class="columns">
          <div
            className={
              (item?.plan_name === "Basic" && "header") ||
              (item?.plan_name === "Business" && "header-second") ||
              (item?.plan_name === "Business+" && "header-last")
            }
          >
            <h5>{item?.plan_name}</h5>
          </div>

          <ul className="price">
            <li className="amount">{item.amounts}/-</li>
            <li>
              <div
                className="plansButton"
                onClick={buyPlan}
                data-planname={item.plan_name}
              >
                <h6
                  style={{
                    color: "#f88932",
                    fontSize: "14px",
                    marginBottom: 0,
                  }}
                >
                  <strong>Buy Now</strong>
                </h6>
              </div>
            </li>
            <li> Items • {item?.no_of_listings}</li>
            <li> {`${item?.lead_info} Lead Info`}</li>
            <li>{`${item?.dashboard_report} Dashboard Report`}</li>
            <li>{`${item?.user_access_limit} Users Access`}</li>
            <li>Call & Email Support</li>
            <li> Item Validity • {item?.listing_validity_days} Days</li>
            <li> Plan Validity • {item?.plan_validity_months} Months</li>
            <li>
              <div className="plan-icon-div">
                {item?.promotional_ads ? (
                  <DoneIcon style={{fill: "green"}} />
                ) : (
                  <CloseIcon className="close-icon" />
                )}
                <h6 className={item?.promotional_ads ? "" : "text-dis"}>
                  {" "}
                  Promotional Ads/Premium Listings
                </h6>
              </div>
            </li>
            <li>
              <div className="plan-icon-div">
                {item?.promotional_ads ? (
                  <DoneIcon style={{fill: "green"}} />
                ) : (
                  <CloseIcon className="close-icon" />
                )}
                <h6 className={item?.promotional_ads ? "" : "text-dis"}>
                  Similar Buyer Inquiries
                </h6>
              </div>
            </li>
            <li>
              <div className="plan-icon-div">
                {item?.website_store ? (
                  <DoneIcon style={{fill: "green"}} />
                ) : (
                  <CloseIcon className="close-icon" />
                )}
                <h6 className={item?.website_store ? "" : "text-dis"}>
                  Website Store
                </h6>
              </div>
            </li>
            <li>
              {" "}
              <div className="plan-icon-div">
                {item?.website_store_promotion ? (
                  <DoneIcon style={{fill: "green"}} />
                ) : (
                  <CloseIcon className="close-icon" />
                )}
                <h6 className={item?.website_store_promotion ? "" : "text-dis"}>
                  Website Store Promotion
                </h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
