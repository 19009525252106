import { Card } from "@mui/material";
import React from "react";
import { useIsMobile } from "../../components/context/IsMobile";
import { contactStyle } from "./style";

const ContactUs = () => {
  const isMobile = useIsMobile();
  return (
    <div className={contactStyle(isMobile)}>
      <div className="section-div">
        <div className="card-div">
          <div className="form-div">
            <div className="inputs-div">
              <h1 className="contact-header">Contact Us</h1>
              <div className="form-input ">
                <form className="form-class">
                  <input placeholder="FullName" className="input" />
                  <input placeholder="Email" className="input" />
                  <input placeholder="Adress" className="input" />
                  <input placeholder="Org Name" className="input" />
                  <input placeholder="Vendor Type" className="input" />
                  <textarea placeholder="Message" className="input" />
                  <button className="submit-div">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="second-card-div">
        <Card className="cards">
          <img
            className="location-icon"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Niiz7nPIegIzujxTVD7MEWiZrDhfUhSpfg&usqp=CAU"
            alt=""
          />
          <h5 className="h5">Our Office Location</h5>
          <p className="texts">
            B 806 Mondeal Heights Iscon Circle, Sarkhej – Gandhinagar Hwy,
            Ahmedabad, Gujarat-380015
          </p>
        </Card>
        <Card>
          <img
            className="contact-icon"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRaZ5IqGOGPSaX2GjKs_0gs83Im_JcDIV6cgg&usqp=CAU"
            alt=""
          />

          <h5 className="h5">Phone Number</h5>
          <p className="texts">9724312409</p>
        </Card>
        <Card>
          <img
            className="email-icon"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3dVrqoIKKUwcEQxaijmeMIKcjPanIrbsFPsi3Jej0Rkm22KxBO0xWnIeCO7IB2YhRI5s&usqp=CAU"
            alt=""
          />

          <h5 className="h5">Email</h5>
          <p className="texts">ironlist@ironlist.com</p>
        </Card>
      </div>
    </div>
  );
};

export default ContactUs;
