import { VisibilityOff } from "@material-ui/icons";
import { Visibility } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import { orange } from "@mui/material/colors";
import axios from "axios";
import React, { useMemo, useState, useCallback } from "react";
import GoogleLogin from "react-google-login";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import LocationSearchInput from "../product/LocationSearchInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sellingStyle } from "./style";
import { sendOtp, verifyMobileOTP } from "../Authentication/OtpVerification";
import { vRegistration } from "../Authentication/Vregistration";
import ValidationError from "../../shared/ValidationError";

const StartSelling = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const [isValidFirstName, setValidFirstName] = useState(true);
  const [isValidLastName, setValidLastName] = useState(true);
  const [isValidCompanyName, setValidCompanyName] = useState(true);
  const [isValidPassword, setValidPassword] = useState(true);
  const [isValidPhoneNumber, setValidPhoneNumber] = useState(true);
  const [isValidEmail, setValidEmail] = useState(true);
  const [googleVerifiedEmail, setGoogleVerifiedEmail] = useState("");

  const [vendorType, setVendorType] = useState("agent");
  const [isChecked] = useState(false);
  const [dialCode, setDialCode] = useState("");
  const [key, setKey] = useState("");
  const [otp, setOtp] = useState("");
  const [otpInput, setOtpInput] = useState(false);
  const [isOTPVerify, setVerifyOTPSuccess] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [add, setAdd] = useState();
  const [latLng, setLatLng] = useState();
  const [locationField, setLocationField] = useState({
    city: "",
    state: "",
    country: "",
  });
  const [isValidCountry, setValidCountry] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const location = [];
  const newLocation = location.join();
  const country = locationField.country;
  // console.log(country);
  const isValidMailCheck = useCallback((value) => {
    setGoogleVerifiedEmail("");
    setEmail(value);
    const pattern =
      /[a-zA-Z0-9]+[.]?([a-zA-Z0-9]+)?[@][a-z]{3,9}[.][a-z]{2,5}/g;
    const result = pattern.test(value);
    setValidEmail(!!result);
  }, []);

  // if (locationField.city) {
  //   location.push(locationField.city);
  // }
  // if (locationField.state) {
  //   location.push(locationField.state);
  // }
  // if (locationField.country) {
  //   location.push(locationField.country);
  // }

  useMemo(() => {
    const add_split = add ? add.split(",") : "";

    setLocationField((prevState) => ({
      ...prevState,
      country: (add_split[add_split.length - 1] ?? "").trim(),
      state: (add_split[add_split.length - 2] ?? "").trim(),
      city: (add_split[add_split.length - 3] ?? "").trim(),
    }));
    if (add_split[add_split.length - 1] ?? "".trim()) {
      setValidCountry(true);
    }
  }, [add]);
  useMemo(() => {
    if (latLng !== undefined) {
      if (latLng.latLng !== undefined) {
        setLocationField((prevState) => ({
          ...prevState,
          i_lat: latLng.latLng.lat ?? "",
          i_long: latLng.latLng.lng ?? "",
        }));
      }
    }
  }, [latLng]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!firstName) setValidFirstName(false);
    if (!lastname) setValidLastName(false);
    if (!companyName) setValidCompanyName(false);
    if (!password) setValidPassword(false);
    if (!isOTPVerify || !phoneNumber) setValidPhoneNumber(false);
    if (!isValidEmail || !email) setValidEmail(false);
    if (!locationField.country) setValidCountry(false);
    if (
      firstName &&
      lastname &&
      password &&
      isOTPVerify &&
      phoneNumber &&
      isValidEmail &&
      email &&
      companyName &&
      locationField.country
    ) {
      let data = {
        firstName,
        lastname,
        email,
        dialCode,
        phone,
        password,
        companyName,
        vendorType,
        country,
        ...(googleVerifiedEmail && {
          verifiedGoogleToken: googleVerifiedEmail,
        }),
      };
      vRegistration(data).then((response) => {
        if (response.success) {
          localStorage.setItem("user", JSON.stringify(response));
          history.push("/billing");
        }
        if (!response?.success) {
          toast.error(`${response?.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
    }
  };
  const onChangePassword = (event) => {
    setValidPassword(true);
    setPassword(event.target.value);
  };
  const responseGoogle = (response) => {
    const options = {
      url: process.env.REACT_APP_API_URL + "/vregisterGoogle",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: { tokenId: response.tokenId, sellerStep: true },
    };
    axios(options).then((value) => {
      setEmail(value.data.email);
      setGoogleVerifiedEmail(value.data.googleVerifiedEmail);
    });
  };
  const responseGoogleFailure = (response) => {
    toast.warn(`Something went wrong please try again later`, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const onChangeContactNumber = (value, data) => {
    setPhoneNumber((prevState) => ({
      ...prevState,
      phone_no: value?.slice(data.dialCode.length),
      dial_code: data?.dialCode,
    }));
    setDialCode(data.dialCode);
    setPhone(value.slice(data.dialCode.length));
    setPhoneNumber(`+${value}`);
  };
  const onSendOTP = () => {
    sendOtp({
      phone,
      countryCode: dialCode,
      phoneNo: `+${dialCode.concat(phone)}`,
    }).then((response) => {
      if (response?.success) {
        setOtpInput(true);
        setKey(response?.key);
        toast.success(`OTP Send Successfully !`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (!response?.success) {
        toast.error(`${response?.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };

  const onVerifyMobileOTP = () => {
    verifyMobileOTP({
      phoneNo: `+${dialCode.concat(phone)}`,
      otp,
      key,
    }).then((response) => {
      if (response?.success) {
        setVerifyOTPSuccess(true);
        setValidPhoneNumber(true);
        setOtpInput(false);
        setVerifyOTP(true);
        toast.success(`${response?.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (!response?.success) {
        toast.error(`${response?.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  return (
    <>
      <ToastContainer />
      <div className={sellingStyle(isMobile)}>
        <div className="card-sell">
          <div className="div1-div">
            <Typography className="heading-text"> Email & Phone </Typography>
            <div className="mid-sell">
              <div>
                <TextField
                  required
                  label="FirstName"
                  value={firstName}
                  onChange={(e) => {
                    setValidFirstName(true);
                    setFirstName(e.target.value);
                  }}
                  variant="outlined"
                  className="field-input"
                />
                <br />
                <ValidationError
                  show={!isValidFirstName}
                  message="FirstName is required"
                />
              </div>
              <div>
                <TextField
                  required
                  label="LastName"
                  value={lastname}
                  onChange={(e, field) => {
                    setValidLastName(true);
                    setLastname(e.target.value);
                  }}
                  variant="outlined"
                  className="field-input"
                />
                <ValidationError
                  show={!isValidLastName}
                  message="LastName is required"
                />
              </div>
            </div>
            <div className="mid-sell">
              <div>
                <TextField
                  required
                  label="Org Name"
                  value={companyName}
                  onChange={(e) => {
                    setValidCompanyName(true);
                    setCompanyName(e.target.value);
                  }}
                  variant="outlined"
                  className="field-input"
                />
                <ValidationError
                  show={!isValidCompanyName}
                  message="Organization Name is required"
                />{" "}
              </div>

              <label
                style={{
                  marginTop: "-10px",
                  marginLeft: "12px",
                  color: "#212529c4",
                }}
              >
                Password
              </label>
              <Form>
                <div className="form-group mt-3">
                  <Input
                    required
                    type={showPassword ? "text" : "password"}
                    className="form-control pass-input"
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                  />{" "}
                  {showPassword ? (
                    <Visibility
                      onClick={handleClickShowPassword}
                      style={{
                        marginTop: "-75px",
                        color: "gray",
                        marginLeft: "80%",
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={handleClickShowPassword}
                      style={{
                        marginTop: "-75px",
                        color: "gray",
                        marginLeft: "80%",
                      }}
                    />
                  )}
                </div>
                <ValidationError
                  show={!isValidPassword}
                  message="Password is Required"
                />
              </Form>
            </div>
            <div className="phone-text">
              <PhoneInput
                inputProps={{
                  name: "number",
                  required: true,
                  disabled: !!verifyOTP,
                }}
                specialLabel=""
                name="number"
                country={"in"}
                value={phoneNumber}
                onChange={(value, data) => {
                  onChangeContactNumber(value, data);
                }}
                required
                containerStyle={{
                  border: "none",
                  marginTop: "25px",
                  height: "48px",
                }}
                inputStyle={{
                  backgroundColor: "#f4f4f5",
                  border: "1px solid #f4f4f5",
                  height: "48px",
                  width: isMobile ? "305px" : "310px",
                  "&:focus": {
                    borderBottom: "1px solid black",
                  },
                }}
              />
              {!isOTPVerify && (
                <button className="send-otp " onClick={onSendOTP}>
                  Send OTP
                </button>
              )}
              {otpInput && (
                <div className="form-group mt-3">
                  <h5>Enter OTP</h5>
                  <OtpInput
                    autoComplete="off"
                    value={otp}
                    onChange={(value) => {
                      setOtp(value);
                    }}
                    numInputs={6}
                    separator={" "}
                    placeholder="0"
                    inputStyle={{
                      width: "2rem",
                      height: "2rem",
                      marginRight: "6px",
                      fontSize: "1rem",
                      borderRadius: 4,
                      border: "1px solid grey",
                    }}
                  />
                  <div className="d-flex">
                    <h6
                      className="mt-3"
                      style={{
                        color: "#f88932",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={onSendOTP}
                    >
                      Resend OTP
                    </h6>
                    <h6
                      className="mt-3"
                      style={{
                        color: "#f88932",
                        fontWeight: "bold",
                        cursor: "pointer",
                        marginLeft: "16px",
                      }}
                      onClick={onVerifyMobileOTP}
                    >
                      Verify OTP
                    </h6>
                  </div>
                </div>
              )}
              <ValidationError
                show={!isValidPhoneNumber}
                message="Mobile number must verified"
              />
            </div>
            <div className="mail-location-div">
              <div>
                <TextField
                  required
                  label="Email Id"
                  value={email}
                  variant="outlined"
                  className="mail-input"
                  onChange={(e) => {
                    isValidMailCheck(e.target.value);
                  }}
                />
                <ValidationError
                  show={!isValidEmail}
                  message="Please Enter Valid Email"
                />
              </div>
              <text className="or">Or</text>
              <GoogleLogin
                className="google-mail"
                clientId="985398972528-kh601re0qhje07jhgfr1rpm1fd7boub4.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                buttonText="Connect with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogleFailure}
                disabled={isChecked}
              />
            </div>

            <div
              style={{
                marginLeft: "22px",
                color: "#21252985",
                marginTop: "15px",
              }}
            >
              <LocationSearchInput
                setAdd={setAdd}
                setLatLng={setLatLng}
                newLocation={newLocation}
              />
              <ValidationError
                show={!isValidCountry}
                message="Please Enter Valid Location"
              />
            </div>
            <div style={{ marginLeft: "25px" }}>
              <div className="form-group mt-3">
                <div className="row">
                  <FormControl
                    component="fieldset"
                    style={{ marginLeft: "11px" }}
                  >
                    <label htmlFor="vendorType">I am</label>
                    <RadioGroup
                      required
                      row
                      aria-label="vendorType"
                      name="row-radio-buttons-group"
                      value={vendorType}
                      onChange={(e) => {
                        setVendorType(e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="agent"
                        control={
                          <Radio
                            sx={{
                              color: orange[800],
                              "&.Mui-checked": {
                                color: orange[800],
                              },
                            }}
                          />
                        }
                        label="Agent"
                        className="ml-3"
                      />
                      <FormControlLabel
                        value="dealer"
                        control={
                          <Radio
                            sx={{
                              color: orange[800],
                              "&.Mui-checked": {
                                color: orange[800],
                              },
                            }}
                          />
                        }
                        label="Dealer"
                      />
                      <FormControlLabel
                        value="vendor"
                        control={
                          <Radio
                            sx={{
                              color: orange[800],
                              "&.Mui-checked": {
                                color: orange[800],
                              },
                            }}
                          />
                        }
                        label="Vendor"
                      />
                      <FormControlLabel
                        value="manufacturer"
                        control={
                          <Radio
                            style={{ marginLeft: "20px" }}
                            sx={{
                              color: orange[800],
                              "&.Mui-checked": {
                                color: orange[800],
                              },
                            }}
                          />
                        }
                        label="Manufacturer"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>

            <div>
              <button className="submit-div" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartSelling;
