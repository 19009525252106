import React from "react";
import { Form } from "react-bootstrap";
import { overlay, popupContainer } from "./style";

export default function SelectSubdomain({
  show,
  isMobile,
  onCancelClick,
  onConfirmClick,
  userWebsite,
  subDomain,
  setSubDomain,
}) {
  return (
    <div
      style={{
        visibility: show ? "visible" : "hidden",
        opacity: show ? "1" : "0",
        height: "auto",
      }}
      className={overlay}
    >
      <div className={popupContainer(isMobile)}>
        <Form className="formStyle">
          {userWebsite.length > 0 && (
            <Form.Group className="mb-3" controlId="Currency">
              <Form.Label>
                <strong>Sub Domain</strong>
              </Form.Label>
              <Form.Select
                name="subdomain_name"
                value={subDomain}
                onChange={(e) => setSubDomain(e.target.value)}
              >
                {userWebsite?.map((item) => (
                  <option value={item?.value}>{item?.label}</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </Form>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn"
            style={{ background: "#f88932", color: "white" }}
            onClick={onConfirmClick}
          >
            OK
          </button>
          <button
            type="button"
            className="btn mx-3"
            style={{ background: "#e0e1e3" }}
            onClick={onCancelClick}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
