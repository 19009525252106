import React from "react";
import PhoneInput from "react-phone-input-2";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { ChevronleftThick } from "@ironlist/ironlist-icons";
import { orange } from "@mui/material/colors";
import { CountryDropdown } from "react-country-region-selector";
import OtpInput from "react-otp-input";
import { Checkbox } from "@mui/material";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};
const firstName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The FirstName must be between 3 and 20 characters.
      </div>
    );
  }
};
const lastName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The LastName must be between 3 and 20 characters.
      </div>
    );
  }
};
export default function UpdateProfile({
  userData,
  onCancelClick,
  setUserData,
  submitUpdateProfile,
  onSendOTP,
  onVerifyMobileOTP,
  otpInput,
  otp,
  setOtp,
  verifyOTPSuccess,
  isMobile,
}) {
  const onChangeContactNumber = (value, data) => {
    setUserData((prevState) => ({
      ...prevState,
      phone: value.slice(data.dialCode.length),
      dialCode: data.dialCode,
    }));
  };

  return (
    <div className="UpdateProfileContainer">
      {isMobile && (
        <div className="header-button">
          <button
            onClick={onCancelClick}
            className="btn"
            style={{
              padding: "0px",
              color: "#f88932",
              marginRight: "16px",
              fontWeight: 800,
            }}
          >
            Cancel
          </button>
          <button
            onClick={submitUpdateProfile}
            className="btn"
            style={{
              padding: "0px",
              color: "#f88932",
              marginRight: "16px",
              fontWeight: 800,
            }}
          >
            Save
          </button>
        </div>
      )}
      {!isMobile ? (
        <div className="headerContainer">
          <div className="d-flex align-items-center">
            <ChevronleftThick
              width={20}
              height={20}
              onClick={onCancelClick}
              className="backButton"
            />
            <h3 className="header">Edit Profile</h3>
          </div>
        </div>
      ) : (
        <div className="separatorLine-header" />
      )}

      <Form className={isMobile ? `formStyle` : `col-6 mx-3 my-3`}>
        <div className="form-group">
          <div className={!isMobile && `row`}>
            <div className="col">
              <label htmlFor="firstName">First Name</label>
              <Input
                required
                type="text"
                className="form-control"
                name="firstName"
                value={userData?.name}
                onChange={(e) =>
                  setUserData((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
                validations={[required, firstName]}
              />
            </div>
            <div className="col">
              <label htmlFor="LastName">Last Name</label>
              <Input
                required
                type="text"
                className="form-control"
                name="lastName"
                value={userData?.lastName}
                onChange={(e) =>
                  setUserData((prevState) => ({
                    ...prevState,
                    lastName: e.target.value,
                  }))
                }
                validations={[required, lastName]}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <label htmlFor="contactNumber">Contact Number</label>
          <PhoneInput
            inputProps={{
              name: "phone_number",
              required: true,
            }}
            value={`+${userData.dialCode} ${userData.phone}`}
            specialLabel=""
            country={userData?.phoneCountry.toLowerCase() || "us"}
            onChange={(value, data) => {
              onChangeContactNumber(value, data);
            }}
            validations={[required(userData.phone)]}
            required
            containerStyle={{
              border: "none",
              marginTop: "4px",
              height: "48px",
            }}
            inputStyle={{
              backgroundColor: "#f4f4f5",
              border: "1px solid #f4f4f5",
              height: "48px",
              width: isMobile ? "336px" : "330px",
              "&:focus": {
                borderBottom: "1px solid black",
              },
            }}
          />

          {!otpInput && !verifyOTPSuccess && (
            <h6
              style={{
                color: "#f88932",
                fontWeight: "bold",
                cursor: "pointer",
                float: "right",
                marginTop: "5px",
              }}
              onClick={onSendOTP}
            >
              Send OTP
            </h6>
          )}

          {!otpInput && verifyOTPSuccess && (
            <div
              className="d-flex align-items-center"
              style={{
                float: "right",
                marginTop: "5px",
              }}
            >
              <Checkbox
                checked
                inputProps={{ "aria-label": "controlled", padding: "0px" }}
                sx={{
                  color: "#f88932",
                  "&.Mui-checked": {
                    color: "#f88932",
                  },
                  padding: "0px",
                }}
              />
              <h6
                style={{
                  marginBottom: "0px",
                  marginLeft: "4px",
                  color: "#f88932",
                  fontWeight: "bold",
                }}
              >
                Verified
              </h6>
            </div>
          )}
          {otpInput && (
            <div className="form-group mt-3">
              <h5>Enter OTP</h5>
              <OtpInput
                autoComplete="off"
                value={otp}
                onChange={(value) => {
                  setOtp(value);
                }}
                numInputs={6}
                separator={" "}
                placeholder="0"
                inputStyle={{
                  width: "2rem",
                  height: "2rem",
                  marginRight: "6px",
                  fontSize: "1rem",
                  borderRadius: 4,
                  border: "1px solid grey",
                }}
              />
              <div className="d-flex">
                <h6
                  className="mt-3"
                  style={{
                    color: "#f88932",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={onSendOTP}
                >
                  Resend OTP
                </h6>
                <h6
                  className="mt-3"
                  style={{
                    color: "#f88932",
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginLeft: "16px",
                  }}
                  onClick={onVerifyMobileOTP}
                >
                  Verify OTP
                </h6>
              </div>
            </div>
          )}
        </div>
        <div className="mt-3">
          <label htmlFor="companyName">Company Name</label>
          <Input
            required
            type="text"
            className="form-control"
            name="companyName"
            value={userData?.companyName}
            onChange={(e) =>
              setUserData((prevState) => ({
                ...prevState,
                companyName: e.target.value,
              }))
            }
            validations={[required]}
          />
        </div>
        <div className="mt-3">
          <div className="row">
            <FormControl component="fieldset" className="radio-button">
              <label htmlFor="vendorType">I am</label>
              <RadioGroup
                row
                aria-label="vendorType"
                name="row-radio-buttons-group"
                value={userData.vendorType.toLowerCase()}
                onChange={(e) =>
                  setUserData((prevState) => ({
                    ...prevState,
                    vendorType: e.target.value,
                  }))
                }
              >
                <FormControlLabel
                  value="agent"
                  control={
                    <Radio
                      sx={{
                        color: orange[800],
                        "&.Mui-checked": {
                          color: orange[800],
                        },
                      }}
                    />
                  }
                  label="Agent"
                  className="ml-3"
                />
                <FormControlLabel
                  value="dealer"
                  control={
                    <Radio
                      sx={{
                        color: orange[800],
                        "&.Mui-checked": {
                          color: orange[800],
                        },
                      }}
                    />
                  }
                  label="Dealer"
                />
                <FormControlLabel
                  value="vendor"
                  control={
                    <Radio
                      sx={{
                        color: orange[800],
                        "&.Mui-checked": {
                          color: orange[800],
                        },
                      }}
                    />
                  }
                  label="Vendor"
                />
                <FormControlLabel
                  value="manufacturer"
                  control={
                    <Radio
                      sx={{
                        color: orange[800],
                        "&.Mui-checked": {
                          color: orange[800],
                        },
                      }}
                    />
                  }
                  label="Manufacturer"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="mt-3">
          <label htmlFor="country">Country</label>
          <CountryDropdown
            style={{
              width: "100%",
              height: "38px",
              borderRadius: "4px",
            }}
            value={userData.country}
            onChange={(e) =>
              setUserData((prevState) => ({
                ...prevState,
                country: e,
              }))
            }
            required
            validations={[required]}
          />
        </div>
      </Form>
      {!isMobile && (
        <div className="SubmitButton">
          <div>
            <button
              onClick={onCancelClick}
              className="btn my-2"
              style={{
                color: "#f88932",
                border: "2px solid #f88932",
                marginRight: "16px",
                height: "40px",
                width: "108px",
              }}
            >
              Cancel
            </button>
            <button
              onClick={submitUpdateProfile}
              type="submit"
              className="btn my-2"
              style={{
                background: "#f88932",
                color: "white",
                height: "40px",
                width: "108px",
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
