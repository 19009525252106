export const imageUrl = (path) =>
  `https://d3g7ucapfhrqqt.cloudfront.net${path}`;

export const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
