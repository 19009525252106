import { Menu, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../pages/home/Header/Header";
import { useIsMobile } from "../context/IsMobile";
import MobileHeader from "../MobileHeader";
import { googleStyle } from "./style";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Navbar({ currentUser, logoutClick }) {
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
  //     },
  //     "google_translate_element"
  //   );
  // };
  function googleTranslateElementInit() {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement,
      },
      "google_translate_element"
    );
  }
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);
  return (
    <>
      {!isMobile ? (
        <>
          <nav className="navbar navbar-expand navbar-light bg-light">
            <div
              className="d-flex justify-content-between m-auto "
              style={{ width: "90%" }}
            >
              <a href="https://ironlist.com" target="_blank" rel="noreferrer">
                <img
                  src="https://d3g7ucapfhrqqt.cloudfront.net/images/ironlist-logo-2.webp"
                  alt="IronList"
                  className="ImageLogo  mx-3 my-2"
                />
              </a>

              {currentUser && (
                <>
                  {isMobile ? <Header /> : null}
                  <div className={googleStyle}>
                    <div
                      id="google_translate_element"
                      className="google-div"
                    ></div>
                  </div>
                  <div className="navbar-nav ml-auto d-flex align-items-center ">
                    <li className="nav-item">
                      <div className="initials-circle" onClick={handleClick}>
                        {currentUser.name ? currentUser.name[0] : ""}
                      </div>
                    </li>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <AccountBoxIcon />
                        <Link
                          to={"/myProfile"}
                          style={{
                            textDecoration: "none",
                            fontSize: "18px",
                            color: "black",
                            marginLeft: 10,
                          }}
                        >
                          My Profile
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleClose}>
                        <AccountCircleIcon />
                        <Link
                          to={"/myProfile"}
                          style={{
                            textDecoration: "none",
                            fontSize: "18px",
                            color: "black",
                            marginLeft: 10,
                          }}
                        >
                          Org Profile
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={logoutClick}>
                        <LogoutIcon style={{ marginRight: 10 }} />
                        Logout
                      </MenuItem>
                    </Menu>
                  </div>
                </>
              )}
            </div>
          </nav>
        </>
      ) : (
        <MobileHeader currentUser={currentUser} logoutClick={logoutClick} />
      )}
    </>
  );
}
