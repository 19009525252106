import React from "react";
import { howToSellStyle, posterStyle, sliderdiv, typoStyle } from "./styles";
import { Card, Typography } from "@mui/material";
import { useIsMobile } from "../../components/context/IsMobile";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const SellItems = () => {
  const isMobile = useIsMobile();
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const whySettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const howToSell = [
    {
      icon: (
        <img
          src="https://www.freeiconspng.com/thumbs/seller-icon/seller-icon-png-0.png"
          alt="image1"
          className="sell-icons"
        />
      ),
      title: "Create An Seller Account for free",
      description:
        "Create an seller account for free all you need is Email address,Mobile Number, Pan & GST Detail for setup.",
    },
    {
      icon: (
        <img
          src="https://www.freeiconspng.com/thumbs/upload-documents-icon/orange-square-button-document-file-page-up-upload-icon-5.jpeg"
          alt="imge2"
          className="sell-icons"
        />
      ),
      title: " Upload Items, Create Page & publish",
      description:
        " Put up all the equipment, machinery and commercial vehicles you want to sell along with images, description and publish it.",
    },
    {
      icon: (
        <img
          src="https://user-images.githubusercontent.com/44617192/48874969-6e1e0900-edf6-11e8-98a4-9812e9a99b60.png"
          alt="imge3"
          className="sell-icons"
        />
      ),
      title: " Start getting leads on item",
      description:
        " Once you have uploaded the equipment & machinery to sell you will start getting inquiries on it from buyers converse with them & sell your item.",
    },
  ];
  const whyIronlist = [
    {
      icon: (
        <img
          src="https://icons.iconarchive.com/icons/custom-icon-design/pretty-office-11/512/sale-icon.png"
          alt="img1"
          className="sell-icon"
        />
      ),
      title: " Sell to genuine interested buyers",
      description:
        "  Sign up and sell your items to right buyers across the global.",
    },
    {
      icon: (
        <img
          src="https://cdn-icons-png.flaticon.com/512/3628/3628260.png"
          alt="img2"
          className="sell-icon"
        />
      ),
      title: " Low Cost of doing business",
      description:
        " Get the best deal price in the market with Ironlist expert selling & marketing assistance .",
    },
    {
      icon: (
        <img
          src="https://cdn-icons-png.flaticon.com/512/1998/1998557.png"
          alt="img3"
          className="sell-icon"
        />
      ),
      title: "Ease & Transparency",
      description:
        "From product info to promotional banners, leads management,Ironlist has a solution for you.",
    },
    {
      icon: (
        <img
          src="https://cdn3d.iconscout.com/3d/premium/thumb/staff-management-5706036-4755478.png"
          alt="img4"
          className="sell-icon"
        />
      ),
      title: "Manage Business one go",
      description:
        "With the Ironlist web app you can manage your business, solve issues, and respond to customers – anywhere, anytime.",
    },
  ];
  return (
    <>
      {isMobile ? (
        <div className={sliderdiv(isMobile)}>
          <p className={typoStyle(isMobile)}>
            How To Sell Used Heavy Equipment & Machinery Online On IronList
          </p>

          <Slider {...settings} className={howToSellStyle(isMobile)}>
            {howToSell.map((x) => (
              <div>
                <Card className="cards">
                  {x.icon}
                  <Typography className="sell-h2">{x.title}</Typography>
                  <Typography className="sell-h3">{x.description}</Typography>
                </Card>
              </div>
            ))}
          </Slider>
          <p className={typoStyle(isMobile)}>
            Why To Sell Used Heavy Equipment & Machinery Online On IronList
          </p>
          <Slider {...whySettings} className={howToSellStyle(isMobile)}>
            {whyIronlist.map((x) => (
              <div>
                <Card className="cards">
                  {x.icon}
                  <Typography className="sell-h2">{x.title}</Typography>
                  <Typography className="sell-h3">{x.description}</Typography>
                </Card>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <>
          <div className={howToSellStyle(isMobile)}>
            <p className={typoStyle(isMobile)}>
              How To Sell Used Heavy Equipment & Machinery Online On IronList
            </p>
            <div className="three-cards">
              {howToSell.map((x) => (
                <div className="cards">
                  {x.icon}
                  <Typography className="sell-h2">{x.title}</Typography>
                  <Typography className="sell-h3">{x.description}</Typography>
                </div>
              ))}
            </div>
          </div>
          <div className={howToSellStyle(isMobile)}>
            <p className={typoStyle(isMobile)}>
              Why To Sell Used Heavy Equipment & Machinery Online On IronList
            </p>

            <div className="four-cards">
              {whyIronlist.map((x) => (
                <div className="cards">
                  {x.icon}
                  <Typography className="sell-h2">{x.title}</Typography>
                  <Typography className="sell-h3">{x.description}</Typography>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div className={posterStyle(isMobile)}>
        <Typography className="typo-div">
          Start Selling Your Equipment & Machines
        </Typography>
        <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
          <button className="sells-button">Start Selling</button>
        </Link>
        <Typography className="typo-text">
          Whether you are a brand owner, a reseller,are new to selling, or have
          been doing it for years-ironlist.com has a wid range of options to
          help you find your customer and grow your business.
        </Typography>
      </div>
    </>
  );
};

export default SellItems;
