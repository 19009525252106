import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../components/context/IsMobile";
import MidPage from "./MidPage";
import { backgroundStyle, machineStyle } from "./style";

const Excavator = () => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title =
      "Sell Excavator Online On IronList | Heavy Equipment & Machines. Used  Excavator For Sale | Excavator price in india | Excavator in India |  Trackhoes & Excavators For Sale";
  }, []);

  return (
    <>
      <div className={backgroundStyle}>
        <div style={{ overflow: "hidden" }}>
          <div className={machineStyle(isMobile)}>
            <div className="images-div">
              <div>
                <h4 className="h4-div">
                  Sell Excavator Online On IronList | Heavy Equipment & Machines
                </h4>
                <div style={{ textAlign: "center" }}>
                  <Link to={"/startSelling"} style={{ textDecoration: "none" }}>
                    <button className="mid-sell-button">Start Selling</button>
                  </Link>
                </div>
              </div>
              <img
                className="imgs "
                src={`./assets/images/Sell Excavator Online Ironlist.png`}
                alt="excavator"
              />
            </div>
          </div>
          <MidPage />
        </div>
      </div>
    </>
  );
};

export default Excavator;
