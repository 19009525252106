import {imageUrl} from ".";

export const backGroundImage = imageUrl("/images/loginSignup_image.jpg");
export const ironListLogo = imageUrl("/images/il-logo-white-big.png");
export const onBoardSteps = [
  {
    image: imageUrl("/images/asset-vendor-home-01.svg"),
    title: "Sign up",
    subTitle: "Sign up with us using some basic details",
  },
  {
    image: imageUrl("/images/asset-vendor-home-02.svg"),
    title: "Choose subscription",
    subTitle:
      "Select a subscription plan as per your convenience and enter item details or your website sitemap ",
  },
  {
    image: imageUrl("/images/asset-vendor-home-03.svg"),
    title: "Publish",
    subTitle: "Publish the listing you created and you are ready to sell!",
  },
];

export const PlansImage = {
  //   Free: imageUrl("/images/RegularPlan.svg"),
  Basic: imageUrl("/images/Bronze.svg"),
  //   Bronze: imageUrl("/images/Bronze.svg"),
  Business: imageUrl("/images/SilverPlan.svg"),
  //   Silver: imageUrl("/images/SilverPlan.svg"),
  "Business+": imageUrl("/images/GoldPlan.svg"),
  //   Gold: imageUrl("/images/GoldPlan.svg"),
};

export const whyIronList = [
  {
    image: imageUrl("/images/asset-vendor-home-04.svg"),
    title: "Global Exposure",
    subTitle: "Get Global Exposure for your Listings",
  },
  {
    image: imageUrl("/images/asset-vendor-home-05.svg"),
    title: "Verified lead",
    subTitle: "Get verified buyer leads",
  },
  {
    image: imageUrl("/images/asset-vendor-home-06.svg"),
    title: "Promote",
    subTitle: "Promote your listing with display and banner ads",
  },
  {
    image: imageUrl("/images/asset-vendor-home-07.svg"),
    title: "Smooth, Easy",
    subTitle: "Smooth onboarding process",
  },
  {
    image: imageUrl("/images/asset-vendor-home-08.svg"),
    title: "Help & Support",
    subTitle: "Help and support at every stage of selling",
  },
];

export const FooterLink = [
  {
    link: "https://ironlist.com/about",
    label: "About Us",
  },
  {
    link: "https://ironlist.com/contact",
    label: "Contact Us",
  },
  {
    link: "https://career.ironlist.com/",
    label: "Career ",
  },
  {
    link: "https://blog.ironlist.com/",
    label: "Blog",
  },
  {
    link: "https://ironlist.com/term-of-use",
    label: "Terms Of Use & Privacy Policy",
  },
];

export const currencyWithSymbol = {
  USD: "36",
  INR: "8377",
  AUD: "36",
  EUR: "8364",
  RUB: "8381",
  JPY: "165",
};

export const faq = [
  {
    id: "1",
    question: "Is Vendor On Boarding Free?",
    answer:
      "Yes Vendor onboarding is free for every type of seller across the globe.",
  },
  {
    id: "2",
    question: "As a seller will I get dashboard to manage my listings?",
    answer:
      "Yes once a vendor gets onboard with us or buy any of our plan then they can manage their listings and plans.",
  },
  {
    id: "3",
    question: "How many Free listings will I get?",
    answer:
      "Every Registered Vendor or seller will get 10 free listings with 45 days of validity throughout the year for more listings please checkout our plans.",
  },
  {
    id: "4",
    question: "What Kind of listing Can I Upload?",
    answer:
      "We Currently are accepting two kind of listings formats Sale & Rent for all kind of Used Heavy Equipment & Machinery Categories.",
  },
  {
    id: "5",
    question: "How can I upload my listings? ",
    answer:
      "Once a vendor is onboard then from listing screen of the vendor dashboard one can easily create and add listings they want to list.",
  },
  {
    id: "6",
    question:
      "What minimum Information do I need to have to upload any listings?",
    answer:
      "We would recommend to enter every detail that has been asked in create listing format for better inquires. But the basic requirement of a listing is Equipment Name, Manufacturer of the Equipment, Year of Manufacturing, Model of Equipment and a Clear image.",
  },
  {
    id: "7",
    question: "Can I pause my live listings?",
    answer:
      "Yes, You Can pause your listings any time within the validity duration and those time will be not be counted.",
  },
  {
    id: "8",
    question: "How will I know if a buyer is interested in my listings?",
    answer:
      "You will receive an email notification of any inquiry and also you can check the leads section for detail leads in your vendor account.",
  },
  {
    id: "9",
    question: "What will be the refund policy?",
    answer:
      "If you face any issues then you can let us know within the first 7 days, we will try to resolve it and it doesn’t work then we will refund the amount. After 7 days there won’t be any refund.",
  },
];
