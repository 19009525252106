import { ChevronleftThick } from "@ironlist/ironlist-icons";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useIsMobile } from "../../components/context/IsMobile";
import { sendOtp, verifyMobileOTP } from "./OtpVerification";

const PhoneLogin = ({ setPhoneLogin }) => {
  const isMobile = useIsMobile();
  const [, successFull] = useState(false);
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [otpInput, setOtpInput] = useState(false);
  const [verifyOTPSuccess, setVerifyOTPSuccess] = useState(false);
  const [, setVerifyOTP] = useState(false);
  const [key, setKey] = useState("");
  const [, setShow] = useState(
    JSON.parse(localStorage.getItem("user"))?.pendingInfo || false
  );
  const history = useHistory();

  const onChangeContactNumber = (value, data) => {
    setPhoneNumber((prevState) => ({
      ...prevState,
      phone_no: value?.slice(data.dialCode.length),
      dial_code: data?.dialCode,
    }));
    setDialCode(data.dialCode);
    setPhone(value.slice(data.dialCode.length));
    // console.log(value, data);
    setPhoneNumber(`+${value}`);
  };
  const onSendOTP = () => {
    sendOtp({
      phone,
      countryCode: dialCode,
      phoneNo: `+${dialCode.concat(phone)}`,
      isLogin: true,
    }).then((response) => {
      if (response?.success) {
        setOtpInput(true);
        setKey(response?.key);
        toast.success(`OTP Send Successfully !`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (!response?.success) {
        toast.error(`${response?.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  const onVerifyMobileOTP = () => {
    verifyMobileOTP({
      key,
      phoneNo: `+${dialCode.concat(phone)}`,
      otp,
      isLogin: true,
    }).then((response) => {
      if (response?.success) {
        setVerifyOTPSuccess(true);
        setOtpInput(false);
        setKey(response?.key);

        setVerifyOTP(true);
        if (response.pendingInfo === false) {
          localStorage.setItem("user", JSON.stringify(response));
          if (response.pendingInfo === false) {
            history.push("/dashboard");
            window.location.reload();
          }
          if (response.pendingInfo) {
            setShow({ show: true, remail: response.phone });
          }
        }

        toast.success(`${response?.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      if (!response?.success) {
        toast.error(`${response?.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    });
  };
  return (
    <div>
      <div
        className="bg-white"
        style={{
          borderRadius: "4px",
          padding: "32px",
          width: isMobile ? "360px" : "474px",
          height: isMobile ? "" : "694px",
        }}
      >
        {!successFull && (
          <>
            <h3 className="signupHeader">
              <ChevronleftThick
                className="leftThick"
                onClick={() => {
                  setPhoneLogin(false);
                }}
                title="Chevron left"
              />
              <strong>Login With Phone Number</strong>
            </h3>
          </>
        )}
        <div>
          <PhoneInput
            inputProps={{
              name: "number",
              required: true,
            }}
            specialLabel=""
            country={"in"}
            value={phoneNumber}
            onChange={(value, data) => onChangeContactNumber(value, data)}
            required
            containerStyle={{
              border: "none",
              marginTop: "25px",
              height: "48px",
            }}
            inputStyle={{
              backgroundColor: "#f4f4f5",
              border: "1px solid #f4f4f5",
              height: "48px",
              width: isMobile ? "336px" : "310px",
              "&:focus": {
                borderBottom: "1px solid black",
              },
            }}
          />
          {!otpInput && !verifyOTPSuccess && (
            <Button className="otp" onClick={onSendOTP}>
              Send OTP
            </Button>
          )}
        </div>
        {otpInput && (
          <div style={{ marginTop: 30 }}>
            <h5 className="otpHeader">Enter OTP</h5>
            <OtpInput
              autoComplete="off"
              value={otp}
              onChange={(value) => {
                setOtp(value);
              }}
              numInputs={6}
              separator={" "}
              placeholder="0"
              inputStyle={{
                width: "2rem",
                height: "2rem",
                marginRight: "6px",
                fontSize: "1rem",
                borderRadius: 4,
                border: "1px solid grey",
              }}
            />
            <div style={{ display: "flex", marginTop: 15 }}>
              <h6
                style={{
                  color: "#f88932",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginTop: 10,
                }}
                onClick={onVerifyMobileOTP}
              >
                Verify OTP
              </h6>
              <h6
                style={{
                  color: "#f88932",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginTop: 10,
                  marginLeft: "16px",
                }}
                onClick={onSendOTP}
              >
                Resend OTP
              </h6>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PhoneLogin;
