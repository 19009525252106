import { useIsMobile } from "../../components/context/IsMobile";
import { mobileTableCard, plansTableStyle } from "./style";

export default function PlansTable({ data, exportPDF }) {
  const isMobile = useIsMobile();
  return (
    <>
      {!isMobile && (
        <div className={plansTableStyle}>
          <table>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Payment Type</th>
                <th>Plan Name</th>
                <th>No. of listing</th>
                <th>Duration</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>Card</td>
                  <td>{item?.plan_name}</td>
                  <td>{item?.no_of_listings}</td>
                  <td>{item?.listing_validity}</td>
                  {item.created_at ? (
                    <td>{item?.created_at?.split("T")[0]}</td>
                  ) : (
                    <td>{item.orderDate?.split("T")[0]}</td>
                  )}
                  <td>
                    <button className="invoice-button" onClick={exportPDF}>
                      Download Invoice
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {isMobile && (
        <div className={mobileTableCard}>
          {data.map((item, index) => (
            <>
              <div className="top-row">
                <h6>
                  <strong>{item?.plan_name}</strong>
                </h6>
                <h6>Sr. No: {index + 1}</h6>
              </div>
              <div className="items">
                <h6 className="item-title">Validity : </h6>
                <h6 className="item-name">{item.listing_validity}</h6>
              </div>
              <div className="items">
                <h6 className="item-title">No. of listing : </h6>
                <h6 className="item-name">{item.no_of_listings}</h6>
              </div>
              <div className="items">
                <h6 className="item-title">Date : </h6>
                <h6 className="item-name">{item.orderDate?.split("T")[0]}</h6>
              </div>
              <div className="items">
                <h6 className="item-title">Payment Type : </h6>
                <h6 className="item-name">Card</h6>
              </div>
              <hr />
              <button className="invoice-button-mobile" onClick={exportPDF}>
                Download Invoice
              </button>
            </>
          ))}
        </div>
      )}
    </>
  );
}
