import {useState, useEffect} from "react";
import {Button} from "react-bootstrap";
import axios from "axios";
import {useHistory, Link} from "react-router-dom";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {DeleteThick, EditThick} from "@ironlist/ironlist-icons";
import ConfirmationPopUp from "../../components/ConfirmationPopup/ConfirmationPopup";
import {listingContainer, MobileListingCard, plansTableStyle} from "./style";
import {useIsMobile} from "../../components/context/IsMobile";
import Pagination from "../../components/Pagination";
import LoadingSpinner from "../../components/LoadingSpinner";
import ModalView from "../Modal";

export default function Listings() {
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  // const [showPopupSold, setShowPopupSold] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const isMobile = useIsMobile();
  const [count, setCount] = useState(0);
  const [isSold, setSold] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const page = Number(urlParams.get("page")) || 1;

  useEffect(() => {
    const options = {
      url: `${process.env.REACT_APP_API_URL}/dashboard/products`,
      method: "POST",
      data: {
        page: page,
        size: 7,
      },
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setLoading(false);
      }
      setCount(response?.data?.count);
      setData(response?.data?.items);
    });
    setTimeout(() => setLoading(false), 2000);
  }, [page, isSold]);
  const handleDelete = (id) => {
    setShowPopup(false);
    setData(data.filter((item) => item.id !== id));
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/dashboard/products/delete/${id}`,
        {
          headers: {
            "x-access-token": JSON.parse(localStorage.getItem("user"))
              ?.accessToken,
          },
        }
      )
      .then((response) => {
        if (response.data.message === "success") {
          setDeleteId("");
          toast.error("Item Deleted Successfully!", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
  };
  const onPublishClick = (is_publish, itemData) => {
    if (itemData.is_sold === 1) {
      return true;
    }
    if (is_publish === -1) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/dashboard/listings/renewed/${itemData.id}`,
          {},
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("user"))
                ?.accessToken,
            },
          }
        )
        .then((response) => {
          toast.success(`${response.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log(isSold);
          setTimeout(() => window.location.reload(), 3000);
          console.log(isSold);
          is_publish = 1;
          return true;
        })
        .catch((err) => {
          toast.error(`${err.data.message}`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/dashboard/listings/${itemData.id}`,
          {is_publish},
          {
            headers: {
              "x-access-token": JSON.parse(localStorage.getItem("user"))
                ?.accessToken,
            },
          }
        )
        .then((response) => {
          if (
            response.data.success &&
            response.data.message !== "Please Buy a plan"
          ) {
            const updatePublish = data.map((item) => ({
              ...item,
              published_at:
                itemData.id === item.id
                  ? !item.published_at
                  : item.published_at,
            }));
            setData(updatePublish);
            setSold(!isSold);
            toast.success(`${response.data.message}`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          if (
            response.data.success &&
            response.data.message === "Please Buy a plan"
          ) {
            toast.error(`${response.data.message}`, {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    }
  };

  const handleSold = (item) => {
    if (item.is_sold === 1) {
      return true;
    }
    const options = {
      url: `${process.env.REACT_APP_API_URL}/dashboard/listings/${item.id}`,
      method: "POST",
      data: {
        is_sold: item.is_sold === 0 ? 1 : 0,
      },
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    axios(options).then((response) => {
      if (response?.data?.success) {
        toast.success(`${response.data.message}`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSold(!isSold);
      }
    });
  };

  if (
    !localStorage.getItem("user") ||
    JSON.parse(localStorage.getItem("user"))?.pendingInfo
  ) {
    history.push("/");
    return null;
  }

  const isLoadRenewOrPublish = (item) => {
    if (item.published_at) {
      return (
        <button
          className="btn"
		  data-productId={item.id}
          style={{
            border: "2px solid #f88932",
            color: "#f88932",
          }}
          onClick={() => onPublishClick("0", item)}
        >
          Unpublish
        </button>
      );
    } else if (!item.published_at && item.is_renew === 1) {
      return (
        <button
          className="btn"
          style={{
            background: "#f88932",
            color: "white",
          }}
          onClick={() => onPublishClick(-1, item)}
          data-productId={item.id}
        >
          Renew
        </button>
      );
    } else {
      return (
        <button
          className="btn"
          style={{
            background: "#f88932",
            color: "white",
          }}
          onClick={() => onPublishClick("1", item)}
          data-productId={item.id}
        >
          Publish
        </button>
      );
    }
  };

  return (
    <>
      <div className={listingContainer(isMobile)}>
        <div className="listingdiv">
          <h3 className="ListingHeader">All Listings</h3>
          <div className="uploadListing">
            <Link to={"/listing/new"}>
              <Button variant="light" className="uploadButton">
                Upload Item
              </Button>
            </Link>
          </div>
        </div>
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {data.length > 0 && !isMobile && (
              <div className={plansTableStyle}>
                <table>
                  <thead>
                    <tr>
                      {/* <th>Image</th> */}
                      <th>ID</th>
                      <th>Item Title</th>
                      <th>Action</th>
                      <th>Status</th>
                      <th>View</th>
                      <th>Sold</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={item?.id}>
                        {/* <td>
                          <img
                            src={item?.thumbnail_url}
                            alt="itemImage"
                            width={48}
                            height={48}
                            className="item-image"
                          />
                        </td> */}
                        <td>
                          {page > 1 ? index + 1 + 7 * (page - 1) : index + 1}
                        </td>
                        <td>{item?.item_title}</td>
                        <td>
                          {item.is_sold === 0 && (
                            <>
                              <Link to={"/listing/" + item?.id}>
                                <EditThick
                                  className="mx-2"
                                  style={{color: "black", opacity: 0.7}}
                                />
                              </Link>
                              <DeleteThick
                                className="mx-2"
                                style={{
                                  color: "black",
                                  opacity: 0.7,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowPopup(true);
                                  setDeleteId(item?.id);
                                }}
                              />
                            </>
                          )}
                        </td>
                        <td>{isLoadRenewOrPublish(item)}</td>
                        <td>
                          <ModalView data={item} />
                        </td>
                        <td>
                          <input
                            value={item.is_sold}
                            key={item.id}
                            type="checkbox"
                            checked={item.is_sold === 1 ? "checked" : null}
                            onChange={() => {
                              // setShowPopupSold(true);
                              handleSold(item);
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {data.length > 0 && isMobile && (
              <>
                {data.map((item) => (
                  <div className={MobileListingCard} key={item?.id}>
                    <div className="first-section">
                      <img
                        src={item?.thumbnail_url}
                        alt="itemImage"
                        width={72}
                        height={72}
                        className="item-image"
                      />
                    </div>
                    <div className="mid-section">
                      <h5 className="card-title">
                        <a
                          style={{
                            textDecoration: "none",
                            cursor: "pointer",
                            color: "gray",
                          }}
                          href={`https://ironlist.com/share/${item.item_title
                            .split(" ")
                            .join("-")
                            .split("/")
                            .join("-")}/${item.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <strong>
                            {item.category} {item?.item_title} {item.year}
                          </strong>
                        </a>
                      </h5>
                      <h5 className="card-price">Price: {item.price_sale}</h5>
                      <h5 className="card-location">
                        Location: {item.city} {item.state} {item.country}
                      </h5>
                    </div>
                    <div className="second-section">
                      <div className="action-button">
                        {item.is_sold === 0 && (
                          <>
                            <Link to={"/listing/" + item?.id}>
                              <EditThick
                                className="mx-2"
                                style={{color: "black", opacity: 0.7}}
                              />
                            </Link>
                            <DeleteThick
                              className="mx-2"
                              style={{
                                color: "black",
                                opacity: 0.7,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowPopup(true);
                                setDeleteId(item?.id);
                              }}
                            />
                          </>
                        )}
                      </div>
                      {isLoadRenewOrPublish(item)}
                      <ModalView
                        data={item}
                        style={{float: "right", marginRight: "-70px"}}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
            {count > 0 && (
              <Pagination
                totalCount={count}
                pageSize={7}
                pathname={window.location.pathname}
              />
            )}
            {data.length === 0 && (
              <div className="NoListing">
                <h4 className="description">
                  You have no active item in your listing, start uploading new
                  items by clicking on upload items.
                </h4>
              </div>
            )}
          </>
        )}
      </div>
      <ToastContainer />
      {showPopup && (
        <ConfirmationPopUp
          show={showPopup}
          message="Are you sure you want to delete this item?"
          onConfirmClick={() => handleDelete(deleteId)}
          onCancelClick={() => setShowPopup(false)}
          buttonName="Delete"
          isMobile={isMobile}
        />
      )}
      {/* {showPopupSold && (
        <ConfirmationPopUp
          show={showPopupSold}
          message="Are you sure?"
          onConfirmClick={() => history.push("/dashboard")}
          onCancelClick={() => setShowPopupSold(false)}
          buttonName="Yes"
          isMobile={isMobile}
        />
      )} */}
    </>
  );
}
