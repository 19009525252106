import React from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import NewForm from "../NewForm";

const StepThree = ({ onChange, product, isPriceDisable }) => {
  const currencyList = ["USD", "INR", "JPY", "RUB", "EUR", "JPY"];
  const buyingFormat = [
    { label: "Sale Price", value: "sale" },
    { label: "Rent Price", value: "rent" },
    { label: "Price On Request", value: "Price On Request" },
  ];

  return (
    <div>
      <Form className="formStyle">
        <Form.Group className="mb-3" as={Row} controlId="Currency">
          <Col sm="6">
            <Form.Label>
              <strong>Sale Format</strong>
            </Form.Label>
            <Form.Select
              name="currency"
              value={product?.buying_format}
              onChange={onChange("buying_format")}
            >
              {buyingFormat.map((format, index) => (
                <option value={format.value} key={index}>
                  {format.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
        {product?.buying_format === "Price On Request" ? null : (
          <>
            <Form.Group className="mb-3" as={Row} controlId="Currency">
              <Col sm="6">
                <Form.Label>
                  <strong>Currency Type</strong>
                </Form.Label>
                <Form.Select
                  name="currency"
                  value={product?.currency}
                  onChange={onChange("currency")}
                  disabled={isPriceDisable}
                >
                  {currencyList.map((currency, index) => (
                    <option value={currency} id={index}>
                      {currency}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Form.Group>
            <NewForm
              inputKey="Price of item"
              name="price"
              value={product?.price}
              inputsHandler={onChange("price")}
              disable={isPriceDisable}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default StepThree;
