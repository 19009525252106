import React from "react";
import { Button } from "react-bootstrap";
import { ChevronleftThick } from "@ironlist/ironlist-icons";
import { imageUrl } from "../../utils";
import { Checkbox } from "@mui/material";

function SelectTheme({ setStep, setValues, values, addSubdomain }) {
  const themeType = [
    {
      image: imageUrl("/images/loginSignup_image.jpg"),
      label: "Classic Theme (Orange)",
      value: "Theme1",
    },
    {
      image: imageUrl("/images/loginSignup_image.jpg"),
      label: "Blue Theme",
      value: "Theme2",
    },
  ];
  return (
    <div className="info-container">
      <h3 className="headerStyle">
        <ChevronleftThick
          onClick={() => {
            setStep(1);
          }}
          className="back-button"
        />
        Select Theme
      </h3>
      <div className="select-theme">
        {themeType.map((item) => (
          <div className="select-item" key={item?.value}>
            <img
              src={item?.image}
              alt={item?.label}
              width={230}
              height={248}
              className="image-box"
            />
            <div className="check-box">
              <Checkbox
                checked={values?.template_name === item?.value}
                inputProps={{ "aria-label": "controlled", padding: "0px" }}
                sx={{
                  color: "#21548f",
                  "&.Mui-checked": {
                    color: "#21548f",
                  },
                  padding: "0px",
                }}
                onChange={() =>
                  setValues((prevState) => ({
                    ...prevState,
                    template_name: item?.value,
                  }))
                }
              />
              <h5>
                <strong>{item?.label}</strong>
              </h5>
            </div>
          </div>
        ))}
      </div>
      <div
        className="next-button"
        onClick={() => {
          addSubdomain();
        }}
      >
        <Button variant="light" className="nextButton">
          Next
        </Button>
      </div>
    </div>
  );
}
export default SelectTheme;
