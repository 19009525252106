/* eslint-disable no-undef */
import {useEffect} from "react";
import axios from "axios";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import {leadsContainer, MobileLeadsCard, plansTableStyle} from "./style";
import LeadsDetails from "./LeadsDetails";
import {useIsMobile} from "../../components/context/IsMobile";
import LoadingSpinner from "../../components/LoadingSpinner";
import {getStorage} from "../../utils/localStorage";
var React = require("react");

export default function Leads() {
  const [data, setData] = useState([]);
  const [similarLeadData, setSimilarLeadData] = useState([]);
  const selectedOrganization = getStorage("organization");
  const [detailPopUp, setDetailPopUp] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const isMobile = useIsMobile();

  useEffect(() => {
    // if (!selectedOrganization?.org_id) {
    //   history.push("/");
    //   return null;
    // }
    const options = {
      url: `${process.env.REACT_APP_API_URL}/dashboard/leads`,
      method: "GET",
      //   data: {
      //     orgId: selectedOrganization?.org_id,
      //   },
      headers: {
        "x-access-token": JSON.parse(localStorage.getItem("user"))?.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    axios(options).then((response) => {
      if (response?.data?.success) {
        setLoading(false);
      }
      setSimilarLeadData(response.data?.similarLeads);
      setData(response.data?.leads);
    });
    // setTimeout(() => setLoading(false), 2000);
  }, [history, selectedOrganization?.org_id]);

  //   if (!getStorage("user") || getStorage("user")?.pendingInfo) {
  //     history.push("/");
  //     return null;
  //   }

  return (
    <>
      <div className={leadsContainer(isMobile)}>
        {!isMobile && <h3 className="LeadsHeader">Leads</h3>}
        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {data.length > 0 && !isMobile && (
              <div className={plansTableStyle}>
                <table>
                  <thead>
                    <tr>
                      <th>Lead Date</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Item interested</th>
                      <th>City</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.created_at.split("T")[0]}</td>
                        <td>{item?.name}</td>
                        <td>{item?.email_id}</td>

                        <td>{item?.item_title}</td>
                        <td>{item?.city}</td>
                        <td
                          className="ViewDetails"
                          onClick={() => {
                            setDetailPopUp(true);
                            setDetailsData(item);
                          }}
                        >
                          View Details
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {data.length > 0 && isMobile && (
              <>
                {data?.map((item, index) => (
                  <div className={MobileLeadsCard} key={index}>
                    <div className="top-row">
                      <h6>
                        <strong>{item?.name}</strong>
                      </h6>
                    </div>
                    <h6>{item?.email_id}</h6>
                    <div className="items">
                      <h6 className="item-title">Item interested: </h6>
                      <h6 className="item-name">{item.item_title}</h6>
                    </div>
                    <div className="items">
                      <h6 className="item-title">Date & Time: </h6>
                      <h6 className="item-name">
                        {new Date(item.created_at).toLocaleDateString("en-US")},
                        {"  "}
                        {new Date(item.created_at).toLocaleTimeString("en-US")}
                      </h6>
                    </div>
                    <div className="view-detail">
                      <button
                        onClick={() => {
                          setDetailPopUp(true);
                          setDetailsData(item);
                        }}
                        className="btn view-button"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
            {data.length === 0 && (
              <div className="NoLeads">
                <div className="description">
                  <img
                    src="https://t3.ftcdn.net/jpg/02/95/34/72/360_F_295347201_ZQfeqTU03fWOUOGq87M3ShBCJx9Vrn9G.jpg"
                    style={{marginLeft: 100, width: 150}}
                    alt="No Leads Found"
                  />{" "}
                  <h4 style={{marginLeft: 60}}>You Have No Leads!!</h4>
                </div>
              </div>
            )}
          </>
        )}

        {loading && <LoadingSpinner />}
        {!loading && (
          <>
            {similarLeadData.length > 0 && !isMobile && (
              <>
                <h3 className="LeadsHeader">Interested Buyers</h3>
                <div className={plansTableStyle}>
                  <table>
                    <thead>
                      <tr>
                        <th>Lead Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Item interested</th>
                        <th>City</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {similarLeadData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.created_at.split("T")[0]}</td>
                          <td>{item?.name}</td>
                          <td>{item?.email_id}</td>

                          <td>{item?.item_title}</td>
                          <td>{item?.city}</td>
                          <td
                            className="ViewDetails"
                            onClick={() => {
                              setDetailPopUp(true);
                              setDetailsData(item);
                            }}
                          >
                            View Details
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {similarLeadData.length > 0 && isMobile && (
              <>
                <h3 className="LeadsHeader">Interested Buyers</h3>
                {similarLeadData?.map((item, index) => (
                  <div className={MobileLeadsCard} key={index}>
                    <div className="top-row">
                      <h6>
                        <strong>{item?.name}</strong>
                      </h6>
                    </div>
                    <h6>{item?.email_id}</h6>
                    <div className="items">
                      <h6 className="item-title">Item interested: </h6>
                      <h6 className="item-name">{item.item_title}</h6>
                    </div>
                    <div className="items">
                      <h6 className="item-title">City : </h6>
                      <h6 className="item-name">{item.city}</h6>
                    </div>
                    <div className="view-detail">
                      <button
                        onClick={() => {
                          setDetailPopUp(true);
                          setDetailsData(item);
                        }}
                        className="btn view-button"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
      {detailPopUp && (
        <LeadsDetails
          show={detailPopUp}
          detailsData={detailsData}
          onClose={() => {
            setDetailPopUp(false);
            setDetailsData([]);
          }}
        />
      )}
    </>
  );
}
